import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { DeleteDataComponent } from '../../delete-data/delete-data.component';
import { ReleaseFromMarketComponent } from '../release-from-market/release-from-market.component';
import { ManagementService } from './../../management.service';
import { ReceiveinMarketComponent } from './../receivein-market/receivein-market.component';

@Component({
  selector: 'app-inmarket',
  templateUrl: './inmarket.component.html',
  styleUrls: ['./inmarket.component.css']
})
export class InmarketComponent implements OnInit, AfterViewInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();
  dtCheck = false;
  markets;
  received;
  isLoading = true;
  isChecked = true;
  constructor(
    private manServ: ManagementService,
    private dialog: MatDialog,
    private router: Router,
    public authService: AuthService
  ) { }
  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.getReceivedGoods();
    this.dtTrigger.next();

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 100,
      processing: true,
      responsive: true,
      dom: 'Bfrtip',
      buttons: [
          'copy', 'csv', 'excel', 'print'
      ]
    };
  }


  getReceivedGoods() {
    this.manServ.getReceivedInMarket().subscribe(
      data => {
        this.markets = data;
        this.isLoading = false;
        this.rerender();
      }
    );
  }


  rerender(): void {
    if (this.dtCheck){
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
          //  $('#dtb').DataTable();
      });
    }
    else {
      this.dtCheck = true;
      this.dtTrigger.next();
    }

  }


  onReceive() {
    const dialogRef = this.dialog.open(ReceiveinMarketComponent, {
      width: '730px',
      height: '730px',
      data: {action: 'add' }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('closed');
      this.getReceivedGoods();
    });
  }

  editMarket(id, regionId, districtId, wardId) {
    const dialogRef = this.dialog.open(ReceiveinMarketComponent, {
      width: '730px',
      height: '730px',
      // tslint:disable-next-line: object-literal-shorthand
      data: {id: id, action: 'edit', region: regionId, district: districtId, ward: wardId }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('closed');
      this.getReceivedGoods();
    });
  }


  deleteMarket(id) {
    const dialogRef = this.dialog.open(DeleteDataComponent, {
      width: '500px',
      height: '300px',
      data: {id, type: 'inmarket' }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getReceivedGoods();
    });
  }

  withdraw(id, market, broker, crop) {
    const dialogRef = this.dialog.open(ReleaseFromMarketComponent, {
      width: '710px',
      height: '700px',
      // tslint:disable-next-line: object-literal-shorthand
      data: {id: id, market_id: market, trader_id: broker, crop_id: crop }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('closed');
      this.getReceivedGoods();
    });
  }

  viewMore(marketId) {
    this.router.navigate(['managements/markets/receive-commodities/' + marketId]);
  }
}
