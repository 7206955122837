<div [ngClass]="!isLoading ? 'show' : 'hide'" class="w-100">
    <div class="condition mt-1 d-flex justify-content-start" *ngIf="authService.isAllowed('manage-users')">
        <div class="p-3">
            <button class="button" mat-button (click)="onAddUser()"><span class="material-icons notranslate">add_circle_outline</span>Add user</button>
        </div>
    </div>
    <div class="view card">
        <div class="table-responsive">
            <div class="card-body">
                <!-- i have removed below  -->
                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" id="dtbs" class="table p-3">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Full Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Username</th>
                            <th>Role</th>
                            <th>Region</th>
                            <th>District</th>
                            <th>Status</th>
                            <th>Photo</th>
                            <th>Action</th>
                        </tr>

                    </thead>
                    <tbody>
                        <tr *ngFor="let user of users;let i = index">
                            <td>{{i+1}}</td>
                            <td>{{user.full_name}}</td>
                            <td>{{user.email}}</td>
                            <td>{{user.phone}}</td>
                            <td>{{user.username}}</td>
                            <td>{{user.role?.role}}</td>
                            <td>{{user.region?.name}}</td>
                            <td>{{user.district?.name}}</td>
                            <td>{{user.status == 1 ? 'Active' : 'In Active'}}</td>
                            <td><img *ngIf="user.image" src={{imageUrl}}{{user.image}}  alt="" width="75" height="50" onerror="this.onerror=null;this.src='https://www.kindpng.com/picc/m/207-2074624_white-gray-circle-avatar-png-transparent-png.png';" style="background-color: transparent;"></td>
                            <td style="display: flex; flex-direction: row;">
                                <a class="text-success act" (click)="editUser(user.id)"><i class="fa fa-edit"></i></a>
                                <mat-icon *ngIf="authService.isAdmin" class="text-danger act" (click)="deleteUser(user.id)">delete_forever</mat-icon>
                                <mat-icon *ngIf="authService.isAdmin" class="text-primary act" (click)="editPassword(user.id)">edit</mat-icon>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

</div>

<div *ngIf="isLoading" class="spinner-holder">
  <mat-spinner class="spinner"></mat-spinner>
</div>
