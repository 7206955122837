<div [ngClass]="received ? 'show' : 'hide'" class="view card">
    <mat-tab-group animationDuration="0ms">
        <mat-tab label="Receive in Warehouse">
            <div class="table-responsive">
                <div class="card-header d-flex justify-content-start" *ngIf="authService.isAllowed('input-data')">
                    <button class="button" mat-button (click)="onReceiveInWarehouse()"><span
                            class="material-icons notranslate">add_circle_outline</span>Receive in Warehouse</button>
                </div>
                <div class="card-body">
                    <fieldset>
                        <legend></legend>
                        <form class="mb-3 w-100" id="" [formGroup]="inWarehouseFilterForm">
                            <div class="form-row">
                              
                                <div class="col-md-3 space1">
                                    <mat-form-field class="w-100" appearance="outline">
                                        <mat-label>Trader</mat-label>
                                        <mat-select placeholder="Select Trader" formControlName="trader_id" (selectionChange)="applyFilter()">
                                            <mat-option *ngFor="let trader of traders" [value]="trader.id">{{trader.user?.full_name}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-3 space1">
                                    <mat-form-field class="w-100" appearance="outline">
                                        <mat-label>Regions</mat-label>
                                        <mat-select placeholder="Select Region" formControlName="region_id" (selectionChange)="applyFilter()">
                                            <mat-option *ngFor="let region of regions" [value]="region.id">{{region.name}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                              
                                <div class="col-md-3 space1">
                                    <mat-form-field class="w-100" appearance="outline">
                                        <mat-label>Crops</mat-label>
                                        <mat-select placeholder="Select Crop" formControlName="crop_id" (selectionChange)="applyFilter()">
                                            <mat-option *ngFor="let crop of crops" [value]="crop.id">{{crop.name}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                              <div class="col-md-3">
                                <mat-form-field class="w-100" appearance="fill">
                                    <mat-label>Start Date</mat-label>
                                    <input matInput [matDatepicker]="picker" formControlName="start" (dateInput)="applyFilter()">
                                    <mat-datepicker-toggle matSuffix [for]="picker">
                                        <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                              </div>
                              
                              <div class="col-md-3">
                                <mat-form-field class="w-100" appearance="fill">
                                    <mat-label>End Date</mat-label>
                                    <input matInput [matDatepicker]="picker2" formControlName="end" (dateInput)="applyFilter()">
                                    <mat-datepicker-toggle matSuffix [for]="picker2">
                                        <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>
                                </mat-form-field>
                              </div>
                              <div class="col-md-3">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Status</mat-label>
                                    <mat-select placeholder="Select Status" formControlName="status" (selectionChange)="applyFilter()">
                                        <mat-option value="New">New</mat-option>
                                        <mat-option value="Approved">Approved</mat-option>
                                    </mat-select>
                                </mat-form-field>
                              </div>
                              <div class="d-flex justify-content-end">
                                <button *ngIf="clear" (click)="clearForm()" type="submit" class="btn btn-blue btn-sm  pull-right filter-btn" id="filter">Clear Filter</button>
                              </div>
                            </div>
                        </form>
                      </fieldset>
                    <!-- i have removed below because of the above ngif -->
                    <table datatable [dtOptions]="dtOptions2" [dtTrigger]="dtTrigger2" id="dtb" class="table p-3">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th width="100px">Received Date</th>
                                <th>Trader</th>
                                <!--Seller -->
                                <!-- <th>Quality/Grade</th> -->
                                <th>Crop</th>
                                <th>Quantity (MT)</th>
                                <th>Source Region</th>
                                <th>Source District</th>
                                <!-- <th>Village</th>
                                <th>Ward</th>
                                <th>District</th> -->
                                <th>Status</th>
                                <th>Action</th>
                            </tr>

                        </thead>
                        <tbody>
                            <tr *ngFor="let warehouse of received;let i = index">
                                <td>{{i+1}}</td>
                                <td>{{warehouse.date | date: mediumDate}}</td>
                                <td>{{warehouse.seller}}</td>
                                <!-- <td>{{warehouse.quality}}</td> -->
                                <td>{{warehouse.crop}}</td>
                                <td>{{warehouse.quantity}}</td>
                                <td>{{warehouse.region?.name}}</td>
                                <td>{{warehouse.district?.name}}</td>
                                <!-- <td>{{warehouse.village}}</td>
                                <td>{{warehouse.ward}}</td>
                                <td>{{warehouse.district}}</td> -->
                                <td>{{warehouse.status}}</td>
                                <td style="display: flex; flex-direction: row;">
                                    <mat-icon class="text-secondary act" title="view more"
                                        (click)="viewMore(warehouse.id)">visibility</mat-icon>
                                    <a class="text-success act" title="update"
                                        (click)="editData(warehouse.id, warehouse.region_id, warehouse.district_id, warehouse.ward_id, warehouse.village_id)">
                                        <i class="fa fa-edit"></i></a>
                                    <mat-icon *ngIf="authService.isAdmin" class="text-danger act" title="delete" (click)="deleteData(warehouse.id)">
                                        delete_forever</mat-icon>
                                    <!--<mat-icon class="text-primary act" title="withdraw good" (click)="withdraw(warehouse.id, warehouse.warehouse_id, warehouse.trader_id, warehouse.crop_id)">ios_share</mat-icon>
                               <mat-icon class="text-info act" title="view withdrawed good" (click)="withdraw(warehouse.id, warehouse.warehouse_id, warehouse.trader_id, warehouse.crop_id)">visibility</mat-icon> -->
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Approve Data" *ngIf="role == 'admin' || role == 'daico'">
            <div class="table-responsive">
                <div class="card-body">
                    <!-- i have removed below because of the above ngif -->
                    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger2" id="dtb2" class="table p-3">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th width="100px">Received Date</th>
                                <th>Trader</th>
                                <!--Seller -->
                                <!-- <th>Quality/Grade</th> -->
                                <th>Crop</th>
                                <th>Quantity (MT)</th>
                                <th>Source Region</th>
                                <th>Source District</th>
                                <!-- <th>Village</th>
                        <th>Ward</th>
                        <th>District</th> -->
                                <th>Status</th>
                                <th>Latitude</th>
                                <th>Longitude</th>
                                <th>Action</th>
                            </tr>

                        </thead>
                        <tbody>
                            <tr *ngFor="let warehouse of new_received;let i = index">
                                <td>{{i+1}}</td>
                                <td>{{warehouse.date | date: mediumDate}}</td>
                                <td>{{warehouse.seller}}</td>
                                <!-- <td>{{warehouse.quality}}</td> -->
                                <td>{{warehouse.crop}}</td>
                                <td>{{warehouse.quantity}}</td>
                                <td>{{warehouse.region?.name}}</td>
                                <td>{{warehouse.district?.name}}</td>
                                <!-- <td>{{warehouse.village}}</td>
                                <td>{{warehouse.ward}}</td>
                                <td>{{warehouse.district}}</td> -->
                                <td>{{warehouse.status}}</td>
                                <td>{{warehouse.lat}}</td>
                                <td>{{warehouse.long}}</td>
                                <td style="display: flex; flex-direction: row;">
                                    <a class="text-success act" title="update"
                                        (click)="editData(warehouse.id, warehouse.region_id, warehouse.district_id, warehouse.ward_id, warehouse.village_id)">
                                        <i class="fa fa-edit"></i></a>
                                    <mat-icon class="text-secondary act" title="view more"
                                        (click)="viewMore(warehouse.id)">visibility</mat-icon>
                                    <mat-icon class="text-primary act" title="Approve"
                                        (click)="approveData(warehouse.id)">check_circle_outline</mat-icon>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Approved Data">
            <div class="table-responsive">
                <div class="card-body">
                    <!-- i have removed below because of the above ngif -->
                    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger3" id="dtb3" class="table p-3">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th width="100px">Received Date</th>
                                <th>Trader</th>
                                <!--Seller -->
                                <!-- <th>Quality/Grade</th> -->
                                <th>Crop</th>
                                <th>Quantity (MT)</th>
                                <th>Source Region</th>
                                <th>Source District</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>

                        </thead>
                        <tbody>
                            <tr *ngFor="let warehouse of approved_received;let i = index">
                                <td>{{i+1}}</td>
                                <td>{{warehouse.date | date: mediumDate}}</td>
                                <td>{{warehouse.seller}}</td>
                                <td>{{warehouse.crop}}</td>
                                <td>{{warehouse.quantity}}</td>
                                <td>{{warehouse.region?.name}}</td>
                                <td>{{warehouse.district?.name}}</td>
                                <td>{{warehouse.status}}</td>
                                <td style="display: flex; flex-direction: row;">
                                    <mat-icon class="text-secondary act" title="view more"
                                        (click)="viewMore(warehouse.id)">visibility</mat-icon>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>

</div>


<div *ngIf="!received" class="spinner-holder">
    <mat-spinner class="spinner"></mat-spinner>
</div>