<div class="ware">
    <div class="row pl-1 pr-1">
        <div class="col-md-4 space-2">
            <div class="card">
                <div class="p-2">
                    <div class="pb-1">
                        <h4 class="d-flex justify-content-between" style="width: 100%; background-color: #70AD47; color: #fff; padding-left: 3px;"><strong>Market Type</strong> <span *ngIf="clearType" class="badge badge-secondary clear" (click)="clearFilter('type')">Clear</span></h4>
                        <hr class="divider-1">
                    </div>
                    <mat-select [(value)]="selectedMarketType" class="form-control" placeholder="Select Market Type" (selectionChange)="selectMarketType($event.value)">
                        <mat-option value="secondary">Secondary</mat-option>
                        <mat-option value="primary">Primary</mat-option>
                    </mat-select>
                </div>
            </div>
        </div>
        <div class="col-md-4 space-2">
            <div class="card p-2">
                <div class="pb-1">
                    <h4 class="d-flex justify-content-between region"><strong>Region</strong> <span *ngIf="clearRegion" class="badge badge-secondary clear" (click)="clearFilter('region')">Clear</span></h4>
                    <hr class="divider-3">
                </div>
                <mat-select [(value)]="selectedRegion" class="form-control" placeholder="Select Region" (selectionChange)="selectRegion($event)">
                    <mat-option *ngFor="let region of regions; let index=i" [value]="region.id">
                        <span class="text-1">{{region.name}}</span>
                    </mat-option>
                </mat-select>
            </div>
        </div>
        <!--<div class="col-md-3 space-2">
            <div class="card p-2">
                <div class="pb-1">
                    <h4 class="d-flex justify-content-between district"><strong>District</strong> <span *ngIf="clearDistrict" class="badge badge-secondary clear" (click)="clearFilter('district')">Clear</span></h4>
                    <hr class="divider-4">
                </div>
                <mat-select [(value)]="selectedDistrict" class="form-control" placeholder="Select District" (selectionChange)="selectDistrict($event.value)">
                    <mat-option *ngFor="let district of districts; let index=i" [value]="district.id">
                        <span class="">{{district.name.substring(0,1) | uppercase }}{{district.name.substring(1) | lowercase }}</span>
                    </mat-option>
                </mat-select>
            </div>
        </div> -->
        <div class="col-md-4 space-2 pb-1">
            <div class="card">
                <div class="p-2">
                    <div class="pb-1">
                        <h4 class="d-flex justify-content-between" style="width: 100%; background-color: #ED7D31; color: #fff; padding-left: 3px;"><strong>Year</strong> <span *ngIf="clearYear" class="badge badge-secondary clear" (click)="clearFilter('year')">Clear</span></h4>
                        <hr class="divider-1">
                    </div>
                    <mat-select [(value)]="selectedYear" class="form-control" placeholder="Select.." (selectionChange)="selectYear($event.value)">
                        <mat-option *ngFor="let year of years; let index=i" [value]="year">
                            {{year}}
                        </mat-option>
                    </mat-select>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-white">
        <div class="crops-holder bg-white pb-3 pt-3 mb-1 mt-1">

            <div style="display: flex;flex-direction: row;">
                <mat-divider style="width: 89%;" class="horizontal-divider"></mat-divider>
            </div>
            <div class="crops-list mat-elevation-z1" *ngIf="!noCrops()">
                <div class="left-indicator" *ngIf="isSScrollable()">
                    <span class="material-icons" (click)="onScrollRight()">
                  arrow_back_ios
                </span>
                </div>

                <div class="scrollable-div" id="list">
                    <a *ngFor="let crop of crops;let i = index" id="crop" class="crop-holder" [ngClass]="[i == selected ? 'active' : '']" (click)="onChangeCrop(crop.id, crop.name, i)" style="text-decoration: none">
                        <div style="display: flex;flex-direction: row;justify-content: space-between;">

                            <div>
                                <p class="crop-name zero-margin" style="font-size: 12px;">
                                    <span>{{ getUpperCase(crop.name) }}</span>
                                </p>

                            </div>
                        </div>
                    </a>
                </div>
                <div class="right-indicator" *ngIf="isSScrollable()">
                    <span class="material-icons" (click)="onScrollLeft()">
                arrow_forward_ios
                </span>
                </div>
            </div>
        </div>
    </div>
    <div class="row pl-3 pr-1">
      <div class="col-md-6 space-2 pb-2">
          <div class="card w-100">
              <highcharts-chart [constructorType]="chartConstructor" [Highcharts]="Highcharts" [options]="chartOptions" style="height: 400px;display: block;width: 100%;overflow: scroll;"></highcharts-chart>
          </div>
      </div>
      <div class="col-md-6 space-2 pb-2">
          <div class="card w-100">
              <highcharts-chart [constructorType]="chartConstructor" [Highcharts]="Highcharts" [options]="chartOptionsRegions" style="height: 400px;display: block;width: 100%;overflow: scroll;"></highcharts-chart>
          </div>
      </div>
      <div class="col-md-6 space-2 pb-2">
          <div class="card w-100">
              <highcharts-chart [constructorType]="chartConstructor" [Highcharts]="Highcharts" [options]="chartOptionsMarkets" style="height: 400px;display: block;width: 100%;overflow: scroll;"></highcharts-chart>
          </div>
      </div>
      <div class="col-md-6 space-2 pb-2">
          <div class="card w-100">
              <highcharts-chart [constructorType]="chartConstructor" [Highcharts]="Highcharts" [options]="chartOptionsPriceTrend" style="height: 400px;display: block;width: 100%;overflow: scroll;"></highcharts-chart>
          </div>
      </div>
      <div class="col-md-6 space-2 pb-2">
          <div class="card w-100">
              <highcharts-chart [constructorType]="chartConstructor" [Highcharts]="Highcharts" [options]="chartOptionsPriceRegions" style="height: 400px;display: block;width: 100%;overflow: scroll;"></highcharts-chart>
          </div>
      </div>
      <div class="col-md-6 space-2 pb-2">
          <div class="card w-100">
              <highcharts-chart [constructorType]="chartConstructor" [Highcharts]="Highcharts" [options]="chartOptionsPriceMarkets" style="height: 400px;display: block;width: 100%;overflow: scroll;"></highcharts-chart>
          </div>
      </div>
    </div>
    <!-- <div class="row">
        <div class="col-md-6 space-2">
            <div class="location">
                <div class="card card-2">
                    <div class="card-header header text">Top 10 Markets by Crops</div>
                    <div id="crop_location" style="height: 33vh;">
                        <canvas id="crop_location_chart" style="height: 100%; width: 100%;"></canvas>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="cropMarket">
                <div class="card card-2">
                    <div class="card-header header text">Markets Quality</div>
                    <div id="market_quality" style="height: 35vh;">
                        <canvas id="market_quality_chart" style="height: 100%; width: 100%;"></canvas>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row pr-0 mb-1">
        <div class="col-md-7 space-2">
            <div class="purchasing mt-1">
                <div class="card card-2">
                    <div class="card-header header text">Regional Avarage Crops Purchasing Price (Tsh/Kg)</div>
                    <div id="purchasing" style="height: 33vh;">
                        <canvas id="purchasing_chart" style="height: 100%; width: 100%;"></canvas>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-5 space-2">
            <div class="selling mt-1">
                <div class="card card-2">
                    <div class="card-header header text">Regional Crops Avarage Selling Price (Tsh/Kg)</div>
                    <div id="selling" style="height: 33vh;">
                        <canvas id="selling_chart" style="height: 100%; width: 100%;"></canvas>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</div>
