<div id="content" class="relative">

    <div id="content-header" class="absolute -top-24">
        <h1 class="text-uppercase">Verified Permits</h1>
    </div>
    <!-- <div class="min-h-[50vh] w-full" *ngIf="!authService.isApproved">
        <div class="mx-auto w-1/3 bg-gray-50 p-10 rounded-lg">
            <div class="text-lg py-3">You have loged in successfully!</div>
            <div class="">Once your account is approved, you will be able to proceed with certificate application.</div>
            <div class="my-2"><span class="bg-red-500 text-white cursor-pointer px-2 py-1 rounded-lg" (click)="updateStatusCheck()"><i class="fa fa-spinner fa-spin" *ngIf="updating"></i> Refresh</span></div>
        </div>
    </div> -->
    <div class="view card" [ngClass]="!isLoading ? 'show' : 'hide'">
        <div class="table-responsive">
            <div class="card-body">
                <div class="card-body">
                    <!-- -->
                    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" id="dtbs" class="table p-3">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Date</th>
                                <th>Name</th>
                                <th>Category</th>
                                <th>Type</th>
                                <th>Market</th>
                                <th>Country</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
    
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of data;let i = index">
                                <td>{{i+1}}</td>
                                <td>{{ data.created_at.split('T')[0] }}</td>
                                <td>{{ data.trader?.full_name }}</td>
                                <td>{{ data.trader?.buyer_category }}</td>
                                <td>{{ data.trader?.type }}</td>
                                <td>{{ data.market_category?.replace('_', ' ') }}</td>
                                <td>{{ data.country }}</td>
                                <td><span class="">{{ data.status }}</span></td>
                                <td class="flex space-x-2 items-center">
                                    <!-- <a class="text-success act" (click)="editTrader(data.id, data.user?.region?.id, data.user?.district?.id, data.user?.ward?.id, 'data')"><i class="fa fa-edit"></i></a> -->
                                    <a routerLink="/buyers-portal/application-info/{{ data.id }}" class="text-blue-500 bg-gray-200 px-2 py-1.5 rounded-md" ><i class="fa fa-eye"></i></a>
                                    <!-- <mat-icon *ngIf="authService.isAdmin" class="text-danger act" (click)="deleteTrader(data.id)">delete_forever</mat-icon> -->
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    
    <div *ngIf="isLoading" class="spinner-holder">
      <mat-spinner class="spinner"></mat-spinner>
    </div>
    
      
</div>