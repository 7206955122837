import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { ManagementService } from '../../management/management.service';
import { BuyerService } from '../buyer.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-update-permit',
  templateUrl: './update-permit.component.html',
  styleUrls: ['./update-permit.component.css']
})
export class UpdatePermitComponent implements OnInit {
  isLoading = true;

  id;

  user;

  regions;
  districts;
  wards;
  villages;
  crops;
  markets;
  edit;
  countries;
  isRequired = false;
  isDomestic = false;
  isIndividual = true;
  tinmask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
  nidamask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
  editCrops = [];
  selectedImage;
  selectedBusinessLicense;
  selectedCertificateOfInc;
  selectedTINCertificate;
  selectedVATCertificate;
  selectedTaxClearance;
  selectedNida;
  selectedLga;
  imageSrc: string;

  submitting = false;
  showIndividual = true;
  page = 'one';

  license_link;
  inc_cert_link;
  tin_link;
  tax_link;
  nida_link;
  lga_link;
  vat_link;

  marketCouncils;

  baseUrl = environment.imageUrl;
  prevUrl: string;

  addForm = this.fb.group({
    full_name: ['', Validators.required],
    phone: ['', Validators.pattern(/^(255)[0-9]{9}$/)],
    category: [''],
    email: ['', [Validators.pattern(/.+@.+\..+/), Validators.required]],
    address: [''],
    company_name: [''],
    tin_number: [''],
    date: [''],
    type: [''],
    crops: ['', Validators.required],
    markets: ['', Validators.required],
    village_id: [''],
    ward_id: [''],
    district_id: [''],
    region_id: [''],
    country_id: [''],
    reg_agency: [''],
    business_license: [''],
    certificate_of_inc: [''],
    nida: [''],
    tin_certificate: [''],
    tax_clearance: [''],
    nida_certificate: [''],
    lga_permit: [''],
    market_category: [''],
    image: [''],
    reg_date: [''],
    operation_date: [''],
    vat_reg_number: [''],
    license_number: [''],
    cert_of_inc_number: [''],
    // tax_number: [''],
    vat_certificate: [''],
    buying_capacity: [''],
    bank_name: [''],
    branch: [''],
    bank_acct: [''],
    swift_code: [''],
    councils: ['', [Validators.required]],
  });


  constructor(
    private fb: FormBuilder,
    private manServ: ManagementService,
    private snackBar: MatSnackBar,
    private dateAdapter: DateAdapter<Date>,
    private authService: AuthService,
    private buyerService: BuyerService,
    private route: Router,
    public activatedRoute: ActivatedRoute
  ) {
    this.dateAdapter.setLocale('en-GB'); // dd/MM/yyyy
    // console.log(activatedRoute.params['_value'].id);
    this.id = activatedRoute.params['_value'].id;
  }

  ngOnInit(): void {
    this.prevUrl = this.buyerService.getPreviousUrl();

    this.getUserDetails();
    this.getCountries();
    this.getAllRegions();
    this.getMarkets();
    this.getCrops();
    this.addForm.get('date').setValue(new Date());
    this.getDetails();
  }

  toNext() {
    this.page = 'two';
  }
  toPrev() {
    this.page = 'one';
  }

  getDetails() {
    this.buyerService.getPermitApplicationDetails(this.id).subscribe(
      (data: any) => {

        // set attachment links
        this.license_link = data.trader.business_license
        this.inc_cert_link = data.trader.certificate_of_inc
        this.tax_link = data.trader.tax_clearance
        this.nida_link = data.trader.nida_certificate
        this.lga_link = data.lga_permit
        this.tin_link = data.trader.tin_certificate
        this.vat_link = data.trader.vat_certificate

        this.addForm.get('tin_number').setValue(data.trader.tin_number)
        this.addForm.get('company_name').setValue(data.trader.company_name)
        this.addForm.get('nida').setValue(data.trader.nida)
        this.addForm.get('country_id').setValue(data.trader.country_id)
        this.addForm.get('vat_reg_number').setValue(data.trader.vat_reg_number)
        this.addForm.get('license_number').setValue(data.trader.license_number)
        this.addForm.get('cert_of_inc_number').setValue(data.trader.cert_of_inc_number)
        // // this.addForm.get('tax_number').setValue(data.trader.tax_number)
        this.addForm.get('bank_name').setValue(data.trader.bank_name)
        this.addForm.get('branch').setValue(data.trader.branch)
        this.addForm.get('bank_acct').setValue(data.trader.bank_acct)
        this.addForm.get('swift_code').setValue(data.trader.swift_code)

        this.addForm.get('region_id').setValue(data.region_id)
        this.getDistricts(data.region_id)

        this.addForm.get('district_id').setValue(data.district_id)
        this.getWards(data.district_id)

        this.addForm.get('ward_id').setValue(data.ward_id)
        this.getVillages(data.ward_id)

        this.addForm.get('village_id').setValue(data.village_id)
        this.addForm.get('reg_agency').setValue(data.reg_agency)
        this.addForm.get('address').setValue(data.trader.address)
        this.addForm.get('market_category').setValue(data.market_category)
        this.changeTypeValidation(data.market_category)
        this.addForm.get('category').setValue(data.trader.buyer_category)
        this.changeCategoryValidation(data.trader.buyer_category)

        this.addForm.get('crops').setValue(data.crop_ids)
        this.addForm.get('markets').setValue(data.market_ids)
        this.getCouncils()
        this.addForm.get('buying_capacity').setValue(data.buying_capacity)
        
        this.addForm.get('councils').setValue(data.council_ids)

        // this.application = data
        // console.log(data);

        this.isLoading = false;
      }
    );
  }

  changeTypeValidation(type) {
    if (type === 'physical_market') {
      this.isDomestic = true;
      this.addForm.get('region_id').setValidators(Validators.required);
      this.addForm.get('district_id').setValidators(Validators.required);
      // this.addForm.get('ward_id').setValidators(Validators.required);
      // this.addForm.get('village_id').setValidators(Validators.required);
      this.addForm.get('reg_agency').clearValidators();
      this.addForm.get('country_id').clearValidators();
      this.addForm.get('country_id').setValue('223');

      this.addForm.get('bank_name').clearValidators();
      this.addForm.get('branch').clearValidators();
      this.addForm.get('bank_acct').clearValidators();
      this.addForm.get('swift_code').clearValidators();

      this.addForm.get('bank_name').setValue('');
      this.addForm.get('branch').setValue('');
      this.addForm.get('bank_acct').setValue('');
      this.addForm.get('swift_code').setValue('');
    }
    else {
      this.isDomestic = false;
      this.addForm.get('reg_agency').setValidators(Validators.required);
      this.addForm.get('country_id').setValidators(Validators.required);

      this.addForm.get('region_id').clearValidators();
      this.addForm.get('district_id').clearValidators();
      this.addForm.get('ward_id').clearValidators();
      this.addForm.get('village_id').clearValidators();

      this.addForm.get('region_id').setValue('');
      this.addForm.get('district_id').setValue('');
      this.addForm.get('ward_id').setValue('');
      this.addForm.get('village_id').setValue('');

      this.addForm.get('category').setValue('company');
      this.changeCategoryValidation('company');


      this.addForm.get('bank_name').setValidators(Validators.required);
      this.addForm.get('branch').setValidators(Validators.required);
      this.addForm.get('bank_acct').setValidators(Validators.required);
      this.addForm.get('swift_code').setValidators(Validators.required);
    }
  }

  getUserDetails() {
    var id = localStorage.getItem('asdsUserId');
    this.manServ.getSingleUser(id).subscribe(
      (data: any) => {
        this.user = data;
        // console.log(data)
        this.addForm.get('full_name').setValue(data.full_name)
        this.addForm.get('phone').setValue(data.phone)
        this.addForm.get('email').setValue(data.email)
        this.addForm.get('address').setValue(data.address)
      }
    );
  }

  changeCategoryValidation(type) {
    if (type === 'individual') {
      this.isIndividual = true;
      this.addForm.get('nida').setValidators(Validators.required);

      // this.addForm.get('tin_number').clearValidators();
      // this.addForm.get('company_name').clearValidators();
      // this.addForm.get('tax_clearance').clearValidators();
      // this.addForm.get('certificate_of_inc').clearValidators();
      // this.addForm.get('tin_certificate').clearValidators();
      // this.addForm.get('business_license').clearValidators();

      if (this.nida_link == null) {
        // this.addForm.get('nida_certificate').setValidators(Validators.required);
      }

      if (this.lga_link == null) {
        this.addForm.get('lga_permit').setValidators(Validators.required);
      }
      if (this.tin_link == null) {
        this.addForm.get('tin_certificate').setValidators(Validators.required);
      }

      // this.addForm.get('tin_number').setValue('');
      // this.addForm.get('company_name').setValue('');
      // this.addForm.get('tax_clearance').setValue('');
      // this.addForm.get('certificate_of_inc').setValue('');
      // this.addForm.get('tin_certificate').setValue('');
      // this.addForm.get('business_license').setValue('');
    }
    else {
      this.isIndividual = false;

      if (this.tin_link == null) {
        this.addForm.get('tin_number').setValidators(Validators.required);
      }
      if (this.tax_link == null) {
        this.addForm.get('tax_clearance').setValidators(Validators.required);
      }
      if (this.inc_cert_link == null) {
        this.addForm.get('certificate_of_inc').setValidators(Validators.required);
      }
      if (this.tin_link == null) {
        this.addForm.get('tin_certificate').setValidators(Validators.required);
      }
      if (this.license_link == null) {
        this.addForm.get('business_license').setValidators(Validators.required);
      }
      this.addForm.get('company_name').setValidators(Validators.required);

      this.addForm.get('nida').clearValidators();
      this.addForm.get('nida_certificate').clearValidators();
      this.addForm.get('lga_permit').clearValidators();

      this.addForm.get('nida').setValue('');
    }
  }

  getCountries() {
    this.manServ.getCountries().subscribe(
      data => {
        this.countries = data;
      }
    );
  }
  getAllRegions() {
    this.manServ.getAllRegions().subscribe(
      data => {
        this.regions = data;
      }
    );
  }

  getDistricts(regionId) {
    this.manServ.getAllDistricts(regionId).subscribe(
      data => {
        this.districts = data;
      }
    );
  }

  getWards(districtId) {
    this.manServ.getWards(districtId).subscribe(
      data => {
        this.wards = data;
      }
    );
  }

  getVillages(wardId) {
    this.manServ.getVillages(wardId).subscribe(
      data => {
        this.villages = data;
      }
    );
  }

  getCrops() {
    this.manServ.getCrops().subscribe(
      data => {
        this.crops = data;
      }
    );
  }
  getErrorMessage() {
    return 'This field is required';
  }

  getMarkets() {
    this.manServ.getAllRegions().subscribe(
      data => {
        this.markets = data;
        // console.log(data);

      }
    );
  }
  getEmailErrorMessage() {
    return 'Wrong email pattern';
  }
  getPhoneErrorMessage() {
    return 'Wrong phone pattern, please check the placeholder';
  }

  goBack() {
    // this.route.navigate(['buyers-portal']);
    this.route.navigate([this.prevUrl]);
  }

  getMyRole() {
    this.authService.fetchTraderCategory().subscribe(
      (data: any) => {
        // console.log(data)
        localStorage.setItem('trader_category', data);
      }
    );

    this.authService.fetchMyRole().subscribe(
      (data: any) => {
        // console.log(data)
        localStorage.setItem('role', data);
      }
    );
  }

  getCouncils() {
    console.log(this.addForm.get('markets').value);
    this.buyerService.getRegionCouncils(this.addForm.get('markets').value).subscribe(
      data => {
        this.marketCouncils = data;
        // console.log(data);
        
      }
    );
  }

  onSubmit() {
    console.log(this.addForm.value);
    var formData: any = new FormData();
    formData.append('full_name', this.addForm.controls['full_name'].value)
    formData.append('address', this.addForm.controls['address'].value)
    formData.append('phone', this.addForm.controls['phone'].value)
    formData.append('tin_number', this.addForm.controls['tin_number'].value)
    formData.append('category', this.addForm.controls['category'].value)
    formData.append('email', this.addForm.controls['email'].value)
    formData.append('company_name', this.addForm.controls['company_name'].value)
    formData.append('region_id', this.addForm.controls['region_id'].value)
    formData.append('district_id', this.addForm.controls['district_id'].value)
    formData.append('ward_id', this.addForm.controls['ward_id'].value)
    formData.append('village_id', this.addForm.controls['village_id'].value)
    formData.append('country_id', this.addForm.controls['country_id'].value)
    formData.append('nida', this.addForm.controls['nida'].value)
    formData.append('type', this.addForm.controls['type'].value)
    formData.append('user', localStorage.getItem('asdsUserId'))
    formData.append('image', this.selectedImage)
    formData.append('business_license', this.selectedBusinessLicense)
    formData.append('certificate_of_inc', this.selectedCertificateOfInc)
    formData.append('tin_certificate', this.selectedTINCertificate)
    formData.append('tax_clearance', this.selectedTaxClearance)
    formData.append('nida_certificate', this.selectedNida)
    formData.append('lga_permit', this.selectedLga)
    formData.append('vat_reg_number', this.addForm.controls['vat_reg_number'].value)
    formData.append('license_number', this.addForm.controls['license_number'].value)
    formData.append('cert_of_inc_number', this.addForm.controls['cert_of_inc_number'].value)
    // // formData.append('tax_number',  this.addForm.controls['tax_number'].value)
    formData.append('vat_certificate', this.selectedVATCertificate)
    formData.append('crops', this.addForm.controls['crops'].value)
    formData.append('market_category', this.addForm.controls['market_category'].value)
    formData.append('markets', this.addForm.controls['markets'].value)
    formData.append('buying_capacity', this.addForm.controls['buying_capacity'].value)
    formData.append('bank_name', this.addForm.controls['bank_name'].value)
    formData.append('branch', this.addForm.controls['branch'].value)
    formData.append('bank_acct', this.addForm.controls['bank_acct'].value)
    formData.append('swift_code', this.addForm.controls['swift_code'].value)
    formData.append('councils', this.addForm.controls['councils'].value)
    formData.append('reg_agency', this.addForm.controls['reg_agency'].value)

    // Display the key/value pairs
    // for (var pair of formData.entries()) {
    //   console.log(pair[0] + ', ' + pair[1]);
    // }

    if (this.addForm.valid) {
      console.log('valid');
      this.submitting = true;
      this.buyerService.updatePermitApplications(formData, this.id).subscribe(
        (resp: any) => {
          this.snackBar.open('Submited', 'Application updated successfully!', {
            duration: 10000
          });
          this.submitting = false;
          this.getMyRole();
          this.route.navigate(['/buyers-portal'])
        }
      );
    } else {
      // console.log('invalid');
      var fields = '';
      Object.keys(this.addForm.controls).forEach(field => {
        const control = this.addForm.get(field);
        if (control.errors) {
          fields += field + ', '
          console.error(`Validation errors for ${field}: `, control.errors);
        }
      });
      this.snackBar.open('Error', 'Make sure ' + fields + ' fields are filled', {
        duration: 10000
      });
    }
  }


  onFileChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {

        this.imageSrc = reader.result as string;

        // this.userForm.patchValue({
        //   fileSource: reader.result,
        // });

        this.selectedImage = event.srcElement.files[0];
      };

    }
  }

  onBusinessLicenseChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.selectedBusinessLicense = event.srcElement.files[0];
      };

    }
  }

  onTINCertificateChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.selectedTINCertificate = event.srcElement.files[0];
      };

    }
  }

  onTaxClearanceChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.selectedTaxClearance = event.srcElement.files[0];
      };

    }
  }

  onCertificateOfIncChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.selectedCertificateOfInc = event.srcElement.files[0];
      };

    }
  }

  onNidaCertificateChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.selectedNida = event.srcElement.files[0];
      };

    }
  }
  onLGAPermitChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.selectedLga = event.srcElement.files[0];
      };

    }
  }

  onVATCertificateChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.selectedVATCertificate = event.srcElement.files[0];
      };

    }
  }

}
