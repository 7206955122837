<div id="content" class="relative">
    <div class="fixed top-2/4 left-2/4 z-50 {{ loading ? '' : 'hidden' }}">
        <div class=" bg-gray-900/50 text-gray-100 p-3 rounded-md">
            <i class="fa fa-spinner fa-spin fa-2x"></i>
        </div>
    </div>

    <div id="content-header" class="absolute -top-24">
        <h1 class="text-uppercase font-bold">Trader Summaries</h1>
    </div>
    <div class="">
        <div class="grid md:grid-cols-4 gap-2 bg-gray-100/50 px-2 mb-2 w-full">
            <div class="">
                <mat-form-field class="" appearance="outline">
                    <mat-label>Regions</mat-label>
                    <mat-select placeholder="Select Region" formControlName="region_id" (selectionChange)="setRegion($event.value)">
                        <mat-option *ngFor="let region of regions" [value]="region.id">{{region.name}}</mat-option>
                    </mat-select>
                </mat-form-field>

            </div>
        </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3">
        <div
            class="h-[20vh] hover:-translate-y-1 duration-700 ease-in-out flex items-center justify-center bg-gray-50/50 rounded-md shadow-md border-t-4 border-gray-100">
            <div class="align-middle">
                <div class="flex justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="size-20 text-sky-700">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 0 1-2.25 2.25M16.5 7.5V18a2.25 2.25 0 0 0 2.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 0 0 2.25 2.25h13.5M6 7.5h3v3H6v-3Z" />
                    </svg>
                </div>
                <div class="text-xl font-bold flex space-x-2 items-center">
                    <div class="text-sky-800 text-2xl">{{ totalTraders }}</div>
                    <div>
                        Registered Traders
                    </div>
                </div>
            </div>
        </div>
        <div
            class="h-[20vh] hover:-translate-y-1 duration-700 ease-in-out flex items-center justify-center bg-gray-50/50 rounded-md shadow-md border-t-4 border-gray-100">
            <div class="align-middle">
                <div class="flex justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="size-20 text-sky-800">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
                    </svg>
                </div>
                <div class="text-xl font-bold flex space-x-2 items-center">
                    <div class="text-sky-800 text-2xl">{{ totalBuyers }}</div>
                    <div>
                        Buyers
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 rounded-md mt-5">
        <div class="bg-inherit rounded-md w-full">
            <highcharts-chart [constructorType]="chartConstructor" [Highcharts]="Highcharts"
                [options]="applicationsChartOptions" class="chart">
            </highcharts-chart>
        </div>
        <!-- <div class="bg-inherit rounded-md w-full">
            <highcharts-chart [constructorType]="chartConstructor" [Highcharts]="Highcharts" [options]="chartOptions"
                class="chart">
            </highcharts-chart>
        </div> -->

        <!-- <div class="">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga maiores animi aspernatur ea asperiores dolores adipisci quisquam ullam ipsa eaque non tempora possimus consequatur eligendi quasi, perspiciatis voluptatibus veniam. Praesentium!
        </div> -->
    </div>
</div>