<div class="relative">
    <div id="content-header" class="absolute -top-24">
        <h1 class="text-uppercase font-bold">Manage Orders</h1>
    </div>
    <div [ngClass]="!isLoading ? 'show' : 'hide'">
        <div class="d-flex justify-content-start mt-1 bg-white">
            <div class="p-3">
                <button class="button" mat-button (click)="onAddOrder()"><span class="material-icons notranslate">add_circle_outline</span>Record Order</button>
            </div>
        </div>
        <div class="col-md-12">
            <div class="view">
                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table p-3">
                    <thead>
                        <tr>
                            <th>Order No.</th>
                            <th>Date</th>
                            <th>Price (TSh.)</th>
                            <th>Buyer</th>
                            <th>Status</th>
                            <th class="hidden">Recorded By</th>
                            <th width="5%">Action</th>
                        </tr>
    
                    </thead>
                    <tbody>
                        <tr *ngFor="let order of orders">
                            <td>{{order.orderno}}</td>
                            <td>{{order.order_date | date: mediumDate}}</td>
                            <td class="text-right">{{order.total_price | number}}</td>
                            <td>{{order.buyer_name}}</td>
                            <td>{{order.status}}</td>
                            <td class="hidden">{{order.user?.full_name}}</td>
                            <td class="flex space-x-2 items-center">
                                <a class="text-primary act" (click)="viewOrder(order.id)"><i class="fa fa-eye"></i></a>
                                <a class="text-success act" (click)="editOrder(order.id)"><i class="fa fa-edit"></i></a>
                                <mat-icon *ngIf="authService.isAdmin" class="text-danger act" (click)="deleteOrder(order.id)">delete_forever</mat-icon>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
    
        </div>
    </div>
    
    
    <div *ngIf="isLoading" class="spinner-holder">
      <mat-spinner class="spinner"></mat-spinner>
    </div>
</div>
