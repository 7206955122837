<form *ngIf="data.action == 'profile'" [formGroup]="userForm" (ngSubmit)="onSubmit()">
    <div class="title-holder">
        <h3 class="title" style="margin: 0px;color: #116B38;">Update profile</h3>
    </div>
    <br>
    <mat-form-field class="w-100" appearance="outline">
        <mat-label>Full Name</mat-label>
        <input matInput placeholder="Full Name" formControlName="fullname" required>
        <mat-error *ngIf="userForm.get('fullname').invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>
    <mat-form-field class="w-100" appearance="outline">
        <mat-label>email</mat-label>
        <input matInput placeholder="User Email" formControlName="email" required>
        <mat-error *ngIf="userForm.get('email').invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>
    <mat-form-field class="w-100" appearance="outline">
        <mat-label>Phone</mat-label>
        <input matInput placeholder="Phone number" type="tel" formControlName="phone" required>
        <mat-error *ngIf="userForm.get('phone').invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>
    <mat-form-field class="w-100" appearance="outline">
        <mat-label>username</mat-label>
        <input matInput placeholder="Username" formControlName="username" required>
        <mat-error *ngIf="userForm.get('username').invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>

    <div style="margin-top: 50px;display: flex;flex-direction: row;justify-content: flex-end;">
        <button (click)="onNoClick()" type="button" class="cancelbtn" style="width: 48%" mat-button>cancel</button>

        <button class="createbtn" mat-button>submit</button>
    </div>
</form>

<form *ngIf="data.action == 'passwords'" [formGroup]="passwordForm" (ngSubmit)="onSubmitPasswords()">
    <div class="title-holder">
        <h3 class="title" style="margin: 0px;color: #116B38;">Change passwords</h3>
    </div>
    <br>
    <mat-form-field class="w-100" appearance="outline">
        <mat-label>Current passwords</mat-label>
        <input matInput placeholder="Current passwords" type="password" formControlName="current" required>
        <mat-error *ngIf="passwordForm.get('current').invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>
    <mat-form-field class="w-100" appearance="outline">
        <mat-label>New passwords</mat-label>
        <input matInput placeholder="New passwords" type="password" formControlName="new" required>
        <mat-error *ngIf="passwordForm.get('new').invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>

    <div style="margin-top: 50px;display: flex;flex-direction: row;justify-content: flex-end;">
        <button (click)="onNoClick()" type="button" class="cancelbtn" style="width: 48%" mat-button>cancel</button>

        <button class="createbtn" mat-button>submit</button>
    </div>
</form>