<div [ngClass]="!isLoading ? 'show' : 'hide'" class="w-100">
    <div class="condition mt-1 d-flex justify-content-start" *ngIf="authService.isAllowed('manage-roles')">
        <div class="p-3">
            <button class="button" mat-button (click)="onAddUserRole()"><span class="material-icons notranslate">add_circle_outline</span>Add user role</button>
        </div>
    </div>
    <div class="view card w-75">
        <div class="table-responsive">
            <div class="card-body">
                <!-- i have removed below -->
                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" id="dtbs" class="table p-3">
                    <thead>
                        <tr>
                            <th width="5%">#</th>
                            <th>Role</th>
                            <th>Slug</th>
                            <th>Action</th>
                        </tr>

                    </thead>
                    <tbody>
                        <tr *ngFor="let role of roles;let i = index">
                            <td>{{i+1}}</td>
                            <td>{{role.role}}</td>
                            <td>{{role.slug}}</td>
                            <td style="display: flex; flex-direction: row;">
                                <a class="text-success act" (click)="editRole(role.id)"><i class="fa fa-edit"></i></a>
                                <mat-icon *ngIf="authService.isAdmin" class="text-danger act" (click)="deleteRole(role.id)">delete_forever</mat-icon>
                                <mat-icon class="text-primary act" title="assign permissions" (click)="assignPermissions(role.id)">lock_open</mat-icon>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

</div>


<div *ngIf="isLoading" class="spinner-holder">
  <mat-spinner class="spinner"></mat-spinner>
</div>
