import { Component, OnInit } from '@angular/core';
import { Validators, FormControl, FormBuilder } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { ManagementService } from '../../management/management.service';
import { BuyerService } from '../buyer.service';

@Component({
  selector: 'app-apply-permit',
  templateUrl: './apply-permit.component.html',
  styleUrls: ['./apply-permit.component.css']
})
export class ApplyPermitComponent implements OnInit {

  user;

  regions;
  districts;
  wards;
  villages;
  crops;
  markets;
  edit;
  countries;
  isRequired = false;
  isDomestic = false;
  isIndividual = true;
  tinmask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
  nidamask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
  editCrops = [];
  selectedImage;
  selectedBusinessLicense;
  selectedCertificateOfInc;
  selectedTINCertificate;
  selectedVATCertificate;
  selectedTaxClearance;
  selectedNida;
  selectedLga;
  imageSrc: string;

  submitting = false;
  showIndividual = true;
  page = 'one';

  // inputCounter = 0;
  // inputs = [{
  //   id: 'market'
  // }];

  marketCouncils;

  addForm = this.fb.group({
    full_name: ['', Validators.required],
    phone: ['', Validators.pattern(/^(255)[0-9]{9}$/)],
    category: [''],
    email: ['', [Validators.pattern(/.+@.+\..+/), Validators.required]],
    address: [''],
    company_name: [''],
    tin_number: [''],
    date: [''],
    type: [''],
    crops: ['', Validators.required],
    markets: ['', Validators.required],
    village_id: [''],
    ward_id: [''],
    district_id: [''],
    region_id: [''],
    country_id: [''],
    reg_agency: [''],
    business_license: [''],
    certificate_of_inc: [''],
    nida: [''],
    tin_certificate: [''],
    tax_clearance: [''],
    nida_certificate: [''],
    lga_permit: [''],
    market_category: [''],
    image: [''],
    reg_date: [''],
    operation_date: [''],
    registration_agency: [''],
    vat_reg_number: [''],
    license_number: [''],
    cert_of_inc_number: [''],
    // tax_number: [''],
    vat_certificate: [''],
    buying_capacity: [''],
    bank_name: [''],
    branch: [''],
    bank_acct: [''],
    swift_code: [''],
    councils: ['', [Validators.required]],
  });


  constructor(
    private fb: FormBuilder,
    private manServ: ManagementService,
    private snackBar: MatSnackBar,
    private dateAdapter: DateAdapter<Date>,
    private authService: AuthService,
    private buyerService: BuyerService,
    private route: Router
  ) {
    this.dateAdapter.setLocale('en-GB'); // dd/MM/yyyy
  }

  ngOnInit(): void {
    this.getUserDetails();
    this.getCountries();
    this.getAllRegions();
    this.getMarkets();
    this.getCrops();
    this.addForm.get('date').setValue(new Date());
  }

  toNext() {
    this.page = 'two';
  }
  toPrev() {
    this.page = 'one';
  }

  changeTypeValidation(type) {
    if (type === 'physical_market') {
      this.isDomestic = true;
      this.addForm.get('region_id').setValidators(Validators.required);
      this.addForm.get('district_id').setValidators(Validators.required);
      // this.addForm.get('ward_id').setValidators(Validators.required);
      // this.addForm.get('village_id').setValidators(Validators.required);
      this.addForm.get('reg_agency').clearValidators();
      this.addForm.get('country_id').clearValidators();
      this.addForm.get('country_id').setValue('223');

      this.addForm.get('bank_name').clearValidators();
      this.addForm.get('branch').clearValidators();
      this.addForm.get('bank_acct').clearValidators();
      this.addForm.get('swift_code').clearValidators();

      this.addForm.get('bank_name').setValue('');
      this.addForm.get('branch').setValue('');
      this.addForm.get('bank_acct').setValue('');
      this.addForm.get('swift_code').setValue('');
    }
    else {
      this.isDomestic = false;
      this.addForm.get('reg_agency').setValidators(Validators.required);
      this.addForm.get('country_id').setValidators(Validators.required);

      this.addForm.get('region_id').clearValidators();
      this.addForm.get('district_id').clearValidators();
      this.addForm.get('ward_id').clearValidators();
      this.addForm.get('village_id').clearValidators();

      this.addForm.get('region_id').setValue('');
      this.addForm.get('district_id').setValue('');
      this.addForm.get('ward_id').setValue('');
      this.addForm.get('village_id').setValue('');

      this.addForm.get('category').setValue('company');
      this.changeCategoryValidation('company');


      this.addForm.get('bank_name').setValidators(Validators.required);
      this.addForm.get('branch').setValidators(Validators.required);
      this.addForm.get('bank_acct').setValidators(Validators.required);
      this.addForm.get('swift_code').setValidators(Validators.required);

    }
  }

  getUserDetails() {
    var id = localStorage.getItem('asdsUserId');
    this.manServ.getSingleUser(id).subscribe(
      (data: any) => {
        this.user = data;
        // console.log(data)
        this.addForm.get('full_name').setValue(data.full_name)
        this.addForm.get('phone').setValue(data.phone)
        this.addForm.get('email').setValue(data.email)
        this.addForm.get('address').setValue(data.address)
      }
    );
  }

  changeCategoryValidation(type) {
    if (type === 'individual') {
      this.isIndividual = true;
      this.addForm.get('nida').setValidators(Validators.required);

      // this.addForm.get('tin_number').clearValidators();
      // this.addForm.get('company_name').clearValidators();
      this.addForm.get('tax_clearance').clearValidators();
      this.addForm.get('certificate_of_inc').clearValidators();
      // this.addForm.get('tin_certificate').clearValidators();
      this.addForm.get('business_license').clearValidators();

      this.addForm.get('tin_certificate').setValidators(Validators.required);
      this.addForm.get('lga_permit').setValidators(Validators.required);

      // this.addForm.get('tin_number').setValue('');
      // this.addForm.get('company_name').setValue('');
      // this.addForm.get('tax_clearance').setValue('');
      // this.addForm.get('certificate_of_inc').setValue('');
      // this.addForm.get('tin_certificate').setValue('');
      // this.addForm.get('business_license').setValue('');
    }
    else {
      this.isIndividual = false;
      this.addForm.get('tin_number').setValidators(Validators.required);
      this.addForm.get('company_name').setValidators(Validators.required);
      this.addForm.get('tax_clearance').setValidators(Validators.required);
      this.addForm.get('certificate_of_inc').setValidators(Validators.required);
      this.addForm.get('tin_certificate').setValidators(Validators.required);
      this.addForm.get('business_license').setValidators(Validators.required);

      this.addForm.get('nida').clearValidators();
      this.addForm.get('nida_certificate').clearValidators();
      this.addForm.get('lga_permit').clearValidators();

      this.addForm.get('nida').setValue('');
    }
  }

  getCountries() {
    this.manServ.getCountries().subscribe(
      data => {
        this.countries = data;
      }
    );
  }
  getAllRegions() {
    this.manServ.getAllRegions().subscribe(
      data => {
        this.regions = data;
      }
    );
  }

  getDistricts(regionId) {
    this.manServ.getAllDistricts(regionId).subscribe(
      data => {
        this.districts = data;
      }
    );
  }

  getWards(districtId) {
    this.manServ.getWards(districtId).subscribe(
      data => {
        this.wards = data;
      }
    );
  }

  getVillages(wardId) {
    this.manServ.getVillages(wardId).subscribe(
      data => {
        this.villages = data;
      }
    );
  }

  getCrops() {
    this.buyerService.getAllCrops().subscribe(
      data => {
        this.crops = data;
      }
    );
  }
  getErrorMessage() {
    return 'This field is required';
  }

  getMarkets() {
    this.manServ.getAllRegions().subscribe(
      data => {
        this.markets = data;
        // console.log(data);

      }
    );
  }
  getEmailErrorMessage() {
    return 'Wrong email pattern';
  }
  getPhoneErrorMessage() {
    return 'Wrong phone pattern, please check the placeholder';
  }

  goBack() {
    this.route.navigate(['buyers-portal']);
  }

  getMyRole() {
    this.authService.fetchTraderCategory().subscribe(
      (data: any) => {
        // console.log(data)
        localStorage.setItem('trader_category', data);
      }
    );

    this.authService.fetchMyRole().subscribe(
      (data: any) => {
        // console.log(data)
        localStorage.setItem('role', data);
      }
    );
  }

  getCouncils() {
    console.log(this.addForm.get('markets').value);
    this.buyerService.getRegionCouncils(this.addForm.get('markets').value).subscribe(
      data => {
        this.marketCouncils = data;
        // console.log(data);

      }
    );
  }

  onSubmit() {
    // console.log(this.addForm.value);
    // console.log(localStorage.getItem('asdsUserId'));
    var formData: any = new FormData();
    formData.append('full_name', this.addForm.controls['full_name'].value)
    formData.append('address', this.addForm.controls['address'].value)
    formData.append('phone', this.addForm.controls['phone'].value)
    formData.append('tin_number', this.addForm.controls['tin_number'].value)
    formData.append('category', this.addForm.controls['category'].value)
    formData.append('email', this.addForm.controls['email'].value)
    formData.append('company_name', this.addForm.controls['company_name'].value)
    formData.append('region_id', this.addForm.controls['region_id'].value)
    formData.append('district_id', this.addForm.controls['district_id'].value)
    formData.append('ward_id', this.addForm.controls['ward_id'].value)
    formData.append('village_id', this.addForm.controls['village_id'].value)
    formData.append('country_id', this.addForm.controls['country_id'].value)
    formData.append('nida', this.addForm.controls['nida'].value)
    formData.append('type', this.addForm.controls['type'].value)
    formData.append('user', localStorage.getItem('asdsUserId'))
    formData.append('image', this.selectedImage)
    formData.append('business_license', this.selectedBusinessLicense)
    formData.append('certificate_of_inc', this.selectedCertificateOfInc)
    formData.append('tin_certificate', this.selectedTINCertificate)
    formData.append('tax_clearance', this.selectedTaxClearance)
    formData.append('nida_certificate', this.selectedNida)
    formData.append('lga_permit', this.selectedLga)
    formData.append('vat_reg_number', this.addForm.controls['vat_reg_number'].value)
    formData.append('license_number', this.addForm.controls['license_number'].value)
    formData.append('cert_of_inc_number', this.addForm.controls['cert_of_inc_number'].value)
    // // formData.append('tax_number',  this.addForm.controls['tax_number'].value)
    formData.append('vat_certificate', this.selectedVATCertificate)
    formData.append('crops', this.addForm.controls['crops'].value)
    formData.append('market_category', this.addForm.controls['market_category'].value)
    formData.append('markets', this.addForm.controls['markets'].value)
    formData.append('buying_capacity', this.addForm.controls['buying_capacity'].value)
    formData.append('bank_name', this.addForm.controls['bank_name'].value)
    formData.append('branch', this.addForm.controls['branch'].value)
    formData.append('bank_acct', this.addForm.controls['bank_acct'].value)
    formData.append('swift_code', this.addForm.controls['swift_code'].value)
    formData.append('councils', this.addForm.controls['councils'].value)
    formData.append('reg_agency', this.addForm.controls['reg_agency'].value)

    // this.addForm.get('category').setValue(this.data.category);
    // const formData = {
    //   ...this.addForm.value,
    //   'user_id':  localStorage.getItem('asdsUserId')
    // }
    // console.log(formData.get('user'));

    if (this.addForm.valid) {
      // console.log('valid');
      this.submitting = true;
      this.buyerService.requestPermitApplications(formData).subscribe(
        (resp: any) => {
          this.submitting = false;
          if(resp['failed'] === 0) {
            this.snackBar.open('Submited', 'Application sent successfully!', {
              duration: 10000
            });
            this.getMyRole();
            this.route.navigate(['/buyers-portal'])
            
          } else {
            this.snackBar.open('Failed', resp['failed'], {
              duration: 10000
            });
          }
        }
      );
    } else {
      // console.log('invalid');
      var fields = '';
      Object.keys(this.addForm.controls).forEach(field => {
        const control = this.addForm.get(field);
        if (control.errors) {
          fields += field + ', '
          console.error(`Validation errors for ${field}: `, control.errors);
        }
      });
      this.snackBar.open('Error', 'Make sure ' + fields + ' fields are filled', {
        duration: 10000
      });
    }
  }


  onFileChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {

        this.imageSrc = reader.result as string;

        // this.userForm.patchValue({
        //   fileSource: reader.result,
        // });

        this.selectedImage = event.srcElement.files[0];
      };

    }
  }

  onBusinessLicenseChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.selectedBusinessLicense = event.srcElement.files[0];
      };

    }
  }

  onTINCertificateChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.selectedTINCertificate = event.srcElement.files[0];
      };

    }
  }

  onTaxClearanceChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.selectedTaxClearance = event.srcElement.files[0];
      };

    }
  }

  onCertificateOfIncChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.selectedCertificateOfInc = event.srcElement.files[0];
      };

    }
  }

  onNidaCertificateChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.selectedNida = event.srcElement.files[0];
      };

    }
  }
  onLGAPermitChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.selectedLga = event.srcElement.files[0];
      };

    }
  }
  onVATCertificateChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.selectedVATCertificate = event.srcElement.files[0];
      };

    }
  }

  // addRow(){
  //   console.log('here');

  //   var inputTemplate = {
  //     id: 'market-' + this.inputCounter,
  //     name: ''
  //   };
  //   this.inputCounter += 1;
  //   this.inputs.push(inputTemplate);

  //   console.log(this.inputs);

  // };

}
