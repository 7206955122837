import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ManagementService } from './../../management.service';

export interface DialogData {
  id;
  action;
  region;
  district;
  ward;

}

@Component({
  selector: 'app-receivein-market',
  templateUrl: './receivein-market.component.html',
  styleUrls: ['./receivein-market.component.css']
})
export class ReceiveinMarketComponent implements OnInit {
  markets;
  regions;
  districts;
  wards;
  villages;
  crops;
  brokers;
  edit;
  units;


  originWarehouses;
  originMarkets;

  fromWarehouse = false;
  fromMarket = false;

  receiveForm = this.fb.group({
    date: ['', Validators.required],
    market_id: ['', Validators.required],
    trader_id: ['', ],
    crop_id: ['', Validators.required],
    quality: ['',],
    quantity: ['', Validators.required],
    total_price: ['', ],
    retail_price: ['', ],
    cess_payment: [''],
    price_unit: [''],
    cess_unit: [''],
    village_id: [''],
    ward_id: [''],
    district_id: ['', Validators.required],
    region_id: ['', Validators.required],
    source: ['', Validators.required],
    origin_warehouse : ['', Validators.required],
    origin_market : ['', Validators.required],
  });

  editForm = this.fb.group({
    date: ['', Validators.required],
    market_id: ['', Validators.required],
    trader_id: ['', Validators.required],
    crop_id: ['', Validators.required],
    quality: ['', Validators.required],
    quantity: ['',],
    total_price: ['', ],
    retail_price: ['', ],
    cess_payment: [''],
    village_id: [''],
    ward_id: [''],
    price_unit: [''],
    cess_unit: [''],
    district_id: ['', Validators.required],
    region_id: ['', Validators.required],
    source: ['', Validators.required],
    origin_warehouse : ['', Validators.required],
    origin_market : ['', Validators.required],
  });
  constructor(
    public dialogRef: MatDialogRef<ReceiveinMarketComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private manServ: ManagementService,
    private snackBar: MatSnackBar,
    private dateAdapter: DateAdapter<Date>,
    ) {
      this.dateAdapter.setLocale('en-GB'); // dd/MM/yyyy
    }
  ngOnInit(): void {
    this.getMarkets();
    this.getAllRegions();
    this.getBrokers();
    this.getUnits();
    this.getCrops();

    this.receiveForm.get('date').setValue(new Date());

    if (this.data.action === 'edit') {
      this.getDistricts(this.data.region);
      this.getWards(this.data.district);
      this.getVillages(this.data.ward);

      this.manServ.getReceivedInMarketSingleData(this.data.id).subscribe(
        resp => {
          this.edit = resp;

          // this.getTraderCrops(this.edit.trader_id);
          this.editForm.get('date').setValue(this.edit.date);
          this.editForm.get('market_id').setValue(this.edit.market_id);
          this.editForm.get('trader_id').setValue(this.edit.trader_id);
          this.editForm.get('crop_id').setValue(this.edit.crop_id);
          this.editForm.get('quality').setValue(this.edit.quality);
          this.editForm.get('source').setValue(this.edit.source);
          this.editForm.get('quantity').setValue(this.edit.quantity);
          this.editForm.get('total_price').setValue(this.edit.total_price);
          this.editForm.get('retail_price').setValue(this.edit.retail_price);
          this.editForm.get('cess_payment').setValue(this.edit.cess_payment);
          this.editForm.get('village_id').setValue(this.edit.village_id);
          this.editForm.get('ward_id').setValue(this.data.ward);
          this.editForm.get('district_id').setValue(this.data.district);
          this.editForm.get('region_id').setValue(this.data.region);

          if (this.edit.source === 'Warehouse') {
            this.editForm.get('origin_warehouse').setValue(this.edit.origin_warehouse);
            this.fromMarket = false;
            this.fromWarehouse = true;
            this.editForm.get('origin_market').clearValidators();
            this.editForm.get('origin_market').setValue('');
          } else if (this.edit.source === 'Market') {
            this.editForm.get('origin_market').setValue(this.edit.origin_market);
            this.fromWarehouse = false;
            this.fromMarket = true;
            this.editForm.get('origin_warehouse').clearValidators();
            this.editForm.get('origin_warehouse').setValue('');
          }
        }
      );
    }
  }
  getUnits() {
    this.manServ.getUoms().subscribe(
      data => {
        this.units = data;

      }
    );
  }
  getAllRegions() {
    var id = localStorage.getItem('asdsUserId');
    this.manServ.getRegions(id).subscribe(
      data => {
        this.regions = data;
      }
    );
  }

  getDistricts(regionId) {
    var id = localStorage.getItem('asdsUserId');
    this.manServ.getDistricts(regionId, id).subscribe(
      data => {
        this.districts = data;
      }
    );
  }

  getWards(districtId) {
    this.getAreaData(districtId);
    this.manServ.getWards(districtId).subscribe(
      data => {
        this.wards = data;
      }
    );
  }

  getVillages(wardId) {
    this.manServ.getVillages(wardId).subscribe(
      data => {
        this.villages = data;
      }
    );
  }


  getAreaData(districtId) {
    this.manServ.availableAreaWarehouses(districtId).subscribe(
      data => {
        this.originWarehouses = data;
        // if (data === 'nothing') {
        //   this.snackBar.open('', 'No warehouse found', {
        //     duration: 5000
        //   });
        // }
      }
    );
    this.manServ.allAvailableAreaMarkets(districtId).subscribe(
      data => {
        this.originMarkets = data;
        // if (data === 'nothing') {
        //   this.snackBar.open('', 'No warehouse found', {
        //     duration: 5000
        //   });
        // }
      }
    );
  }

  getMarkets() {
    this.manServ.availableAreaMarkets(localStorage.getItem('asdsUserId')).subscribe(
      data => {
        this.markets = data;
        if (data['resp'] === 'nothing') {
          this.snackBar.open('', 'No market found', {
            duration: 5000
          });
        }
      }
    );
  }


  chooseSource(value) {
    if (value === 'Warehouse') {
      this.fromMarket = false;
      this.fromWarehouse = true;

      this.receiveForm.get('origin_warehouse').setValidators(Validators.required);
      this.receiveForm.get('origin_market').clearValidators();
      this.receiveForm.get('origin_market').setValue('');
      this.editForm.get('origin_warehouse').setValidators(Validators.required);
      this.editForm.get('origin_market').clearValidators();
      this.editForm.get('origin_market').setValue('');
    } else if (value === 'Market') {
      this.fromWarehouse = false;
      this.fromMarket = true;

      this.receiveForm.get('origin_market').setValidators(Validators.required);
      this.receiveForm.get('origin_warehouse').clearValidators();
      this.receiveForm.get('origin_warehouse').setValue('');
      this.editForm.get('origin_market').setValidators(Validators.required);
      this.editForm.get('origin_warehouse').clearValidators();
      this.editForm.get('origin_warehouse').setValue('');
    } else {
      this.fromWarehouse = false;
      this.fromMarket = false;

      this.receiveForm.get('origin_warehouse').clearValidators();
      this.receiveForm.get('origin_market').clearValidators();
      this.receiveForm.get('origin_market').setValue('');
      this.receiveForm.get('origin_warehouse').setValue('');
      this.editForm.get('origin_warehouse').clearValidators();
      this.editForm.get('origin_market').clearValidators();
      this.editForm.get('origin_market').setValue('');
      this.editForm.get('origin_warehouse').setValue('');
    }
  }
  getBrokers() {
    this.manServ.getCategoryTraders('Broker').subscribe(
      data => {
        this.brokers = data;
      }
    );
  }

  // getTraderCrops(id) {
  //   this.manServ.getTraderCrops(id).subscribe(
  //     data => {
  //       this.crops = data;
  //     }
  //   );
  // }

  getCrops() {
    this.manServ.getCrops().subscribe(
      data => {
        this.crops = data;
      }
    );
  }
  getErrorMessage() {
    return 'This field is required';
  }

  onNoClick() {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.receiveForm.valid) {
      this.manServ.receiveInMarket(this.receiveForm.value).subscribe(
        (resp: string) => {
          this.snackBar.open('success', resp['resp'], {
              duration: 5000
            });
          this.onNoClick();
        }
      );
    }
  }

  onUpdate() {
    if (this.editForm.valid) {
      this.manServ.updateReceivedInMarket(this.editForm.value, this.data.id).subscribe(
        (resp: string) => {
          this.snackBar.open('success', resp, {
              duration: 5000
            });
          this.onNoClick();
        }
      );
    }
  }

}
