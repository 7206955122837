<app-header></app-header>

<div class="relative sm:px-4 md:px-72">
    <div class="absolute right-1/3" *ngIf="isLoading">
        <img src="assets/loader.gif" alt="">
    </div>

    <div id="content-header" class="mt-3">
        <h1 class="text-uppercase font-bold">Application Details</h1>
    </div>
    <div class="w-full min-h-64">
        <!-- Profile tab -->
        <!-- About Section -->
        <div class="bg-white p-3 shadow-sm rounded-sm">
            <div class="flex justify-between items-center">
                <div class="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
                    <span clas="text-green-500">
                        <svg class="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                        </svg>
                    </span>
                    <span class="tracking-wide">Business Information</span>
                </div>
            </div>
            <div class="px-4">
                <div class="text-gray-700">
                    <div
                        class="grid md:grid-cols-2 text-sm [&>*:nth-child(even)]:bg-[#F6F9FF]">
                        <div class="flex space-x-2 items-center">
                            <div class="px-2 py-2 font-semibold">Full Name</div>
                            <div class="px-2 py-2 col-span-2">{{ application?.trader?.full_name }}</div>
                        </div>
                        <div class="flex space-x-2 items-center">
                            <div class="px-2 py-2 font-semibold">Contact No.</div>
                            <div class="px-2 py-2 col-span-2">{{ application?.trader?.user?.phone }}</div>
                        </div>
                        <div class="flex space-x-2 items-center">
                            <div class="px-2 py-2 font-semibold">Address</div>
                            <div class="px-2 py-2 col-span-2">{{ application?.trader?.address }}</div>
                        </div>
                        <div class="flex space-x-2 items-center">
                            <div class="px-2 py-2 font-semibold">Email.</div>
                            <div class="px-2 py- col-span-2">
                                <a class="text-blue-800 text-wrap"
                                    href="mailto:{{ application?.trader?.user?.email }}">{{
                                    application?.trader?.user?.email }}</a>
                            </div>
                        </div>
                        <div class="flex space-x-2 items-center">
                            <div class="px-2 py-2 font-semibold"> Type</div>
                            <div class="px-2 py-2 col-span-2">{{ application?.trader?.type }}</div>
                        </div>
                    </div>
                </div>
                <div class="py-2 bg-gray-100"></div>
                <div class="text-gray-700">
                    <div
                        class="grid md:grid-cols-2 text-sm [&>*:nth-child(even)]:bg-[#F6F9FF]">
                        <div class="flex space-x-2 items-center" *ngIf="application?.trader?.type === 'international'">
                            <div class="px-2 py-2 font-semibold">Country</div>
                            <div class="px-2 py-2 col-span-2">{{ application?.country }}</div>
                        </div>
                        <div class="flex space-x-2 items-center"
                            *ngIf="application?.trader?.buyer_category === 'individual'">
                            <div class="px-2 py-2 font-semibold">NIDA</div>
                            <div class="px-2 py-2 col-span-3">{{ application?.trader?.nida }}</div>
                        </div>
                    </div>
                    <div class="grid md:grid-cols-2 text-sm [&>*:nth-child(even)]:bg-[#F6F9FF]"
                        *ngIf="application?.trader?.type !== 'international'">
                        <div class="flex space-x-2 items-center">
                            <div class="px-2 py-2 font-semibold">Region</div>
                            <div class="px-2 py-2 col-span-2">{{ application?.region.name }}</div>
                        </div>
                        <div class="flex space-x-2 items-center">
                            <div class="px-2 py-2 font-semibold">District</div>
                            <div class="px-2 py-2 col-span-2">{{ application?.district?.name }}</div>
                        </div>
                        <div class="flex space-x-2 items-center">
                            <div class="px-2 py-2 font-semibold">Ward</div>
                            <div class="px-2 py-2 col-span-2">{{ application?.ward?.name }}</div>
                        </div>
                        <div class="flex space-x-2 items-center">
                            <div class="px-2 py-2 font-semibold">Village/Street</div>
                            <div class="px-2 py-2 col-span-2">{{ application?.village?.name }}</div>
                        </div>
                    </div>
                    <div
                        class="grid md:grid-cols-2 text-sm [&>*:nth-child(even)]:bg-[#F6F9FF]">
                        <!-- *ngIf="application?.trader?.buyer_category !== 'individual'" -->
                        <div class="flex space-x-2 items-center">
                            <div class="px-2 py-2 font-semibold">Business Name</div>
                            <div class="px-2 py-2 col-span-2">{{ application?.trader?.company_name }}</div>
                        </div>
                        <div class="flex space-x-2 items-center">
                            <div class="px-2 py-2 font-semibold">TIN Number</div>
                            <div class="px-2 py-2 col-span-2">{{ application?.trader?.tin_number }}</div>
                        </div>
                        <div class="flex space-x-2 items-center">
                            <div class="px-2 py-2 font-semibold">License Number</div>
                            <div class="px-2 py-2 col-span-2">{{ application?.trader?.license_number }}</div>
                        </div>
                        <div class="flex space-x-2 items-center">
                            <div class="px-2 py-2 font-semibold">Certificate of Inc. Number</div>
                            <div class="px-2 py-2 col-span-2">{{ application?.trader?.cert_of_inc_number }}</div>
                        </div>
                        <div class="flex space-x-2 items-center">
                            <div class="px-2 py-2 font-semibold">Tax Number</div>
                            <div class="px-2 py-2 col-span-2">{{ application?.trader?.tax_number }}</div>
                        </div>
                        <div class="flex space-x-2 items-center">
                            <div class="px-2 py-2 font-semibold">VAT Number</div>
                            <div class="px-2 py-2 col-span-2">{{ application?.trader?.vat_reg_number }}</div>
                        </div>
                        <div class="flex space-x-2 items-center"
                            *ngIf="application?.trader?.buyer_category !== 'individual'">
                            <div class="px-2 py-2 font-semibold">Bank Name</div>
                            <div class="px-2 py-2 col-span-2">{{ application?.trader?.bank_name }}</div>
                        </div>
                        <div class="flex space-x-2 items-center"
                            *ngIf="application?.trader?.buyer_category !== 'individual'">
                            <div class="px-2 py-2 font-semibold">Branch</div>
                            <div class="px-2 py-2 col-span-2">{{ application?.trader?.branch }}</div>
                        </div>
                        <div class="flex space-x-2 items-center"
                            *ngIf="application?.trader?.buyer_category !== 'individual'">
                            <div class="px-2 py-2 font-semibold">Account Number</div>
                            <div class="px-2 py-2 col-span-2">{{ application?.trader?.bank_acct }}</div>
                        </div>
                        <div class="flex space-x-2 items-center"
                            *ngIf="application?.trader?.buyer_category !== 'individual'">
                            <div class="px-2 py-2 font-semibold">SWIFT Code</div>
                            <div class="px-2 py-2 col-span-2">{{ application?.trader?.swift_code }}</div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <!-- End of about section -->

        <div class="my-4"></div>

        <!-- Attachments -->
        <div class="bg-white p-3 shadow-sm rounded-sm">

            <div class="grid grid-cols-1 items-center">
                <div>
                    <div class="flex  col-span-2 items-center space-x-2 font-semibold text-gray-900 leading-8 mb-3">
                        <span clas="text-green-500">
                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                            </svg>
                        </span>
                        <span class="tracking-wide">Certificates</span>
                    </div>
                    <ul class="list-inside grid grid-cols-1 md:grid-cols-6 gap-2 px-4">
                        <li *ngIf="application?.trader?.business_license">
                            <div class="text-teal-600"><a
                                    href="{{ attachmentUrl }}{{ application?.trader?.business_license }}"
                                    target="_blank" rel="noopener noreferrer">Business License</a></div>
                            <div class="text-gray-500 text-xs">business_license</div>
                        </li>
                        <li *ngIf="application?.trader?.tax_clearance">
                            <div class="text-teal-600"><a
                                    href="{{ attachmentUrl }}{{ application?.trader?.tax_clearance }}" target="_blank"
                                    rel="noopener noreferrer">Tax Clearance Certificate</a></div>
                            <div class="text-gray-500 text-xs">tax_clearance</div>
                        </li>
                        <li *ngIf="application?.trader?.tin_certificate">
                            <div class="text-teal-600"><a
                                    href="{{ attachmentUrl }}{{ application?.trader?.tin_certificate }}" target="_blank"
                                    rel="noopener noreferrer">TIN Certificate</a></div>
                            <div class="text-gray-500 text-xs">tin_certificate</div>
                        </li>
                        <li *ngIf="application?.trader?.certificate_of_inc">
                            <div class="text-teal-600"><a
                                    href="{{ attachmentUrl }}{{ application?.trader?.certificate_of_inc }}"
                                    target="_blank" rel="noopener noreferrer">Certificate of Incorporation</a></div>
                            <div class="text-gray-500 text-xs">certificate_of_inc</div>
                        </li>
                        <li *ngIf="application?.trader?.nida_certificate">
                            <div class="text-teal-600"><a
                                    href="{{ attachmentUrl }}{{ application?.trader?.nida_certificate }}"
                                    target="_blank" rel="noopener noreferrer">NIDA ID</a></div>
                            <div class="text-gray-500 text-xs">nida_certificate</div>
                        </li>
                        <li *ngIf="application?.lga_permit">
                            <div class="text-teal-600"><a
                                    href="{{ attachmentUrl }}{{ application?.trader?.lga_permit }}" target="_blank"
                                    rel="noopener noreferrer">LGA's License</a></div>
                            <div class="text-gray-500 text-xs">lga_permit</div>
                        </li>
                        <li *ngIf="application?.trader?.vat_certificate">
                            <div class="text-teal-600"><a
                                    href="{{ attachmentUrl }}{{ application?.trader?.vat_certificate }}" target="_blank"
                                    rel="noopener noreferrer">VAT Certificate</a></div>
                            <div class="text-gray-500 text-xs">vat_certificate</div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- End of Attachments -->
        <div class="bg-white p-3 shadow-sm rounded-sm">
            <div class="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
                <span clas="text-green-500">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-4 h-4">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" />
                    </svg>

                </span>
                <span class="tracking-wide">Market & Crop Information</span>
            </div>
            <div class="px-4">
                <div class="md:w-1/3">
                    <div class="flex space-x-2 items-center">
                        <div class="px-2 py-2 font-semibold">Market Category</div>
                        <div class="px-2 py-2 col-span-2">{{ application?.market_category?.replace('_', ' ') }}</div>
                    </div>
                </div>
                <div class="grid grid-cols-1 md:grid-cols-4 px-4">
                    <div class="">
                        <div class="font-bold py-2">Markets</div>
                        <div class="px-2">
                            <div class="flex space-x-2" *ngFor="let market of application?.markets">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                        stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-gray-600">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                            d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
                                    </svg>
                                </span>
                                {{ market?.market?.name }}
                            </div>
                        </div>
                    </div>
                    <div class="">
                        <div class="font-bold py-2">Crops</div>
                        <div class="px-2">
                            <div class="flex space-x-2 notranslate" *ngFor="let crop of application?.crops">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                        stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-gray-600">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                            d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
                                    </svg>
                                </span>{{ crop?.crop.name }}
                            </div>
                        </div>
                    </div>
                    <div class="">
                        <div class="font-bold py-2">Councils</div>
                        <div class="px-2">
                            <div class="flex space-x-2" *ngFor="let council of application?.councils">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                        stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-gray-600">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                            d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
                                    </svg>
                                </span>{{ council?.council.name }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-white p-3 shadow-sm rounded-sm">
            <div class="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
                <span clas="text-green-500">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-4 h-4">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                    </svg>


                </span>
                <span class="tracking-wide">Application Status</span>
            </div>
            <div class="px-4 py-2">
                <span class="py-2 px-8 rounded-md font-bold {{ application.status == 'approved' ? 'bg-green-800/5 text-green-600' : 'bg-red-800/5 text-red-600' }}">
                    {{ application.status }}
                </span>
            </div>
        </div>
        <div class="flex justify-end py-2" *ngIf="authService.isAllowed('verify-permit')">
            <button class="bg-green-500 px-2.5 py-1 rounded-lg items-center flex" (click)="onApprove()"
                *ngIf="application.status == 'pending'">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z" />
                </svg>
                <span class="px-2">Approve</span></button>
        </div>
        <div class="" *ngIf="authService.isBuyer || authService.isTemp">
            <div class="flex justify-end my-2"
                *ngIf="application?.status == 'pending' || application?.status == 'returned'">
                <a routerLink="/buyers-portal/update-permit/{{ Id }}"
                    class="text-gray-100 hover:no-underline hover:text-white bg-green-700 px-2 py-1.5 rounded-md cursor-pointer"><i
                        class="fa fa-edit"></i> Update</a>
            </div>

        </div>
    </div>
</div>