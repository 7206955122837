import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ManagementService } from './../../management.service';
import { MarketService } from '../market.service';

export interface DialogData {
  id;
  action;
  region;
  district;
  ward;
}

@Component({
  selector: 'app-add-market',
  templateUrl: './add-market.component.html',
  styleUrls: ['./add-market.component.css']
})
export class AddMarketComponent implements OnInit {
  regions;
  districts;
  wards;
  villages;
  edit;

  managers;

  marketForm = this.fb.group({
    name : ['', Validators.required],
    quality : ['', Validators.required],
    type : ['', Validators.required],
    manager : [''],
    village_id : [''],
    ward_id : [''],
    district_id : ['', Validators.required],
    region_id : ['', Validators.required],
    latitude : [''],
    longitude : [''],
  });

  editForm = this.fb.group({
    name : ['', Validators.required],
    quality : ['', Validators.required],
    type : ['', Validators.required],
    manager : [''],
    village_id : [''],
    ward_id : [''],
    district_id : ['', Validators.required],
    region_id : ['', Validators.required],
    latitude : [''],
    longitude : [''],
  });
  constructor(
    public dialogRef: MatDialogRef<AddMarketComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private manServ: ManagementService,
    private snackBar: MatSnackBar,
    private marketServ: MarketService
  ) { }

  ngOnInit(): void {
    this.getAllRegions();
    this.getManagers();

    if (this.data.action === 'edit') {

      this.getDistricts(this.data.region);
      this.getWards(this.data.district);
      this.getVillages(this.data.ward);

      this.manServ.getMarketSingleData(this.data.id).subscribe(
        resp => {
          this.edit = resp;
          this.editForm.get('name').setValue(this.edit.name);
          this.editForm.get('type').setValue(this.edit.type);
          this.editForm.get('quality').setValue(this.edit.quality);
          this.editForm.get('latitude').setValue(this.edit.lat);
          this.editForm.get('longitude').setValue(this.edit.long);
          this.editForm.get('region_id').setValue(this.data.region);
          this.editForm.get('district_id').setValue(this.data.district);
          this.editForm.get('ward_id').setValue(this.data.ward);
          this.editForm.get('village_id').setValue(this.edit.village_id);
          this.editForm.get('manager').setValue(Number(this.edit.manager));
        }
      );

    }
  }

  getManagers() {
    var id = localStorage.getItem('asdsUserId');
    this.marketServ.getMarketManagers(id).subscribe(
      data => {
        this.managers = data;
      }
    );
  }

  getAllRegions() {
    var id = localStorage.getItem('asdsUserId');
    this.manServ.getRegions(id).subscribe(
      data => {
        this.regions = data;
      }
    );
  }

  getDistricts(regionId) {
    var id = localStorage.getItem('asdsUserId');
    this.manServ.getDistricts(regionId, id).subscribe(
      data => {
        this.districts = data;
      }
    );
  }

  getWards(districtId) {
    this.manServ.getWards(districtId).subscribe(
      data => {
        this.wards = data;
      }
    );
  }

  getVillages(wardId) {
    this.manServ.getVillages(wardId).subscribe(
      data => {
        this.villages = data;
      }
    );
  }

  getErrorMessage() {
    return 'This field is required';
  }

  onNoClick() {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.marketForm.valid) {
      const formData = {
        ...this.marketForm.value,
        user_id: localStorage.getItem('asdsUserId')
      };
      // console.log(formData)
      this.manServ.addMarket(formData).subscribe(
        (data: string) => {
          if (data['resp'] === 'failed') {
            this.snackBar.open('error', 'Market exists', {
              duration: 5000
            });
          }
          else {
            this.snackBar.open('success', data['resp'], {
              duration: 5000
            });
            this.onNoClick();
          }
        }
      );
    }
  }

  onUpdate() {
    if (this.editForm.valid) {
      const formData = {
        ...this.editForm.value,
        // user_id: localStorage.getItem('asdsUserId')
      };
      // console.log(formData)
      this.manServ.updateMarket(formData, this.data.id).subscribe(
        (data: string) => {
          if (data['resp'] === 'failed') {
            this.snackBar.open('error', 'Market exists', {
              duration: 5000
            });
          }
          else {
            this.snackBar.open('success', data, {
              duration: 5000
            });
            this.onNoClick();
          }
        }
      );
    }
  }
}
