<div class="bg-white">
    <div *ngIf="isVisible">
        <h3>Matching info</h3>
        
        <!-- Header Row - Moved outside the *ngFor loop -->
        <div class="grid grid-cols-2 md:grid-cols-4 gap-2 px-0.5 py-2 font-bold bg-gray-200/50 rounded-md">
            <div class="">Name</div>
            <div class="">Phone</div>
            <div class="hidden md:flex">Type</div>
            <div class="hidden md:flex">Location</div>
        </div>

        <!-- Farmer Data Rows -->
        <div class="grid grid-cols-2 md:grid-cols-4 gap-2 w-full overflow-x-auto my-0.5" *ngFor="let farmer of farmers">
            <div class="py-1">
                <div class="bg-green-100/50 text-green-700 hover:text-green-600 cursor-pointer" (click)="setFarmer(farmer)">{{ farmer.full_name }}</div>
            </div>
            <div class="py-1">{{ farmer.phone }}</div>
            <div class="py-1 hidden md:flex">{{ farmer.farmer_type }}</div>
            <div class="py-1 hidden md:flex">{{ farmer.location }}</div>
        </div>
    </div>
</div>

