import { Component, Inject, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ManagementService } from '../../management.service';
import { CrossPointsService } from '../cross-points.service';

export interface DialogData {
  id;
  action;
  region;
  district;
  ward;
}
@Component({
  selector: 'app-add-point',
  templateUrl: './add-point.component.html',
  styleUrls: ['./add-point.component.css']
})
export class AddPointComponent implements OnInit {
  regions;
  districts;
  wards;
  villages;
  edit;

  crossPointForm = this.fb.group({
    name : ['', Validators.required],
    category : ['', Validators.required],
    village_id : [''],
    ward_id : [''],
    district_id : ['', Validators.required],
    region_id : ['', Validators.required],
    latitude : [''],
    longitude : [''],
  });

  editForm = this.fb.group({
    name : ['', Validators.required],
    category : ['', Validators.required],
    village_id : [''],
    ward_id : [''],
    district_id : ['', Validators.required],
    region_id : ['', Validators.required],
    latitude : [''],
    longitude : [''],
  });
  constructor(
    public dialogRef: MatDialogRef<AddPointComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private manServ: ManagementService,
    private snackBar: MatSnackBar,
    public crossP: CrossPointsService
  ) { }

  ngOnInit(): void {
    this.getAllRegions();

    if (this.data.action === 'edit') {

      this.getDistricts(this.data.region);
      this.getWards(this.data.district);
      this.getVillages(this.data.ward);

      this.crossP.getCrossPointSingleData(this.data.id).subscribe(
        resp => {
          this.edit = resp;
          this.editForm.get('name').setValue(this.edit.name);
          this.editForm.get('category').setValue(this.edit.category);
          this.editForm.get('latitude').setValue(this.edit.lat);
          this.editForm.get('longitude').setValue(this.edit.long);
          this.editForm.get('region_id').setValue(this.data.region);
          this.editForm.get('district_id').setValue(this.data.district);
          this.editForm.get('ward_id').setValue(this.data.ward);
          this.editForm.get('village_id').setValue(this.edit.village_id);
        }
      );

    }
  }

  getAllRegions() {
    var id = localStorage.getItem('asdsUserId');
    this.manServ.getRegions(id).subscribe(
      data => {
        this.regions = data;
      }
    );
  }

  getDistricts(regionId) {
    var id = localStorage.getItem('asdsUserId');
    this.manServ.getDistricts(regionId, id).subscribe(
      data => {
        this.districts = data;
      }
    );
  }

  getWards(districtId) {
    this.manServ.getWards(districtId).subscribe(
      data => {
        this.wards = data;
      }
    );
  }

  getVillages(wardId) {
    this.manServ.getVillages(wardId).subscribe(
      data => {
        this.villages = data;
      }
    );
  }

  getErrorMessage() {
    return 'This field is required';
  }

  onNoClick() {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.crossPointForm.valid) {
      this.crossP.addCrossPoint(this.crossPointForm.value).subscribe(
        (data: string) => {
          if (data['resp'] === 'failed') {
            this.snackBar.open('error', 'Record exists', {
              duration: 5000
            });
          }
          else {
            this.snackBar.open('success', data, {
              duration: 5000
            });
            this.onNoClick();
          }
        }
      );
    }
  }

  onUpdate() {
    if (this.editForm.valid) {
      this.crossP.updateCrossPoint(this.editForm.value, this.data.id).subscribe(
        (data: string) => {
          if (data['resp'] === 'failed') {
            this.snackBar.open('error', 'Record exists', {
              duration: 5000
            });
          }
          else {
            this.snackBar.open('success', data, {
              duration: 5000
            });
            this.onNoClick();
          }
        }
      );
    }
  }
}
