import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MarketService } from '../market.service';
import * as Highcharts from 'highcharts/highstock';
import HC_exporting from 'highcharts/modules/export-data';
import { ManagementService } from '../../management.service';
HC_exporting(Highcharts);

@Component({
  selector: 'app-market-summary',
  templateUrl: './market-summary.component.html',
  styleUrls: ['./market-summary.component.css']
})
export class MarketSummaryComponent implements OnInit, AfterViewInit {
    totalMarkets = 0
    totalPrimary = 0;
    totalSecondary = 0;
    loading = true;
    region_id = null;

    regions;
  
    Highcharts = Highcharts;
    chartOptions: {};
    chartConstructor = 'chart';
    constructor(
      public marketServ: MarketService,
      public manServ: ManagementService,
    ) { }
  
    ngAfterViewInit(): void {
      this.getData();
    }
    
    ngOnInit(): void {
      this.getAllRegions()
    }
  
    getAllRegions() {
      this.manServ.getAllRegions().subscribe(
        data => {
          this.regions = data;
        }
      );
    }

    getData() {
      this.loading = true;
      var data = {
        'region_id': this.region_id
      }
      this.marketServ.getData(data).subscribe(
        (data: any) => {
          console.log(data);
          
          this.totalMarkets = data.stats.totalMarkets;
          this.totalPrimary = data.stats.totalPrimary;
          this.totalSecondary = data.stats.totalSecondary;
  
  
          this.marketQualityChart(data.qualities);
          this.loading = false;
        }
      );
    }
  
  
    marketQualityChart(data) {
      this.chartOptions = {
        chart: {
          type: 'pie'
        },
        title: {
          text: 'Number of Markets',
          align: 'center'
        },
        tooltip: {
          valueSuffix: ''
        },
        subtitle: {
          text:
            'By Quality',
          align: 'center'
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false,
              format: '{point.name}: {point.percentage:.1f} %',
              distance: 10,
              style: {
                fontSize: '1.2em',
                textOutline: 'none',
                opacity: 0.7
              }
            },
            showInLegend: true
          }
        }, 
        legend: {
          enabled: true,
          align: 'right',
          verticalAlign: 'bottom',
          layout: 'vertical'
        },
        series: [data]
      };
  
    }

  setRegion(id) {
    this.region_id = id;
    this.getData();
  }
  }