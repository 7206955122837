<form *ngIf="data.action == 'add'" [formGroup]="userForm" (ngSubmit)="onSubmit()">
    <div class="title-holder">
        <h2 class="title">Register User</h2>
    </div>
    <mat-form-field class="w-100" appearance="outline">
        <mat-label>Full Name</mat-label>
        <input matInput placeholder="Full Name" formControlName="full_name" required>
        <mat-error *ngIf="userForm.get('full_name').invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>

    <div class="row">
      <div class="col-md-6 space1">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput placeholder="Email" formControlName="email" required >
            <mat-error *ngIf="userForm.get('email').invalid">{{getEmailErrorMessage()}}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6 space3">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Phone</mat-label>
            <input matInput placeholder="Phone" formControlName="phone" required>
            <mat-error *ngIf="userForm.get('phone').invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 space1">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Username</mat-label>
          <input matInput placeholder="Username" formControlName="username" required>
          <mat-error *ngIf="userForm.get('username').invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6 space3">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Passwords</mat-label>
            <input matInput type="password" placeholder="Passwords" formControlName="password" required>
            <mat-error *ngIf="userForm.get('password').invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
        <div class="col-md-6 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Regions</mat-label>
                <mat-select placeholder="Select Region" formControlName="region_id" required (selectionChange)="getDistricts($event.value)">
                    <mat-option *ngFor="let region of regions" [value]="region.id">{{region.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="userForm.get('region_id').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Districts</mat-label>
                <mat-select placeholder="Select District" formControlName="district_id" required (selectionChange)="getWards($event.value)">
                    <mat-option *ngFor="let district of districts" [value]="district.id">{{district.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="userForm.get('district_id').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Wards</mat-label>
                <mat-select placeholder="Select Ward" formControlName="ward_id" (selectionChange)="getVillages($event.value)">
                    <mat-option *ngFor="let ward of wards" [value]="ward.id">{{ward.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-6 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Villages/Streets</mat-label>
                <mat-select placeholder="Select Village" formControlName="village_id">
                    <mat-option *ngFor="let village of villages" [value]="village.id">{{village.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <mat-form-field class="w-100" appearance="outline">
        <mat-label>User Role</mat-label>
        <mat-select placeholder="Select role" formControlName="role_id" required (selectionChange)="selectedRole($event)">
            <mat-option *ngFor="let role of roles" [value]="role.id">{{role.role}}</mat-option>
        </mat-select>
        <mat-error *ngIf="userForm.get('role_id').invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>
    <mat-form-field class="w-100" appearance="outline" *ngIf="isMarket">
        <mat-label>Market</mat-label>
        <mat-select placeholder="Select market" formControlName="market_id">
            <mat-option *ngFor="let market of markets" [value]="market.id">{{market.name}}</mat-option>
        </mat-select>
        <mat-error *ngIf="userForm.get('market_id').invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>
    <mat-form-field class="w-100" appearance="outline" *ngIf="isWarehouse">
        <mat-label>Warehouse</mat-label>
        <mat-select placeholder="Select warehouse" formControlName="warehouse_id">
            <mat-option *ngFor="let warehouse of warehouses" [value]="warehouse.id">{{warehouse.name}}</mat-option>
        </mat-select>
        <mat-error *ngIf="userForm.get('warehouse_id').invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>

    <!-- <mat-form-field class="w-100" appearance="outline" >
        <mat-label>Picture (Operator)</mat-label>
        <input type="file" accept="image/*" formControlName="image" required />
        <mat-error *ngIf="userForm.get('image').invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field> -->
    <div class="w-100">
        <label>Picture</label>
        <!-- <button type="button" mat-raised-button (click)="fileInput.click()">Choose File</button> -->
        <!-- <input hid den (change)="onFileSelected($event)" formControlName="image" #fileInput type="file"> -->
        <input class="form-control" formControlName="image" accept="image/*" #fileInput type="file" (change)="onFileChange($event)">
        <span class="file-name">{{selectedFile?.name}}</span>
        <img [src]="imageSrc" *ngIf="imageSrc" style="height: 100px; width:100px; margin-top: 10px;">
    </div>


    <div style="margin-top: 30px; display: flex; flex-direction: row; justify-content: flex-end;">
        <button (click)="onNoClick()" class="cancelbtn" mat-button>Cancel</button>

        <button type="submit" class="createbtn" mat-button>Submit</button>
    </div>
</form>

<!-- updating form -->
<form *ngIf="data.action == 'edit'" [formGroup]="userFormEdit" (ngSubmit)="onUpdate()">
    <div class="title-holder">
        <h2 class="title">Update User</h2>
    </div>
    <mat-form-field class="w-100" appearance="outline">
        <mat-label>Full Name</mat-label>
        <input matInput placeholder="Full Name" formControlName="full_name" required>
        <mat-error *ngIf="userFormEdit.get('full_name').invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>
    <mat-form-field class="w-100" appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput placeholder="Email" formControlName="email" required >
        <mat-error *ngIf="userFormEdit.get('email').invalid">{{getEmailErrorMessage()}}</mat-error>
    </mat-form-field>
    <mat-form-field class="w-100" appearance="outline">
        <mat-label>Phone</mat-label>
        <input matInput placeholder="Phone" formControlName="phone" required>
        <mat-error *ngIf="userFormEdit.get('phone').invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>
    <mat-form-field class="w-100" appearance="outline">
        <mat-label>Username</mat-label>
        <input matInput placeholder="Username" formControlName="username" required>
        <mat-error *ngIf="userFormEdit.get('username').invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>
    <mat-form-field class="w-100" appearance="outline">
        <mat-label>Regions</mat-label>
        <mat-select placeholder="Select Region" formControlName="region_id" required (selectionChange)="getDistricts($event.value)">
            <mat-option *ngFor="let region of regions" [value]="region.id">{{region.name}}</mat-option>
        </mat-select>
        <mat-error *ngIf="userFormEdit.get('region_id').invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>
    <mat-form-field class="w-100" appearance="outline">
        <mat-label>Districts</mat-label>
        <mat-select placeholder="Select District" formControlName="district_id" required (selectionChange)="getWards($event.value)">
            <mat-option *ngFor="let district of districts" [value]="district.id">{{district.name}}</mat-option>
        </mat-select>
        <mat-error *ngIf="userFormEdit.get('district_id').invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>
    <mat-form-field class="w-100" appearance="outline">
        <mat-label>Wards</mat-label>
        <mat-select placeholder="Select Ward" formControlName="ward_id" (selectionChange)="getVillages($event.value)">
            <mat-option *ngFor="let ward of wards" [value]="ward.id">{{ward.name}}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="w-100" appearance="outline">
        <mat-label>Villages/Streets</mat-label>
        <mat-select placeholder="Select Village" formControlName="village_id">
            <mat-option *ngFor="let village of villages" [value]="village.id">{{village.name}}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="w-100" appearance="outline">
        <mat-label>User Role</mat-label>
        <mat-select placeholder="Select role" formControlName="role_id" (selectionChange)="selectedRoleUpdate($event)">
            <mat-option *ngFor="let role of roles" [value]="role.id">{{role.role}}</mat-option>
        </mat-select>
        <mat-error *ngIf="userFormEdit.get('role_id').invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>
    <mat-form-field class="w-100" appearance="outline" *ngIf="isMarket">
        <mat-label>Market</mat-label>
        <mat-select placeholder="Select market" formControlName="market_id">
            <mat-option *ngFor="let market of markets" [value]="market.id">{{market.name}}</mat-option>
        </mat-select>
        <mat-error *ngIf="userFormEdit.get('market_id').invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>
    <mat-form-field class="w-100" appearance="outline" *ngIf="isWarehouse">
        <mat-label>Warehouse</mat-label>
        <mat-select placeholder="Select warehouse" formControlName="warehouse_id">
            <mat-option *ngFor="let warehouse of warehouses" [value]="warehouse.id">{{warehouse.name}}</mat-option>
        </mat-select>
        <mat-error *ngIf="userFormEdit.get('warehouse_id').invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>

    <div class="w-100 mb-1">
        <label>Picture</label>
        <!-- <button type="button" mat-raised-button (click)="fileInput.click()">Choose File</button> -->
        <!-- <input hid den (change)="onFileSelected($event)" formControlName="image" #fileInput type="file"> -->
        <input class="form-control" formControlName="image" accept="image/*" #fileInput type="file" (change)="onFileChange($event)">
        <span class="file-name">{{selectedFile?.name}}</span>
        <img [src]="imageSrc" *ngIf="imageSrc" style="height: 100px; width:100px; margin-top: 10px;">
    </div>

    <mat-form-field class="w-100" appearance="outline">
        <mat-label>Status</mat-label>
        <mat-select placeholder="Change Status" formControlName="status">
            <mat-option [value]="1">Active</mat-option>
            <mat-option [value]="0">In Active</mat-option>
        </mat-select>
        <mat-error *ngIf="userFormEdit.get('status').invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>


    <div style="margin-top: 30px; display: flex; flex-direction: row; justify-content: flex-end;">
        <button (click)="onNoClick()" class="cancelbtn" mat-button>Cancel</button>

        <button type="submit" class="createbtn" mat-button>Update</button>
    </div>
</form>

<!-- updating form -->
<form *ngIf="data.action == 'edit_password'" [formGroup]="userFormEditPassword" (ngSubmit)="onUpdatePassword()">
  <div class="title-holder">
      <h2 class="title">Update User Passwords</h2>
  </div>
  <mat-form-field class="w-100" appearance="outline">
      <mat-label>Passwords</mat-label>
      <input matInput type="password" placeholder="New Passwords" formControlName="password" required>
      <mat-error *ngIf="userFormEditPassword.get('password').invalid">{{getErrorMessage()}}</mat-error>
  </mat-form-field>


  <div style="margin-top: 30px; display: flex; flex-direction: row; justify-content: flex-end;">
      <button (click)="onNoClick()" class="cancelbtn" mat-button>Cancel</button>

      <button type="submit" class="createbtn" mat-button>Update</button>
  </div>
</form>

