import { Component, OnInit } from '@angular/core';
import { ManagementService } from '../../management/management.service';
import { DefinitionService } from '../definition.service';
import {Chart} from 'node_modules/chart.js';
import { MatSelectChange } from '@angular/material/select';
import * as Highcharts from 'highcharts';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-market-dashb',
  templateUrl: './market-dashb.component.html',
  styleUrls: ['./market-dashb.component.css']
})
export class MarketDashbComponent implements OnInit {

  cropName;
  regions;
  districts;
  crops;
  stockTrend;
  stockPerRegions;
  stockMarkets;
  priceTrend;
  pricePerRegions;
  pricePerMarkets;

  pickedRegion = 'Tanzania';
  regionTitle = "Regions";
  yearTitle = "Yearly";

  selected = 0;
  cropId;
  years;

  clearType = false;
  clearRegion = false;
  clearDistrict = false;
  clearYear = false;

    // filter variables
    selectedRegion = null;
    selectedDistrict = null;
    selectedMarketType = null;
    selectedYear = null;

    Highcharts = Highcharts;
    chartOptions: {};
    chartOptionsRegions: {};
    chartOptionsMarkets: {};
    chartOptionsPriceTrend: {};
    chartOptionsPriceRegions: {};
    chartOptionsPriceMarkets: {};
    chartConstructor = 'chart';
  constructor(
    private manServ: ManagementService,
    private defn: DefinitionService,
    private authService: AuthService,
    private router: Router,
  ) { 
    if(this.authService.isAcManager) {
      this.router.navigate(['manage-ac/summaries'])
    } else {
      this.router.navigate(['/stock-in-warehouse'])
    }
  }

  ngOnInit(): void {
    this.allCrops();
  }

  getAllData() {
    this.allYears();
    this.allRegions();
    this.drawCharts();
  }

  getColor(change) {
    if (change > 0) {
        return '#116B38';
    }
    if (change < 0) {
        return '#C81212';
    }
    if (change === 0) {
      return '#0A3EA6';
    }
  }

  getUpperCase(value: string) {
    return value.toUpperCase();
  }


  onScrollLeft() {
    document.getElementById('list').scrollLeft += 240;
  }

  onScrollRight() {
    document.getElementById('list').scrollLeft -= 240;
  }

  isSScrollable() {
    if (document.getElementById('list') !== null) {
    if (document.getElementById('list').scrollWidth > document.getElementById('list').clientWidth) {
     return true;
    } else {
     return false;
    }
  }
    return false;
  }

  noCrops() {
    if (this.crops.length <= 0) {
       return true;
    } else {
      return false;
    }
  }

  onChangeCrop(cropId, cropName, i) {

    this.regionTitle = "Regions";
    this.yearTitle = "Yearly";

    // this.clearType = false;
    // this.clearRegion = false;
    this.clearDistrict = false;
    // this.clearYear = false;

    // this.selectedMarketType = null;
    // this.selectedRegion = null;
    this.selectedDistrict = null;
    // this.selectedYear = '';
    console.log(cropId);
    this.cropId = cropId;
    this.cropName = cropName;
    this.selected = i;
    this.marketsCharts(cropId);

   }

   allYears() {
    this.years = this.manServ.getYears();
  }

  allRegions() {
    var id = localStorage.getItem('asdsUserId');
    this.manServ.getRegions(id).subscribe(
      data => {
        this.regions = data;
        // console.log(this.regions)
      }
    );
  }

  getDistricts(regionId) {
    var id = localStorage.getItem('asdsUserId');
    this.manServ.getDistricts(regionId, id).subscribe(
      data => {
        this.districts = data;
        // console.log(this.districts)
      }
    );
  }

  selectMarketType(type) {
    this.selectedMarketType = type;
    this.clearType = true;
    this.filter();
  }

  selectYear(year) {
    this.selectedYear = year;
    this.clearYear = true;
    this.filter();
  }
  selectRegion(event: MatSelectChange) {
    this.selectedRegion = event.value;
    this.pickedRegion = event.source.triggerValue;
    this.clearRegion = true;
    this.getDistricts(event.value);
    this.filter();
  }

  selectDistrict(id) {
    this.selectedDistrict = id;
    this.clearDistrict = true;
    this.filter();
  }

  clearFilter(value) {
    if (value == 'type') {
      this.selectedMarketType = null;
      this.clearType = false;
      this.filter();
    }
    if (value == 'region') {
      this.selectedRegion = null;
      this.clearRegion = false;
      this.filter();
    }
    if (value == 'district') {
      this.selectedDistrict = null;
      this.clearDistrict = false;
      this.filter();
    }
    if (value == 'year') {
      this.selectedYear = '';
      this.clearYear = false;
      this.filter();
    }
  }

  allCrops() {
    this.manServ.getCrops().subscribe(
      data => {
        this.crops = data;
        this.cropId = this.crops[0].id;
        this.cropName = this.crops[0].name;

        this.getAllData();
      }
    );
  }

  drawCharts() {
    this.marketsCharts(this.cropId);
  }

  marketsCharts(crop) {
    this.defn.stockMarketCharts(crop).subscribe(
      data => {
        this.stockTrend = data[0];
        this.stockPerRegions = data[1];
        this.stockMarkets = data[2];
        this.priceTrend = data[3];
        this.pricePerRegions = data[4];
        this.pricePerMarkets = data[5];
        this.drawStockTrend();
        this.drawStockPerRegion();
        this.drawTopMarkets();
        this.drawPriceTrend();
        this.drawPricePerRegion();
        this.drawPricePerMarket();
      }
    );
  }

  // graphs
  drawStockTrend() {
    console.log(this.stockTrend)
    this.chartOptions = {
      chart: {
        type: 'column',

      },
      title: {
          text: ' Domestic Market Transactions in ' + this.pickedRegion
      },
      subtitle: {
          text: this.cropName + ': ' + this.selectedYear
      },
      xAxis: {
          categories: this.stockTrend[0],
          crosshair: true
      },
      yAxis: {
          min: 0,
          title: {
              text: 'Quantity (MT)'
          }
      },
      tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y} MT</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
      },
      plotOptions: {
          column: {
              pointPadding: 0.2,
              borderWidth: 0
          }
      },
      series: this.stockTrend[1]
    };
  }

  drawStockPerRegion() {
    this.chartOptionsRegions = {
      chart: {
        type: 'column',

    },
    title: {
        text: ' Domestic Market Transactions in ' + this.pickedRegion
    },
    subtitle: {
         text: this.cropName + ': ' + this.selectedYear
    },
    xAxis: {
        categories: this.stockPerRegions[0],
        crosshair: true
    },
    yAxis: {
        min: 0,
        title: {
            text: 'Quantity (MT)'
        }
    },
    tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y} MT</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
    },
    plotOptions: {
        column: {
            pointPadding: 0.2,
            borderWidth: 0
        }
    },
    series: this.stockPerRegions[1]
  };
  }

  drawTopMarkets() {
    this.chartOptionsMarkets = {
        chart: {
          type: 'column',
      },
      title: {
          text: ' Domestic Market Transactions in ' +  this.pickedRegion
      },
      subtitle: {
          text: this.cropName + ': ' + this.selectedYear
      },
      xAxis: {
          categories: this.stockMarkets[0],
          crosshair: true
      },
      yAxis: {
          min: 0,
          title: {
              text: 'Quantity (MT)'
          }
      },
      tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y} MT</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
      },
      plotOptions: {
          column: {
              pointPadding: 0.3,
              borderWidth: 0
          },
          series: {
            borderWidth: 0,
        },
      },
      series: this.stockMarkets[1]
    };
  }

  drawPriceTrend() {
    this.chartOptionsPriceTrend = {
        chart: {
          type: 'column',
      },
      title: {
          text: ' Domestic market Transaction Price Trend in ' + this.pickedRegion
      },
      subtitle: {
          text: this.cropName + ': ' + this.selectedYear
      },
      xAxis: {
          categories: this.priceTrend[0],
          crosshair: true
      },
      yAxis: {
          min: 0,
          title: {
              text: 'Average Price (TSHs)'
          }
      },
      tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y} MT</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
      },
      plotOptions: {
          column: {
              pointPadding: 0.3,
              borderWidth: 0
          },
          series: {
            borderWidth: 0,
        },
      },
      series: this.priceTrend[1]
    };
  }

  drawPricePerRegion() {
    this.chartOptionsPriceRegions = {
        chart: {
          type: 'column',
      },
      title: {
          text: ' Domestic Market Transaction Price per ' + this.regionTitle
      },
      subtitle: {
          text: this.cropName + ': ' + this.selectedYear
      },
      xAxis: {
          categories: this.pricePerRegions[0],
          crosshair: true
      },
      yAxis: {
          min: 0,
          title: {
              text: 'Average Price (TSHs)'
          }
      },
      tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y} TSHs</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
      },
      plotOptions: {
          column: {
              pointPadding: 0.3,
              borderWidth: 0
          },
          series: {
            borderWidth: 0,
        },
      },
      series: this.pricePerRegions[1]
    };
  }

  drawPricePerMarket() {
    this.chartOptionsPriceMarkets = {
        chart: {
          type: 'column',
      },
      title: {
          text: ' Domestic Transactions Prices Per Markets'
      },
      subtitle: {
          text: this.cropName + ': ' + this.selectedYear
      },
      xAxis: {
          categories: this.pricePerMarkets[0],
          crosshair: true
      },
      yAxis: {
          min: 0,
          title: {
              text: 'Average Price (TSHs)'
          }
      },
      tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y} TSHs</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
      },
      plotOptions: {
          column: {
              pointPadding: 0.3,
              borderWidth: 0
          },
          series: {
            borderWidth: 0,
        },
      },
      series: this.pricePerMarkets[1]
    };
  }


  filter() {
    // no filter specified
    if (this.selectedMarketType == null && this.selectedRegion == null && this.selectedDistrict == null && this.selectedYear == null) {
      this.marketsCharts(this.cropId);
    }

    if (this.selectedDistrict === null) {
      if (this.selectedMarketType != null && this.selectedRegion != null && this.selectedYear != null) {
        this.defn.excludeDistrictTrading(this.selectedMarketType, this.selectedRegion, this.selectedYear, this.cropId).subscribe(
          data => {
            this.drawFilteredCharts(data);
          }
        );
      }

      if (this.selectedMarketType != null && this.selectedRegion != null && this.selectedYear == null) {
        this.defn.marketTypeRegionTrading(this.selectedMarketType, this.selectedRegion, this.cropId).subscribe(
          data => {
            this.drawFilteredCharts(data);
          }
        );
      }

      if (this.selectedMarketType != null && this.selectedRegion == null && this.selectedYear != null) {
        this.defn.marketTypeYearTrading(this.selectedMarketType, this.selectedYear, this.cropId).subscribe(
          data => {
            this.drawFilteredCharts(data);
          }
        );
      }

      if (this.selectedMarketType == null && this.selectedRegion != null && this.selectedYear != null) {
        this.defn.regionYearTrading(this.selectedRegion, this.selectedYear, this.cropId).subscribe(
          data => {
            this.drawFilteredCharts(data);
          }
        );
      }

      if (this.selectedMarketType != null && this.selectedRegion == null && this.selectedYear == null) {
        this.defn.onlyMarketTypeTrading(this.selectedMarketType, this.cropId).subscribe(
          data => {
            this.drawFilteredCharts(data);
          }
        );
      }

      if (this.selectedMarketType == null && this.selectedRegion != null && this.selectedYear == null) {
        this.defn.onlyRegionTrading(this.selectedRegion, this.cropId).subscribe(
          data => {
            this.drawFilteredCharts(data);
          }
        );
      }

      if (this.selectedMarketType == null && this.selectedRegion == null && this.selectedYear != null) {
        this.defn.onlyYearTrading(this.selectedYear, this.cropId).subscribe(
          data => {
            this.drawFilteredCharts(data);
          }
        );
      }
    }
  }


  drawFilteredCharts(data) {
    if (this.selectedRegion != null) {
      this.regionTitle = "Districts"
    } else {this.regionTitle = "Regions"}
    if (this.selectedYear != null) {
      this.yearTitle = "Monthly"
    } else {this.yearTitle = "Yearly"}

    this.stockTrend = data[0];
    this.stockPerRegions = data[1];
    this.stockMarkets = data[2];
    this.priceTrend = data[3];
    this.pricePerRegions = data[4];
    this.pricePerMarkets = data[5];
    this.drawStockTrend();
    this.drawStockPerRegion();
    this.drawTopMarkets();
    this.drawPriceTrend();
    this.drawPricePerRegion();
    this.drawPricePerMarket();
  }

}



function numberWithCommas(x) {
  const parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}
