import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ManagementService } from '../../management.service';

@Component({
  selector: 'app-role-permissions',
  templateUrl: './role-permissions.component.html',
  styleUrls: ['./role-permissions.component.css']
})
export class RolePermissionsComponent implements OnInit {

  id;
  role;
  permissions;
  my_perms = [];
  isLoading = true;
  selected = []
  constructor(
    private active: ActivatedRoute,
    private manServ: ManagementService,
    public router: Router,
    private snackBar: MatSnackBar
    // private location: Location
  ) {
    this.active.url.subscribe(
      data => {
        console.log(data)
        this.id = data[3]['path'];
      }
    );
   }

  ngOnInit(): void {
    this.getRolePermissions()
  }

  getRolePermissions() {
    this.manServ.getRolePermissionData(this.id).subscribe(
      data => {
        this.role = data[0]
        this.permissions = data[1]
        this.my_perms = data[2]

        this.isLoading = false;
        // console.log(this.selected)
        this.selected == data[2]
        data[2].forEach(e => {
          this.selected.push(e)
        });
        // console.log(this.selected)
      }

    )
  }

  assignedPerms(id) {
    if(this.my_perms.includes(id)) {
      
      return true;
    } else {
      return false
    }
  }

  goBack() {
    this.router.navigate(['managements/user-roles'])
  }


  changePermission(e) {
    var data = [];
    var value = e.target.value
    if (e.target.checked) {
      if(this.selected.includes(parseInt(value))) {
        console.log('available')
        data = this.selected.filter((item) => parseInt(item) !== parseInt(value));
        this.selected = [];
        data.forEach(e => {
          this.selected.push(e)
        });
      }
      else {
        // this.selected.push(parseInt(value))
      }
    } else {
      data = this.selected.filter((item) => parseInt(item) !== parseInt(value));
      this.selected = [];
      data.forEach(e => {
        this.selected.push(e)
      });
    //   var temp = []
    //   for(var i=0; i < this.selected.length; i++) {
    //     if(this.selected[i] == value) {
    //       console.log[this.selected[j]]
    //       for(var j=0; j < this.selected.length; j++) {
    //         if(this.selected[j] != value) {
    //           temp.push(this.selected[j])
    //         }
    //         this.selected = temp
    //       }
    //     }
    //   }
    //   this.selected.forEach((element,index)=>{
    //     if(element==value) this.selected.splice(index,1);
    //  });
  }
  // this.rolePermissionUpdate(this.selected)
    // this.selected = []
    console.log(this.selected)
  }

  rolePermissionUpdate() {
    var checked = {
      'data': this.selected
    }
    console.log(this.selected)
    // this.isLoading = true
    // this.manServ.updateRolePermissionData(this.id, checked).subscribe(
    //   (data: string) => {
    //     this.selected = [];
    //     this.getRolePermissions();
    //     this.snackBar.open('', data, {
    //       duration: 5000
    //     });

    //   }
    // )
  }

}
