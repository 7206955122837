import { Component, Inject, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ManagementService } from '../../management.service';
import { MarketService } from '../market.service';
import { DateAdapter } from '@angular/material/core';

export interface DialogData {
  id;
  action;
  region;
  district;
}

@Component({
  selector: 'app-add-market-price',
  templateUrl: './add-market-price.component.html',
  styleUrls: ['./add-market-price.component.css']
})
export class AddMarketPriceComponent implements OnInit {
  regions;
  districts;
  edit;
  markets;
  crops;
  units;

  addForm = this.fb.group({
    market_id : ['', Validators.required],
    high_price : ['', Validators.required],
    low_price : [''],
    district_id : [''],
    region_id : [''],
    crop_id : ['', Validators.required],
    price_unit : ['', Validators.required],
    date : ['', Validators.required],
  });

  editForm = this.fb.group({
    market_id : ['', Validators.required],
    high_price : ['', Validators.required],
    low_price : [''],
    district_id : [''],
    region_id : [''],
    crop_id : ['', Validators.required],
    price_unit : ['', Validators.required],
    date : ['', Validators.required],
  });
  constructor(
    public dialogRef: MatDialogRef<AddMarketPriceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private manServ: ManagementService,
    private snackBar: MatSnackBar,
    private marketServ: MarketService,
    private dateAdapter: DateAdapter<Date>,
    ) {
      this.dateAdapter.setLocale('en-GB'); // dd/MM/yyyy
    }

  ngOnInit(): void {
    this.getAllRegions();
    this.getMarkets();
    this.getCrops();
    this.getUnits();

    if (this.data.action === 'edit') {

      this.getDistricts(this.data.region);

      this.marketServ.getSingleMarketPrice(this.data.id).subscribe(
        resp => {
          this.edit = resp;
          this.editForm.get('date').setValue(this.edit.date);
          this.editForm.get('market_id').setValue(this.edit.market_id);
          this.editForm.get('low_price').setValue(this.edit.low_price);
          this.editForm.get('high_price').setValue(this.edit.high_price);
          this.editForm.get('crop_id').setValue(this.edit.crop_id);
          this.editForm.get('price_unit').setValue(Number(this.edit.price_unit));
          this.editForm.get('region_id').setValue(this.data.region);
          this.editForm.get('district_id').setValue(this.data.district);
        }
      );

    }
  }


  getAllRegions() {
    var id = localStorage.getItem('asdsUserId');
    this.manServ.getRegions(id).subscribe(
      data => {
        this.regions = data;
      }
    );
  }

  getDistricts(regionId) {
    var id = localStorage.getItem('asdsUserId');
    this.manServ.getDistricts(regionId, id).subscribe(
      data => {
        this.districts = data;
      }
    );
  }
  getMarkets() {
    this.manServ.availableAreaMarkets(localStorage.getItem('asdsUserId')).subscribe(
      data => {
        this.markets = data;
        if (data['resp'] === 'nothing') {
          this.snackBar.open('', 'No market found', {
            duration: 5000
          });
        }
      }
    );
  }
  getUnits() {
    this.manServ.getUoms().subscribe(
      data => {
        this.units = data;

      }
    );
  }
  getCrops() {
    this.manServ.getCrops().subscribe(
      data => {
        this.crops = data;
      }
    );
  }


  getErrorMessage() {
    return 'This field is required';
  }

  onNoClick() {
    this.dialogRef.close();
  }

  onAddPrice() {
    if (this.addForm.valid) {
      const formData = {
        ...this.addForm.value,
        // user_id: localStorage.getItem('asdsUserId')
      };
      // console.log(formData)
      this.marketServ.addMarketPrice(formData).subscribe(
        (data: string) => {
            this.snackBar.open('success', data['resp'], {
              duration: 5000
            });
            this.onNoClick();
        }
      );
    }
  }

  onUpdate() {
    if (this.editForm.valid) {
      const formData = {
        ...this.editForm.value,
        // user_id: localStorage.getItem('asdsUserId')
      };
      // console.log(formData)
      this.marketServ.updateMarketPrice(formData, this.data.id).subscribe(
        (data: string) => {
            this.snackBar.open('success', data['resp'], {
              duration: 5000
            });
            this.onNoClick();
        }
      );
    }
  }

}
