import { AfterViewInit, Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts/highstock';
import HC_exporting from 'highcharts/modules/export-data';
import { TradersService } from '../traders.service';
import { ManagementService } from '../../management.service';
HC_exporting(Highcharts);

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css']
})
export class SummaryComponent implements OnInit, AfterViewInit {
  totalTraders = 0
  totalBuyers = 0
  loading = false;
  region_id = null;

  regions;

  Highcharts = Highcharts;
  chartOptions: {};
  applicationsChartOptions: {};
  // chartOptionsUtilization: {};
  // chartOptionsGrade: {};
  chartConstructor = 'chart';
  constructor(
    public traderServ: TradersService,
    private manServ: ManagementService,
  ) { }

  ngAfterViewInit(): void {
    this.getData();
  }

  ngOnInit(): void {
    this.getAllRegions()
  }

  getAllRegions() {
    this.manServ.getAllRegions().subscribe(
      data => {
        this.regions = data;
      }
    );
  }

  getData() {
    this.loading = !this.loading;
    var data = {
      'region_id': this.region_id
    }
    this.traderServ.getData(data).subscribe(
      (data: any) => {
        // console.log(data);
        this.totalTraders = data.stats.totalTraders;
        this.totalBuyers = data.stats.totalBuyers;


        this.applicationStatsChart(data.appStatus);
        // this.genderChart();
        this.loading = !this.loading;
      }
    );
  }


  applicationStatsChart(data) {
    this.applicationsChartOptions = {
      chart: {
        type: 'pie'
      },
      title: {
        text: 'Buyer Applications'
      },
      tooltip: {
        valueSuffix: ''
      },
      subtitle: {
        text:
          'By Status'
      },
      plotOptions: {
        // series: {
        //   allowPointSelect: true,
        //   cursor: 'pointer',
        //   dataLabels: [{
        //     enabled: true,
        //     distance: 20
        //   }, {
        //     enabled: true,
        //     distance: -40,
        //     format: '{point.percentage:.1f}',
        //     style: {
        //       fontSize: '1.2em',
        //       textOutline: 'none',
        //       opacity: 0.7
        //     },
        //     filter: {
        //       operator: '>',
        //       property: 'percentage',
        //       value: 10
        //     },
        //   }]
        // },
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
            format: '{point.name}: {point.percentage:.1f} %',
            distance: 10,
            style: {
              fontSize: '1.2em',
              textOutline: 'none',
              opacity: 0.7
            }
          },
          showInLegend: true
        }
      }, 
      legend: {
        enabled: true,
        align: 'right',
        verticalAlign: 'bottom',
        layout: 'vertical'
      },
      series: [data]
    };

  }

  setRegion(id) {
    this.region_id = id;
    this.getData();
  }
}
