import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ManagementService } from './../../management.service';

export interface DialogData {
  id;
  action;
  region;
  district;
  ward;
  village;
}

@Component({
  selector: 'app-receivein-warehouse',
  templateUrl: './receivein-warehouse.component.html',
  styleUrls: ['./receivein-warehouse.component.css']
})
export class ReceiveinWarehouseComponent implements OnInit {
  regions;
  districts;
  wards;
  villages;
  edit;
  crops;
  warehouses;
  sellers;
  units;

  originWarehouses;
  originMarkets;

  fromWarehouse = false;
  fromMarket = false;
  warehouseForm = this.fb.group({
    date : ['', Validators.required],
    trader_id : ['', Validators.required],
    quality : ['', ],
    buying_price : ['', ],
    quantity : ['', Validators.required],
    crop_id : ['', Validators.required],
    village_id : ['', ],
    ward_id : ['', ],
    district_id : ['', Validators.required],
    region_id : ['', Validators.required],
    warehouse_id : ['', Validators.required],
    origin_warehouse : ['', Validators.required],
    origin_market : ['', Validators.required],
    source : ['', Validators.required],
    cess_payment : [''],
    cess_unit : [''],
    price_unit : [''],
  });

  editForm = this.fb.group({
    date : ['', Validators.required],
    trader_id : ['', Validators.required],
    quality : ['', ],
    buying_price : ['', ],
    quantity : ['', Validators.required],
    crop_id : ['', Validators.required],
    village_id : ['', ],
    ward_id : ['', ],
    district_id : ['', Validators.required],
    region_id : ['', Validators.required],
    warehouse_id : ['', Validators.required],
    origin_warehouse : [''],
    origin_market : [''],
    source : ['', Validators.required],
    cess_payment : [''],
    cess_unit : [''],
    price_unit : [''],
  });

  constructor(
    public dialogRef: MatDialogRef<ReceiveinWarehouseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private manServ: ManagementService,
    private snackBar: MatSnackBar,
    private dateAdapter: DateAdapter<Date>,
    ) {
      this.dateAdapter.setLocale('en-GB'); // dd/MM/yyyy
    }

  ngOnInit(): void {
    this.getAllRegions();
    this.getCrops();
    this.getSellers();
    this.getWarehouses();
    this.getUnits();

    this.warehouseForm.get('date').setValue(new Date());
    if (this.data.action === 'edit') {

      this.getDistricts(this.data.region);
      this.getWards(this.data.district);
      this.getVillages(this.data.ward);
      this.getAreaData(this.data.district);

      this.manServ.getReceivedSingleData(this.data.id).subscribe(
        resp => {
          this.edit = resp;

          this.editForm.get('date').setValue(this.edit.date);
          this.editForm.get('trader_id').setValue(this.edit.trader_id);
          this.editForm.get('quality').setValue(this.edit.quality);
          this.editForm.get('buying_price').setValue(this.edit.buying_price);
          this.editForm.get('quantity').setValue(this.edit.quantity);
          this.editForm.get('crop_id').setValue(this.edit.crop_id);
          this.editForm.get('village_id').setValue(this.edit.village_id);
          this.editForm.get('ward_id').setValue(this.data.ward);
          this.editForm.get('district_id').setValue(this.data.district);
          this.editForm.get('region_id').setValue(this.data.region);
          this.editForm.get('warehouse_id').setValue(this.edit.warehouse_id);
          this.editForm.get('cess_payment').setValue(this.edit.cess_payment);
          this.editForm.get('source').setValue(this.edit.source);

          if (this.edit.source === 'Warehouse') {
            this.editForm.get('origin_warehouse').setValue(this.edit.origin_warehouse);
            this.fromMarket = false;
            this.fromWarehouse = true;
            this.editForm.get('origin_market').clearValidators();
            this.editForm.get('origin_market').setValue('');
          } else if (this.edit.source === 'Market') {
            this.editForm.get('origin_market').setValue(this.edit.origin_market);
            this.fromWarehouse = false;
            this.fromMarket = true;
            this.editForm.get('origin_warehouse').clearValidators();
            this.editForm.get('origin_warehouse').setValue('');
          }
        }
      );
    }
  }
  getAllRegions() {
    var id = localStorage.getItem('asdsUserId');
    this.manServ.getRegions(id).subscribe(
      data => {
        this.regions = data;
      }
    );
  }

  getDistricts(regionId) {
    var id = localStorage.getItem('asdsUserId');
    this.manServ.getDistricts(regionId, id).subscribe(
      data => {
        this.districts = data;
      }
    );
  }

  getWards(districtId) {
    this.getAreaData(districtId);
    this.manServ.getWards(districtId).subscribe(
      data => {
        this.wards = data;
      }
    );
  }

  getVillages(wardId) {
    this.manServ.getVillages(wardId).subscribe(
      data => {
        this.villages = data;
      }
    );
  }

  getAreaData(districtId) {
    this.manServ.availableAreaWarehouses(districtId).subscribe(
      data => {
        this.originWarehouses = data;
        // if (data === 'nothing') {
        //   this.snackBar.open('', 'No warehouse found', {
        //     duration: 5000
        //   });
        // }
      }
    );
    this.manServ.allAvailableAreaMarkets(districtId).subscribe(
      data => {
        this.originMarkets = data;
        // if (data === 'nothing') {
        //   this.snackBar.open('', 'No warehouse found', {
        //     duration: 5000
        //   });
        // }
      }
    );
  }

  getWarehouses() {
    this.manServ.myWarehouses(localStorage.getItem('asdsUserId')).subscribe(
      data => {
        this.warehouses = data;
      }
    );
  }

  chooseSource(value) {
    if (value === 'Warehouse') {
      this.fromMarket = false;
      this.fromWarehouse = true;

      this.warehouseForm.get('origin_warehouse').setValidators(Validators.required);
      this.warehouseForm.get('origin_market').clearValidators();
      this.warehouseForm.get('origin_market').setValue('');
      this.editForm.get('origin_warehouse').setValidators(Validators.required);
      this.editForm.get('origin_market').clearValidators();
      this.editForm.get('origin_market').setValue('');
    } else if (value === 'Market') {
      this.fromWarehouse = false;
      this.fromMarket = true;

      this.warehouseForm.get('origin_market').setValidators(Validators.required);
      this.warehouseForm.get('origin_warehouse').clearValidators();
      this.warehouseForm.get('origin_warehouse').setValue('');
      this.editForm.get('origin_market').setValidators(Validators.required);
      this.editForm.get('origin_warehouse').clearValidators();
      this.editForm.get('origin_warehouse').setValue('');
    } else {
      this.fromWarehouse = false;
      this.fromMarket = false;

      this.warehouseForm.get('origin_warehouse').clearValidators();
      this.warehouseForm.get('origin_market').clearValidators();
      this.warehouseForm.get('origin_market').setValue('');
      this.warehouseForm.get('origin_warehouse').setValue('');
      this.editForm.get('origin_warehouse').clearValidators();
      this.editForm.get('origin_market').clearValidators();
      this.editForm.get('origin_market').setValue('');
      this.editForm.get('origin_warehouse').setValue('');
    }
  }
  getSellers() {
    this.manServ.getCategoryTraders('seller').subscribe(
      data => {
        this.sellers = data;
      }
    );
  }


  getUnits() {
    this.manServ.getUoms().subscribe(
      data => {
        this.units = data;

      }
    );
  }


  getCrops() {
    this.manServ.getCrops().subscribe(
      data => {
        this.crops = data;
      }
    );
  }
  getErrorMessage() {
    return 'This field is required';
  }

  onNoClick() {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.warehouseForm.valid) {
      const formData = {
        ...this.warehouseForm.value,
        'user_id': localStorage.getItem('asdsUserId')
      };
      this.manServ.addReceivedInWarehouse(formData).subscribe(
        (data: string) => {
          // tslint:disable-next-line: no-string-literal
          if (data['resp'] === 'failed') {
            this.snackBar.open('failed', 'The quantity exceeds the warehouse capacity', {
              duration: 5000
            });
          }
          else {
            // tslint:disable-next-line: no-string-literal
            this.snackBar.open('success', data['resp'], {
              duration: 5000
            });
            this.onNoClick();
          }
        }
      );
    }
  }

  onUpdate() {
    if (this.editForm.valid) {
      this.manServ.updateReceivedInWarehouse(this.editForm.value, this.data.id).subscribe(
        (data: string) => {
          // tslint:disable-next-line: no-string-literal
          if (data['resp'] === 'failed') {
            this.snackBar.open('failed', 'The quantity exceeds the warehouse capacity', {
              duration: 5000
            });
          }
          else {
            // tslint:disable-next-line: no-string-literal
            this.snackBar.open('success', data['resp'], {
              duration: 5000
            });
            this.onNoClick();
          }
        }
      );
    } else {
      console.log('invalid');
    }
  }
}

