import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeleteDataComponent } from '../delete-data/delete-data.component';
import { WarehousesService } from '../warehouses/warehouses.service';

export interface DialogData {
  id;
  type;
}
@Component({
  selector: 'app-approve-data',
  templateUrl: './approve-data.component.html',
  styleUrls: ['./approve-data.component.css']
})
export class ApproveDataComponent implements OnInit {

  isLoading = false;

  constructor(
    public dialogRef: MatDialogRef<DeleteDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private ware: WarehousesService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    this.isLoading = true;
    if (this.data.type === 'warehouse'){
      this.ware.approveNewInWarehouses(this.data.id).subscribe(
        (resp: string) => {
          this.snackBar.open('', resp, {
            duration: 5000
          });
          this.onNoClick();
        }
      );
    }
  }
  }
