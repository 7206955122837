import { SharedModule } from './modules/shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DataTablesModule } from 'angular-datatables';
import { IndexComponent } from './modules/definition/index/index.component';
import { WarehouseComponent } from './modules/management/warehouses/warehouse/warehouse.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AddWarehouseComponent } from './modules/management/warehouses/add-warehouse/add-warehouse.component';
import { InwarehouseComponent } from './modules/management/warehouses/inwarehouse/inwarehouse.component';
import { DeleteDataComponent } from './modules/management/delete-data/delete-data.component';
import { ReceiveinWarehouseComponent } from './modules/management/warehouses/receivein-warehouse/receivein-warehouse.component';
import { CropsComponent } from './modules/management/crops/crops/crops.component';
import { AddCropComponent } from './modules/management/crops/add-crop/add-crop.component';
import { MarketComponent } from './modules/management/markets/market/market.component';
import { AddMarketComponent } from './modules/management/markets/add-market/add-market.component';
import { InmarketComponent } from './modules/management/markets/inmarket/inmarket.component';
import { ReceiveinMarketComponent } from './modules/management/markets/receivein-market/receivein-market.component';
import { WithdrawGoodComponent } from './modules/management/withdraw/withdraw-good/withdraw-good.component';
import { AddWithdrawComponent } from './modules/management/withdraw/add-withdraw/add-withdraw.component';
import { WarehouseDashbComponent } from './modules/definition/warehouse-dashb/warehouse-dashb.component';
import { UsersComponent } from './modules/management/users/users/users.component';
import { AddUserComponent } from './modules/management/users/add-user/add-user.component';
import { MarketDashbComponent } from './modules/definition/market-dashb/market-dashb.component';
import { MovementDashbComponent } from './modules/definition/movement-dashb/movement-dashb.component';
import { LoginComponent } from './auth/login/login.component';
import { RolesComponent } from './modules/management/users/roles/roles.component';
import { AddRoleComponent } from './modules/management/users/add-role/add-role.component';
import { TradersComponent } from './modules/management/traders/traders/traders.component';
import { AddTraderComponent } from './modules/management/traders/add-trader/add-trader.component';
// import { ReleaseFromWarehouseComponent } from './modules/management/warehouses/release-from-warehouse/release-from-warehouse.component';
import { ReleasedFromWarehouseComponent } from './modules/management/warehouses/released-from-warehouse/released-from-warehouse.component';
import { ReleaseFromMarketComponent } from './modules/management/markets/release-from-market/release-from-market.component';
import { ReleasedFromMarketComponent } from './modules/management/markets/released-from-market/released-from-market.component';
import { MoreDetailsComponent } from './modules/management/markets/more-details/more-details.component';
import { WarehouseDetailsComponent } from './modules/management/warehouses/warehouse-details/warehouse-details.component';
import { RolePermissionsComponent } from './modules/management/users/role-permissions/role-permissions.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { UomComponent } from './modules/management/uom/uom/uom.component';
import { AddUomComponent } from './modules/management/uom/add-uom/add-uom.component';
import { ApproveDataComponent } from './modules/management/approve-data/approve-data.component';
import { RegisterComponent } from './modules/management/cross-points/register/register.component';
import { RecordComponent } from './modules/management/cross-points/record/record.component';
import { AddPointComponent } from './modules/management/cross-points/add-point/add-point.component';
import { AddPointDataComponent } from './modules/management/cross-points/add-point-data/add-point-data.component';
import { ViewBalanceComponent } from './modules/management/warehouses/view-balance/view-balance.component';
import { QRCodeModule } from 'angularx-qrcode';
import { MarketPriceComponent } from './modules/management/markets/market-price/market-price.component';
import { AddMarketPriceComponent } from './modules/management/markets/add-market-price/add-market-price.component';
import { BuyerRegisterComponent } from './auth/buyer-register/buyer-register.component';
import { ApplicationComponent } from './modules/buyers/application/application.component';
import { GuestComponent } from './guest/guest.component';
import { BuyerProfileComponent } from './modules/buyers/buyer-profile/buyer-profile.component';
import { CommonModule } from '@angular/common';
import { ApplyPermitComponent } from './modules/buyers/apply-permit/apply-permit.component';
import { ApplicationInfoComponent } from './modules/buyers/application-info/application-info.component';
import { PendingPermitComponent } from './modules/buyers/management/pending-permit/pending-permit.component';
import { VerifiedPermitComponent } from './modules/buyers/management/verified-permit/verified-permit.component';
import { PermitActionComponent } from './modules/buyers/management/permit-action/permit-action.component';
import { MyPermitComponent } from './modules/buyers/my-permit/my-permit.component';
import { UpdatePermitComponent } from './modules/buyers/update-permit/update-permit.component';
import { PrintPermitComponent } from './modules/buyers/print-permit/print-permit.component';
import { ReturnedComponent } from './modules/buyers/management/returned/returned.component';
import { RejectedComponent } from './modules/buyers/management/rejected/rejected.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { Select2Module } from 'ng-select2-component';
import { QrInfoComponent } from './modules/buyers/qr-info/qr-info.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RegistrationComponent } from './modules/buyers/report/registration/registration.component';
import { BuyerCropsComponent } from './modules/buyers/report/buyer-crops/buyer-crops.component';
import { SummaryComponent } from './modules/management/traders/summary/summary.component';
import { WarehouseSummaryComponent } from './modules/management/warehouses/warehouse-summary/warehouse-summary.component';
import { MarketSummaryComponent } from './modules/management/markets/market-summary/market-summary.component';
import { AcListComponent } from './modules/management/aggregation-centers/registration/ac-list/ac-list.component';
import { AcFormComponent } from './modules/management/aggregation-centers/registration/ac-form/ac-form.component';
import { ProducesListComponent } from './modules/management/aggregation-centers/produces/produces-list/produces-list.component';
import { ProducesFormComponent } from './modules/management/aggregation-centers/produces/produces-form/produces-form.component';
import { FarmerModalComponent } from './modules/management/aggregation-centers/farmer/farmer-modal/farmer-modal.component';
import { OrderListComponent } from './modules/management/aggregation-centers/orders/order-list/order-list.component';
import { OrderFormComponent } from './modules/management/aggregation-centers/orders/order-form/order-form.component';
import { OrderViewComponent } from './modules/management/aggregation-centers/orders/order-view/order-view.component';
import { SummariesComponent } from './modules/management/aggregation-centers/reports/summaries/summaries.component';
import { AggregatedListComponent } from './modules/management/aggregation-centers/produces/aggregated-list/aggregated-list.component';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    WarehouseComponent,
    AddWarehouseComponent,
    InwarehouseComponent,
    DeleteDataComponent,
    ReceiveinWarehouseComponent,
    CropsComponent,
    AddCropComponent,
    MarketComponent,
    AddMarketComponent,
    InmarketComponent,
    ReceiveinMarketComponent,
    WithdrawGoodComponent,
    AddWithdrawComponent,
    WarehouseDashbComponent,
    UsersComponent,
    AddUserComponent,
    MarketDashbComponent,
    MovementDashbComponent,
    LoginComponent,
    RolesComponent,
    AddRoleComponent,
    TradersComponent,
    AddTraderComponent,
    // ReleaseFromWarehouseComponent,
    ReleasedFromWarehouseComponent,
    ReleaseFromMarketComponent,
    ReleasedFromMarketComponent,
    MoreDetailsComponent,
    WarehouseDetailsComponent,
    RolePermissionsComponent,
    UomComponent,
    AddUomComponent,
    ApproveDataComponent,
    RegisterComponent,
    RecordComponent,
    AddPointComponent,
    AddPointDataComponent,
    ViewBalanceComponent,
    MarketPriceComponent,
    AddMarketPriceComponent,
    BuyerRegisterComponent,
    ApplicationComponent,
    GuestComponent,
    BuyerProfileComponent,
    ApplyPermitComponent,
    ApplicationInfoComponent,
    PendingPermitComponent,
    VerifiedPermitComponent,
    PermitActionComponent,
    MyPermitComponent,
    UpdatePermitComponent,
    PrintPermitComponent,
    ReturnedComponent,
    RejectedComponent,
    ForgotPasswordComponent,
    QrInfoComponent,
    RegistrationComponent,
    BuyerCropsComponent,
    SummaryComponent,
    WarehouseSummaryComponent,
    MarketSummaryComponent,
    AcListComponent,
    AcFormComponent,
    ProducesListComponent,
    ProducesFormComponent,
    FarmerModalComponent,
    OrderListComponent,
    OrderFormComponent,
    OrderViewComponent,
    SummariesComponent,
    AggregatedListComponent,
  ],
  imports: [
    CommonModule,
    HighchartsChartModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DataTablesModule,
    SharedModule,
    HttpClientModule,
    QRCodeModule,
    Select2Module,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
