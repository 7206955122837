<div>
  <div class="w-100">
      <div [ngClass]="!isLoading ? 'show' : 'hide'" class="view card">
          <div class="table-responsive">
              <div class="card-header d-flex justify-content-start">
                  <button class="button" mat-button (click)="onAddData()"><span class="material-icons notranslate">add_circle_outline</span>Record Data</button>
              </div>
              <div class="card-body">
                  <!--  -->
                  <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" id="dtbs" class="table p-3">
                      <thead>
                          <tr>
                              <th>#</th>
                              <th>Date</th>
                              <th>Point Name</th>
                              <th>Source Region</th>
                              <th>Source District</th>
                              <th>Destination Region</th>
                              <th>Destination District</th>
                              <th>Action</th>
                          </tr>

                      </thead>
                      <tbody>
                          <tr *ngFor="let point of points;let i = index">
                              <td>{{i+1}}</td>
                              <td>{{point.date}}</td>
                              <td>{{point.point?.name}}</td>
                              <td>{{point.region?.name}}</td>
                              <td>{{point.district?.name}}</td>
                              <td>{{point.dest_region?.name}}</td>
                              <td>{{point.dest_district?.name}}</td>
                              <td style="display: flex; flex-direction: row;">
                                  <a class="text-success act" (click)="editData(point.id, point.region_id, point.district_id, point.ward_id, point.dest_region_id, point.dest_district_id)"><i class="fa fa-edit"></i></a>
                                  <mat-icon *ngIf="authService.isAdmin" class="text-danger act" (click)="deleteData(point.id)">delete_forever</mat-icon>
                                  <!-- <mat-icon class="text-danger act" (click)="deleteData(point.id)">delete_forever</mat-icon> -->
                              </td>
                          </tr>
                      </tbody>
                  </table>
              </div>
          </div>
      </div>


      <div *ngIf="isLoading" class="spinner-holder">
          <mat-spinner class="spinner"></mat-spinner>
      </div>
  </div>
  <!-- <div [ngClass]="isChecked ? 'hidden': ''">
      <app-inmarket></app-inmarket>
  </div> -->
</div>
