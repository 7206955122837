<form [formGroup]="permitActionForm" (ngSubmit)="onSubmit()">
    <div class="title-holder">
        <h2 class="title">Verify Certificate</h2>
    </div>
    <mat-form-field class="w-100 px-1" appearance="outline">
        <mat-label>Status</mat-label>
        <mat-select placeholder="Select Status" formControlName="status" required>
            <mat-option value="approved">Approve</mat-option>
            <mat-option value="returned">Return</mat-option>
            <mat-option value="rejected">Reject</mat-option>
        </mat-select>
        <mat-error *ngIf="permitActionForm.get('status').invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>

    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Comment</mat-label>
      <textarea matInput formControlName="comment"></textarea>
      <mat-error *ngIf="permitActionForm.get('comment').invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>

    <div style="margin-top: 30px; display: flex; flex-direction: row; justify-content: flex-end;" class="space-x-2" *ngIf="authService.isAllowed('verify-permit')">
        <a (click)="onNoClick()" class="bg-red-500 text-gray-100 hover:text-white cursor-pointer hover:no-underline text-center rounded-lg px-4 py-2">Cancel</a>

        <button type="submit" class="createbtn" mat-button> <i *ngIf="isSaving" class="fa fa-spinner fa-spin"></i> Submit</button>
    </div>
</form>