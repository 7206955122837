import { Component, OnInit } from '@angular/core';
import { BuyerService } from '../buyer.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-buyer-profile',
  templateUrl: './buyer-profile.component.html',
  styleUrls: ['./buyer-profile.component.css']
})
export class BuyerProfileComponent implements OnInit {

  profile;
  isLoading = false;
  showAll = false;
  attachmentUrl = ''
  constructor(
    public buyerService: BuyerService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.getMyProfile();
    this.attachmentUrl = environment.imageUrl
  }

  getMyProfile() {
    this.buyerService.getBuyerProfile().subscribe(
      data => {
        this.profile = data;
        this.isLoading = false

        // console.log(this.profile)
      }
    );
    this.isLoading = false
  }

  showAllInfo() {
    this.showAll = !this.showAll
  }

}
