<!-- add price -->

<form *ngIf="data.action == 'add'" [formGroup]="addForm" (ngSubmit)="onAddPrice()">
    <div class="title-holder">
        <h2 class="title">Add Price</h2>
    </div>
    <div class="col-md-12 column">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Market Name</mat-label>
            <mat-select placeholder="Select Market" formControlName="market_id" required>
                <mat-option *ngFor="let market of markets" [value]="market.id">{{market.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="addForm.get('market_id').invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>
    </div>
    <div class="row">
        <div class="col-md-6 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>High Price</mat-label>
                <input matInput type="number" placeholder="High Price" formControlName="high_price" >
                <mat-error *ngIf="addForm.get('high_price').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Low price</mat-label>
                <input matInput type="number" placeholder="Low price" formControlName="low_price" >
                <mat-error *ngIf="addForm.get('low_price').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="col-md-12 column">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Crop</mat-label>
            <mat-select placeholder="Select Crop" formControlName="crop_id" required>
                <mat-option *ngFor="let crop of crops" [value]="crop.id">{{crop.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="addForm.get('crop_id').invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>
    </div>
    <div class="col-md-12 column">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Price Unit</mat-label>
            <mat-select placeholder="Select Unit" formControlName="price_unit">
                <mat-option *ngFor="let unit of units" [value]="unit.id">{{unit.uom}}</mat-option>
            </mat-select>
            <mat-error *ngIf="addForm.get('price_unit').invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>
    </div>
    <div class="row column">
        <div class="col-md-6 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Regions</mat-label>
                <mat-select placeholder="Select Region" formControlName="region_id" (selectionChange)="getDistricts($event.value)">
                    <mat-option *ngFor="let region of regions" [value]="region.id">{{region.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="addForm.get('region_id').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Districts</mat-label>
                <mat-select placeholder="Select District" formControlName="district_id">
                    <mat-option *ngFor="let district of districts" [value]="district.id">{{district.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="addForm.get('district_id').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="col-md-12 column">
        <mat-form-field class="w-100" appearance="fill">
            <mat-label>Date (13/1/2001)</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="date" required>
            <mat-datepicker-toggle matSuffix [for]="picker">
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="addForm.get('date').invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>
    </div>

    <div style="margin-top: 30px; display: flex; flex-direction: row; justify-content: flex-end;">
        <button (click)="onNoClick()" class="cancelbtn" mat-button>Cancel</button>

        <button type="submit" class="createbtn" mat-button>Add</button>
    </div>
</form>

<!-- Update price -->

<form *ngIf="data.action == 'edit'" [formGroup]="editForm" (ngSubmit)="onUpdate()">
    <div class="title-holder">
        <h2 class="title">Update Details</h2>
    </div>
    <div class="col-md-12 column">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Market Name</mat-label>
            <mat-select placeholder="Select Market" formControlName="market_id" required>
                <mat-option *ngFor="let market of markets" [value]="market.id">{{market.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="editForm.get('market_id').invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>
    </div>
    <div class="row">
        <div class="col-md-6 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>High Price</mat-label>
                <input matInput type="number" placeholder="High Price" formControlName="high_price" >
                <mat-error *ngIf="editForm.get('high_price').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Low price</mat-label>
                <input matInput type="number" placeholder="Low price" formControlName="low_price" >
                <mat-error *ngIf="editForm.get('low_price').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="col-md-12 column">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Crop</mat-label>
            <mat-select placeholder="Select Crop" formControlName="crop_id" required>
                <mat-option *ngFor="let crop of crops" [value]="crop.id">{{crop.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="editForm.get('crop_id').invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>
    </div>
    <div class="col-md-12 column">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Price Unit</mat-label>
            <mat-select placeholder="Select Unit" formControlName="price_unit">
                <mat-option *ngFor="let unit of units" [value]="unit.id">{{unit.uom}}</mat-option>
            </mat-select>
            <mat-error *ngIf="editForm.get('price_unit').invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>
    </div>
    <div class="row column">
        <div class="col-md-6 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Regions</mat-label>
                <mat-select placeholder="Select Region" formControlName="region_id" (selectionChange)="getDistricts($event.value)">
                    <mat-option *ngFor="let region of regions" [value]="region.id">{{region.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="editForm.get('region_id').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Districts</mat-label>
                <mat-select placeholder="Select District" formControlName="district_id">
                    <mat-option *ngFor="let district of districts" [value]="district.id">{{district.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="editForm.get('district_id').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="col-md-12 column">
        <mat-form-field class="w-100" appearance="fill">
            <mat-label>Date (13/1/2001)</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="date" required>
            <mat-datepicker-toggle matSuffix [for]="picker">
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="editForm.get('date').invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>
    </div>

    <div style="margin-top: 30px; display: flex; flex-direction: row; justify-content: flex-end;">
        <button (click)="onNoClick()" class="cancelbtn" mat-button>Cancel</button>

        <button type="submit" class="createbtn" mat-button>Update</button>
    </div>
</form>

