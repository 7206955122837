import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ManagementService } from './../../management.service';
import { AddUserComponent } from './../add-user/add-user.component';
import { DeleteDataComponent } from '../../delete-data/delete-data.component';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit, AfterViewInit {
  users;
  isLoading = true;
  imageUrl = environment.imageUrl;

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  dtCheck = false;
  constructor(
    private dialog: MatDialog,
    private manServ: ManagementService,
    public authService: AuthService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.getUsers();
    this.dtTrigger.next();

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 100,
      processing: true,
      responsive: true
    };
  }

  getUsers() {
    var userId = localStorage.getItem('asdsUserId');
    this.manServ.getUsers(userId).subscribe(
      data => {
        this.users = data;
        this.isLoading = false;
        this.rerender();
      }
    );
  }

  rerender(): void {
    if (this.dtCheck){
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
      });
    }
    else {
      this.dtCheck = true;
      this.dtTrigger.next();
    }

  }

  onAddUser() {
    const dialogRef = this.dialog.open(AddUserComponent, {
      width: '650px',
      height: '710px',
      data: {action: 'add' }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('closed');
      this.getUsers();
    });
  }



  editUser(id) {
    const dialogRef = this.dialog.open(AddUserComponent, {
      width: '650px',
      height: '710px',
      // tslint:disable-next-line: object-literal-shorthand
      data: {id: id, action: 'edit' }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('closed');
      this.getUsers();
    });
  }

  editPassword(id) {
    const dialogRef = this.dialog.open(AddUserComponent, {
      width: '500px',
      height: '270px',
      // tslint:disable-next-line: object-literal-shorthand
      data: {id: id, action: 'edit_password' }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('closed');
      this.getUsers();
    });
  }

  deleteUser(id) {
    const dialogRef = this.dialog.open(DeleteDataComponent, {
      width: '500px',
      height: '300px',
      // tslint:disable-next-line: object-literal-shorthand
      data: {id: id, type: 'user' }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getUsers();
    });
  }
}
