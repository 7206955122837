import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ManagementService } from '../../management.service';

export interface DialogData {
  action;
  category;
  id;
  region;
  district;
  ward;
}

@Component({
  selector: 'app-add-trader',
  templateUrl: './add-trader.component.html',
  styleUrls: ['./add-trader.component.css']
})
export class AddTraderComponent implements OnInit {
  regions;
  districts;
  wards;
  villages;
  crops;
  edit;
  countries;
  isRequired = false;
  tinmask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-',  /\d/, /\d/, /\d/];
  editCrops = [];
  selectedImage;
  imageSrc: string;

  addForm = this.fb.group({
    name : ['', Validators.required],
    phone : ['', Validators.pattern(/^(255)[0-9]{9}$/)],
    category : ['', Validators.required],
    email : ['', Validators.pattern(/.+@.+\..+/)],
    address : [''],
    company_name : [''],
    tin_number : [''],
    date : ['', Validators.required],
    // crops : ['', Validators.required],
    village_id : [''],
    ward_id : [''],
    district_id : [''],
    region_id : [''],
    country_id : [''],
    image: [''],
  });

  editForm = this.fb.group({
    name : ['', Validators.required],
    phone : ['', Validators.pattern(/^(255)[0-9]{9}$/)],
    category : ['', Validators.required],
    email : ['', Validators.pattern(/.+@.+\..+/)],
    address : [''],
    company_name : [''],
    tin_number : [''],
    date : ['', Validators.required],
    // crops : ['', Validators.required],
    village_id : [''],
    ward_id : [''],
    district_id : [''],
    region_id : [''],
    country_id : [''],
    image: FormControl[''],
  });

  constructor(
    public dialogRef: MatDialogRef<AddTraderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private manServ: ManagementService,
    private snackBar: MatSnackBar,
    private dateAdapter: DateAdapter<Date>,
  ) {
    this.dateAdapter.setLocale('en-GB'); // dd/MM/yyyy
  }

  ngOnInit(): void {
    this.getCountries();
    this.getAllRegions();
    this.getCrops();
    this.addForm.get('date').setValue(new Date());
    if (this.data.action === 'edit') {

      this.getDistricts(this.data.region);
      this.getWards(this.data.district);
      this.getVillages(this.data.ward);

      this.manServ.getTraderData(this.data.id).subscribe(
        resp => {
          this.edit = resp;
          this.editForm.get('name').setValue(this.edit.user?.full_name);
          this.editForm.get('address').setValue(this.edit.address);
          this.editForm.get('phone').setValue(this.edit.user?.phone);
          this.editForm.get('tin_number').setValue(this.edit.tin_number);
          this.editForm.get('date').setValue(this.edit.date);
          this.editForm.get('category').setValue(this.edit.trader_category);
          this.editForm.get('email').setValue(this.edit.user?.email);
          this.editForm.get('company_name').setValue(this.edit.company_name);
          this.editForm.get('region_id').setValue(this.data.region);
          this.editForm.get('district_id').setValue(this.data.district);
          this.editForm.get('ward_id').setValue(this.data.ward);
          this.editForm.get('village_id').setValue(this.edit.user?.village?.id);
          this.editForm.get('country_id').setValue(this.edit.country_id);

          // tslint:disable-next-line: prefer-for-of
          for (let i = 0; i < this.edit.crops.length; i++) {
            this.editCrops.push(this.edit.crops[i].id);
          }
          // this.editForm.get('crops').setValue(this.editCrops);

          if (this.edit.country_id === 223) {
            this.isRequired = true;
          }
        }
      );

    }
  }

  changeValidation(countryId) {
    if (countryId === 223) {
      this.isRequired = true;
      if (this.data.action === 'add') {
        this.addForm.get('region_id').setValidators(Validators.required);
        this.addForm.get('district_id').setValidators(Validators.required);
        // this.addForm.get('ward_id').setValidators(Validators.required);
        // this.addForm.get('village_id').setValidators(Validators.required);
      }else {
        this.editForm.get('region_id').setValidators(Validators.required);
        this.editForm.get('district_id').setValidators(Validators.required);
        // this.editForm.get('ward_id').setValidators(Validators.required);
        // this.editForm.get('village_id').setValidators(Validators.required);
      }

    }
    else {
      this.isRequired = false;

      if (this.data.action === 'add') {
        this.addForm.get('region_id').clearValidators();
        this.addForm.get('district_id').clearValidators();
        this.addForm.get('ward_id').clearValidators();
        this.addForm.get('village_id').clearValidators();

        this.addForm.get('region_id').setValue('');
        this.addForm.get('district_id').setValue('');
        this.addForm.get('ward_id').setValue('');
        this.addForm.get('village_id').setValue('');
      }else {
        this.editForm.get('region_id').clearValidators();
        this.editForm.get('district_id').clearValidators();
        this.editForm.get('ward_id').clearValidators();
        this.editForm.get('village_id').clearValidators();

        this.editForm.get('region_id').setValue('');
        this.editForm.get('district_id').setValue('');
        this.editForm.get('ward_id').setValue('');
        this.editForm.get('village_id').setValue('');
      }
    }
  }

  getCountries() {
    this.manServ.getCountries().subscribe(
      data => {
        this.countries = data;
      }
    );
  }
  getAllRegions() {
    var id = localStorage.getItem('asdsUserId');
    this.manServ.getRegions(id).subscribe(
      data => {
        this.regions = data;
      }
    );
  }

  getDistricts(regionId) {
    var id = localStorage.getItem('asdsUserId');
    this.manServ.getDistricts(regionId, id).subscribe(
      data => {
        this.districts = data;
      }
    );
  }

  getWards(districtId) {
    this.manServ.getWards(districtId).subscribe(
      data => {
        this.wards = data;
      }
    );
  }

  getVillages(wardId) {
    this.manServ.getVillages(wardId).subscribe(
      data => {
        this.villages = data;
      }
    );
  }

  getCrops() {
    this.manServ.getCrops().subscribe(
      data => {
        this.crops = data;
      }
    );
  }
  getErrorMessage() {
    return 'This field is required';
  }

  getEmailErrorMessage() {
    return 'Wrong email pattern';
  }
  getPhoneErrorMessage() {
    return 'Wrong phone pattern, please check the placeholder';
  }

  onNoClick() {
    this.dialogRef.close();
  }
  onSubmit() {
    console.log(this.addForm.value);
    var formData:any = new FormData();
    formData.append('name', this.addForm.controls['name'].value)
    formData.append('address', this.addForm.controls['address'].value)
    formData.append('phone', this.addForm.controls['phone'].value)
    formData.append('tin_number', this.addForm.controls['tin_number'].value)
    formData.append('category', this.addForm.controls['category'].value)
    formData.append('email', this.addForm.controls['email'].value)
    formData.append('company_name', this.addForm.controls['company_name'].value)
    formData.append('region_id', this.addForm.controls['region_id'].value)
    formData.append('district_id', this.addForm.controls['district_id'].value)
    formData.append('ward_id', this.addForm.controls['ward_id'].value)
    formData.append('village_id', this.addForm.controls['village_id'].value)
    formData.append('country_id', this.addForm.controls['country_id'].value)
    formData.append('user_id', localStorage.getItem('asdsUserId'))
    formData.append('image', this.selectedImage)

    // this.addForm.get('category').setValue(this.data.category);
    // const formData = {
    //   ...this.addForm.value,
    //   'user_id':  localStorage.getItem('asdsUserId')
    // }
    if (this.addForm.valid) {
      console.log('valid');
      this.manServ.addTraderData(formData).subscribe(
        (resp: any) => {
          this.snackBar.open('', resp['success'], {
            duration: 5000
          });
          this.onNoClick();
        }
      );
    }
  }


  onUpdate() {
    if (this.editForm.valid) {
      // var formData:FormData = new FormData();
      // formData.append('name', this.editForm.controls['name'].value)
      // formData.append('address', this.editForm.controls['address'].value)
      // formData.append('phone', this.editForm.controls['phone'].value)
      // formData.append('tin_number', this.editForm.controls['tin_number'].value)
      // formData.append('category', this.editForm.controls['category'].value)
      // formData.append('email', this.editForm.controls['email'].value)
      // formData.append('company_name', this.editForm.controls['company_name'].value)
      // formData.append('region_id', this.editForm.controls['region_id'].value)
      // formData.append('district_id', this.editForm.controls['district_id'].value)
      // formData.append('ward_id', this.editForm.controls['ward_id'].value)
      // formData.append('village_id', this.editForm.controls['village_id'].value)
      // formData.append('country_id', this.editForm.controls['country_id'].value)
      // formData.append('user_id', localStorage.getItem('asdsUserId'))
      // formData.append('image', this.selectedImage)
      // formData.append('_method', 'PUT')
      // console.log('valid')
      this.manServ.updateTraderData(this.editForm.value, this.data.id).subscribe(
        (data: string) => {
          this.snackBar.open('', data, {
            duration: 5000
          });
          this.onNoClick();
        },
        error => {
          console.log(error);
          this.snackBar.open('', 'Error', {
            duration: 5000
          });
        }
      );
    }
  }

  onFileChange(event) {
    const reader = new FileReader();
    
    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
    
      reader.onload = () => {
   
        this.imageSrc = reader.result as string;
     
        // this.userForm.patchValue({
        //   fileSource: reader.result,
        // });

        this.selectedImage = event.srcElement.files[0];
      };
   
    }
  }

}
