<div class="w-100">
    <!-- <div class="condition">
        <div class="row p-3 w-50 mt-1 ">
            <div class="col-md-5">
                <label class="switch">
                <input type="checkbox" value="one" [checked]="isChecked" (change)="itemChanged($event.target.value)">
                <span class="slider round"></span>
              </label><span class="desc"> market Registration</span>
            </div>
            <div class="col-md-7">
                <label class="switch">
                  <input type="checkbox" value="two" [checked]="!isChecked" (change)="itemChanged($event.target.value)">
                  <span class="slider round"></span>
                </label>
                <span class="desc"> Receive Commodities in a market</span>
            </div>
        </div>
    </div> -->
    <div [ngClass]="!isChecked ? 'hidden': ''">
        <div [ngClass]="!isLoading ? 'show' : 'hide'" class="view card">
            <div class="table-responsive">
                <div class="card-header d-flex justify-content-start">
                    <button class="button" mat-button (click)="onAddMarket()"><span class="material-icons notranslate">add_circle_outline</span>Add market</button>
                </div>
                <div class="card-body">
                    <!-- i have removed [dtTrigger]="dtTrigger" below  -->
                    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" id="dtbs" class="table p-3">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Market Name</th>
                                <th>Type</th>
                                <th>Quality/Grade</th>
                                <th>Manager</th>
                                <th>Village</th>
                                <th>Ward</th>
                                <th>District</th>
                                <th>Region</th>
                                <th>Latitude</th>
                                <th>Longitude</th>
                                <th>Action</th>
                            </tr>

                        </thead>
                        <tbody>
                            <tr *ngFor="let market of markets;let i = index">
                                <td>{{i+1}}</td>
                                <td>{{ market.name }}</td>
                                <td>{{ market.type }}</td>
                                <td>{{ market.quality }}</td>
                                <td>{{ market.manager }}</td>
                                <td>{{ market.village?.name }}</td>
                                <td>{{ market.ward?.name }}</td>
                                <td>{{ market.district?.name }}</td>
                                <td>{{ market.region?.name }}</td>
                                <td>{{ market.lat }}</td>
                                <td>{{ market.long }}</td>
                                <td style="display: flex; flex-direction: row;">
                                    <a class="text-success act" (click)="editMarket(market.id, market.region_id, market.district_id, market.ward_id)"><i class="fa fa-edit"></i></a>
                                    <mat-icon *ngIf="authService.isAdmin" class="text-danger act" (click)="deleteMarket(market.id)">delete_forever</mat-icon>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>


        <div *ngIf="isLoading" class="spinner-holder">
            <mat-spinner class="spinner"></mat-spinner>
        </div>
    </div>
    <!-- <div [ngClass]="isChecked ? 'hidden': ''">
        <app-inmarket></app-inmarket>
    </div> -->
</div>
