import { Component, OnInit } from '@angular/core';
import { OrderService } from '../order.service';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ManagementService } from '../../../management.service';
import { RegistrationService } from '../../registration/registration.service';

@Component({
  selector: 'app-order-form',
  templateUrl: './order-form.component.html',
  styleUrls: ['./order-form.component.css']
})
export class OrderFormComponent implements OnInit {
  form: FormGroup;

  id;
  action;

  crops;
  centers;
  loading = false;
  submitting = false;
  buyer

  fullName: string = '';
  phone: string = '';
  buyers;
  statuses = ['new', 'sold', 'cancelled'];
  showModal: boolean = false;
  searching: boolean = false;

  constructor(
    public active: ActivatedRoute,
    public acRegServ: RegistrationService,
    public orderServ: OrderService,
    public manServ: ManagementService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private route: Router,
  ) {
    active.url.subscribe(
      data => {
        // console.log(data[2].path)
        this.id = data[2].path;
        if (this.id) {
          this.action = 'update';
        }
      }
    );
  }

  ngOnInit(): void {
    this.loading = true
    this.form = this.fb.group({
      trader_id: ['', Validators.required],
      order_date: [this.getCurrentDate(), Validators.required],
      aggregation_center_id: ['', Validators.required],
      status: [''],
      items: this.fb.array([
        this.createItemFormGroup()
      ])
    });


    this.getAllCenters();
    this.getAllBuyers();
    this.getCrops();
    if (this.id) {
      this.orderServ.getOrderSingleData(this.id).subscribe(
        (data: any) => {
          // console.log(data);

          this.form.patchValue({
            trader_id: data.trader_id,
            status: data.status,
            order_date: data.order_date.split('T')[0],
            aggregation_center_id: data.aggregation_center_id,
          });
          this.buyer = data.trader?.full_name


          this.items.clear();  // Clear existing items
          // Add new items to the FormArray
          data.items.forEach(item => {
            this.items.push(this.fb.group({
              crop_id: [item.crop_id, Validators.required],
              quantity: [item.quantity, Validators.required],
              price: [item.price, Validators.required],
            })
            )
          });
        }
      );
    }
  }
  handleEvent(data: any) {
    // console.log(data); 
    this.buyer = data.full_name;
    this.form.get('trader_id').setValue(data.id);
  }

  getAllBuyers() {
    this.orderServ.getBuyers().subscribe(
      data => {
        this.buyers = data;
        this.loading = false;
      }
    );
  }

  getAllCenters() {
    this.acRegServ.getAggregationCenters().subscribe(
      data => {
        this.centers = data;
        this.loading = false;
      }
    );
  }

  getCrops() {
    this.loading = true;

    this.manServ.getCrops().subscribe(
      data => {
        this.crops = data;
        this.loading = false;
      }
    );
  }
  // Function to get the current date in 'YYYY-MM-DD' format
  getCurrentDate(): string {
    const today = new Date();
    const year = today.getFullYear();
    const month = ('0' + (today.getMonth() + 1)).slice(-2);  // Months are zero-based
    const day = ('0' + today.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  searchBuyers() {
    this.searching = true;
    this.orderServ.searchBuyers(this.fullName, this.phone).subscribe(
      (data: any) => {
        this.buyers = data;
        this.showModal = true;
        this.searching = false;
      },
      (error: any) => {
        console.error('Error fetching buyers:', error);
        this.searching = false;
      }
    );
  }

  // Getter to access the items FormArray
  get items(): FormArray {
    return this.form.get('items') as FormArray;
  }


  // Method to create a new FormGroup for an item
  createItemFormGroup(): FormGroup {
    return this.fb.group({
      crop_id: ['', Validators.required],
      quantity: ['', Validators.required],
      price: ['', Validators.required],
    });
  }

  // Method to add a new item (FormGroup) to the items FormArray
  addItem() {
    this.items.push(this.createItemFormGroup());
  }

  // Method to remove an item from the items FormArray by index
  removeItem(index: number) {
    this.items.removeAt(index);
  }

  onSubmit() {
    this.submitting = true;
    if (this.form.valid) {
      // console.log(this.form.value);

      if (this.action !== 'update') {
        this.form.value['created_by'] = localStorage.getItem('asdsUserId');
        // console.log('valid');
        this.orderServ.addOrder(this.form.value).subscribe(
          (resp: any) => {
            this.snackBar.open('message', resp['success'], {
              duration: 5000
            });
            this.submitting = false;
            this.route.navigate(['manage-ac/order-produces'])
          }
        );
      } else {
        this.orderServ.updateOrderData(this.form.value, this.id).subscribe(
          (resp: any) => {
            this.snackBar.open('message', resp['success'], {
              duration: 5000
            });
            this.submitting = false;
            this.route.navigate(['manage-ac/order-produces'])
          }
        );
      }
    }
  }


  goBack() {
    this.route.navigate(['manage-ac/order-produces']);
  }
}
