<form *ngIf="data.action == 'add'" [formGroup]="marketForm" (ngSubmit)="onSubmit()">
    <div class="title-holder">
        <h2 class="title">Add Market</h2>
    </div>
    <div class="row">
        <div class="col-md-6 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Market Name</mat-label>
                <input matInput placeholder="Market Name" formControlName="name" required>
                <mat-error *ngIf="marketForm.get('name').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Market Type</mat-label>
                <mat-select placeholder="Select type" formControlName="type" required>
                    <mat-option value="primary">Primary (Collection center, auction)</mat-option>
                    <mat-option value="secondary">Secondary</mat-option>
                    <!-- <mat-option value="Whole sellers">Whole sellers</mat-option> -->
                </mat-select>
                <mat-error *ngIf="marketForm.get('type').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 space1">
            <!-- <mat-form-field class="w-100" appearance="outline">
                <mat-label>Manager</mat-label>
                <input matInput placeholder="Manager" formControlName="manager" required>
                <mat-error *ngIf="marketForm.get('manager').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field> -->
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Manager</mat-label>
                <mat-select placeholder="Select manager" formControlName="manager">
                    <mat-option *ngFor="let manager of managers" [value]="manager.id">{{manager.full_name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="marketForm.get('manager').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>market quality</mat-label>
                <mat-select placeholder="Select quality" formControlName="quality" required>
                    <mat-option value="high">High</mat-option>
                    <mat-option value="moderate">Moderate</mat-option>
                    <mat-option value="low">Low</mat-option>
                    <mat-option value="unknown">Unknown</mat-option>
                </mat-select>
                <mat-error *ngIf="marketForm.get('quality').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row column">
        <div class="col-md-6 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Regions</mat-label>
                <mat-select placeholder="Select Region" formControlName="region_id" required (selectionChange)="getDistricts($event.value)">
                    <mat-option *ngFor="let region of regions" [value]="region.id">{{region.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="marketForm.get('region_id').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Districts</mat-label>
                <mat-select placeholder="Select District" formControlName="district_id" required (selectionChange)="getWards($event.value)">
                    <mat-option *ngFor="let district of districts" [value]="district.id">{{district.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="marketForm.get('district_id').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row column">
        <div class="col-md-6 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Wards</mat-label>
                <mat-select placeholder="Select Ward" formControlName="ward_id" (selectionChange)="getVillages($event.value)">
                    <mat-option *ngFor="let ward of wards" [value]="ward.id">{{ward.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="marketForm.get('ward_id').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Villages/Streets</mat-label>
                <mat-select placeholder="Select Village" formControlName="village_id">
                    <mat-option *ngFor="let village of villages" [value]="village.id">{{village.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="marketForm.get('village_id').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row column">
        <div class="col-md-6 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>latitude</mat-label>
                <input matInput placeholder="latitude" formControlName="latitude">
            </mat-form-field>
        </div>
        <div class="col-md-6 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>longitudes</mat-label>
                <input matInput placeholder="longitudes" formControlName="longitude">
            </mat-form-field>
        </div>
    </div>

    <div style="margin-top: 30px; display: flex; flex-direction: row; justify-content: flex-end;">
        <button (click)="onNoClick()" class="cancelbtn" mat-button>Cancel</button>

        <button type="submit" class="createbtn" mat-button>Submit</button>
    </div>
</form>

<!-- Update market -->

<form *ngIf="data.action == 'edit'" [formGroup]="editForm" (ngSubmit)="onUpdate()">
    <div class="title-holder">
        <h2 class="title">Edit Market</h2>
    </div>
    <div class="row">
        <div class="col-md-6 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Market Name</mat-label>
                <input matInput placeholder="Market Name" formControlName="name" required>
                <mat-error *ngIf="editForm.get('name').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Market Type</mat-label>
                <mat-select placeholder="Select type" formControlName="type" required>
                    <mat-option value="primary">Primary (Collection center, auction)</mat-option>
                    <mat-option value="secondary">Secondary</mat-option>
                    <!-- <mat-option value="Whole sellers">Whole sellers</mat-option> -->
                </mat-select>
                <mat-error *ngIf="editForm.get('type').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 space1">
            <!-- <mat-form-field class="w-100" appearance="outline">
                <mat-label>Manager</mat-label>
                <input matInput placeholder="Manager" formControlName="manager" required>
                <mat-error *ngIf="editForm.get('manager').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field> -->
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Manager</mat-label>
                <mat-select placeholder="Select manager" formControlName="manager">
                    <mat-option *ngFor="let manager of managers" [value]="manager.id">{{manager.full_name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="editForm.get('manager').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>market quality</mat-label>
                <mat-select placeholder="Select quality" formControlName="quality" required>
                    <mat-option value="high">High</mat-option>
                    <mat-option value="moderate">Moderate</mat-option>
                    <mat-option value="low">Low</mat-option>
                    <mat-option value="unknown">Unknown</mat-option>
                </mat-select>
                <mat-error *ngIf="editForm.get('quality').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row column">
        <div class="col-md-6 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Regions</mat-label>
                <mat-select placeholder="Select Region" formControlName="region_id" required (selectionChange)="getDistricts($event.value)">
                    <mat-option *ngFor="let region of regions" [value]="region.id">{{region.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="editForm.get('region_id').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Districts</mat-label>
                <mat-select placeholder="Select District" formControlName="district_id" required (selectionChange)="getWards($event.value)">
                    <mat-option *ngFor="let district of districts" [value]="district.id">{{district.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="editForm.get('district_id').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row column">
        <div class="col-md-6 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Wards</mat-label>
                <mat-select placeholder="Select Ward" formControlName="ward_id" (selectionChange)="getVillages($event.value)">
                    <mat-option *ngFor="let ward of wards" [value]="ward.id">{{ward.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="editForm.get('ward_id').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Villages/Streets</mat-label>
                <mat-select placeholder="Select Village" formControlName="village_id">
                    <mat-option *ngFor="let village of villages" [value]="village.id">{{village.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="editForm.get('village_id').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row column">
        <div class="col-md-6 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>latitude</mat-label>
                <input matInput placeholder="latitude" formControlName="latitude">
            </mat-form-field>
        </div>
        <div class="col-md-6 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>longitudes</mat-label>
                <input matInput placeholder="longitudes" formControlName="longitude">
            </mat-form-field>
        </div>
    </div>

    <div style="margin-top: 30px; display: flex; flex-direction: row; justify-content: flex-end;">
        <button (click)="onNoClick()" class="cancelbtn" mat-button>Cancel</button>

        <button type="submit" class="createbtn" mat-button>Update</button>
    </div>
</form>
