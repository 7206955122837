import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  index = 0;
  isOpened = true;
  title;
  menu;
  panelOpenState1 = false;
  panelOpenState = false;
  panelOpenState2 = false;
  panelOpenState3 = false;
  panelOpenState4 = false;

  url = '';

  constructor(
    public authService: AuthService,
    public router: Router,
    public activRoute: ActivatedRoute,
    ) { }

  ngOnInit(): void {
    this.url = this.router.url;
    // console.log(this.url)
    
    if (window.location.href.indexOf('managements') > -1) {
      this.index = 10;
      this.resetPannel();
      // this.panelOpenState1 = true;
      this.panelOpenState2 = true;
      this.title = 'Manage warehouses';
      this.menu = 'Warehouses';
    }
    if (window.location.href.indexOf('warehouses/receive-commodities') > -1) {
      this.index = 17;
      this.resetPannel();
      // this.panelOpenState1 = true;
      this.panelOpenState2 = true;
      this.title = 'Receive Commodities';
      this.menu = 'Warehouses/Receive Stock';
    }
    if (window.location.href.indexOf('warehouses/released-commodities') > -1) {
      this.index = 19;
      this.resetPannel();
      // this.panelOpenState1 = true;
      this.panelOpenState2 = true;
      this.title = 'Released Commodities';
      this.menu = 'Warehouses/Release Stock';
    }
    if (window.location.href.indexOf('stock-in-market') > -1) {
      this.index = 1;
      this.resetPannel();
      this.title = 'Stock in market';
      this.menu = 'Markets Trade';
    }
    if (window.location.href.indexOf('stock-movement') > -1) {
      this.index = 2;
      this.resetPannel();
      this.title = 'Stock movement';
      this.menu = 'Stock Movement';
    }
    if (window.location.href.indexOf('markets') > -1) {
      this.index = 11;
      this.resetPannel();
      // this.panelOpenState1 = true;
      this.panelOpenState3 = true;
      this.title = 'Manage Markets';
      this.menu = 'Markets';
    }
    if (window.location.href.indexOf('markets/receive-commodities') > -1) {
      this.index = 18;
      this.resetPannel();
      // this.panelOpenState1 = true;
      this.panelOpenState3 = true;
      this.title = 'Receive Commodities';
      this.menu = 'Markets/Receive Stock';
    }
    if (window.location.href.indexOf('markets/released-commodities') > -1) {
      this.index = 20;
      this.resetPannel();
      // this.panelOpenState1 = true;
      this.panelOpenState3 = true;
      this.title = 'Released Commodities';
      this.menu = 'Markets/Release Stock';
    }
    if (window.location.href.indexOf('crops') > -1) {
      this.index = 12;
      this.resetPannel();
      this.panelOpenState1 = true;
      this.title = 'Manage Crops';
      this.menu = 'Crops';
    }
    if (window.location.href.indexOf('managements/traders') > -1) {
      this.index = 13;
      this.resetPannel();
      // this.panelOpenState1 = true;
      this.title = 'Manage Traders';
      this.menu = 'Traders';
    }
    if (window.location.href.indexOf('withdraw-commodities') > -1) {
      this.index = 14;
      this.resetPannel();
      // this.panelOpenState1 = true;
      this.title = 'Manage Commodities Withdraw';
    }
    if (window.location.href.indexOf('users') > -1) {
      this.index = 15;
      this.resetPannel();
      // this.panelOpenState1 = true;
      this.panelOpenState = true;
      this.title = 'Manage Users';
      this.menu = 'Users';
    }
    if (window.location.href.indexOf('user-roles') > -1) {
      this.index = 16;
      this.resetPannel();
      // this.panelOpenState1 = true;
      this.panelOpenState = true;
      this.title = 'Manage User Roles';
      this.menu = 'Users/Roles';
    }
    if (window.location.href.indexOf('unit-of-measure') > -1) {
      this.index = 21;
      this.resetPannel();
      // this.panelOpenState1 = true;
      this.panelOpenState1 = true;
      this.title = 'Manage Units of Measure';
      this.menu = 'Units of Measure';
    }
    if (window.location.href.indexOf('cross-points') > -1) {
      this.index = 22;
      this.resetPannel();
      // this.panelOpenState1 = true;
      this.panelOpenState4 = true;
      this.title = 'Manage Cross Points';
      this.menu = 'Cross Points';
    }
    if (window.location.href.indexOf('data-cross-points') > -1) {
      this.index = 23;
      this.resetPannel();
      // this.panelOpenState1 = true;
      this.panelOpenState4 = true;
      this.title = 'Cross Points Data Management';
      this.menu = 'Cross Points/Management';
    }
    if (window.location.href.indexOf('market-prices') > -1) {
      this.index = 24;
      this.resetPannel();
      // this.panelOpenState1 = true;
      this.panelOpenState4 = true;
      this.title = 'Market Prices Data Management';
      this.menu = 'Market/Market Prices';
    }
    if (window.location.href.indexOf('stock-in-warehouse') > -1){
      this.title = 'Stock in Warehouses'
      this.menu = 'Warehouse Stock';
    }
  }

  // tslint:disable-next-line: typedef
  toggleSideBar() {
    this.isOpened = !this.isOpened;
  }

  resetPannel() {
    this.panelOpenState1 = false;
    this.panelOpenState = false;
    this.panelOpenState2 = false;
    this.panelOpenState3 = false;
    this.panelOpenState4 = false;
  }

  urlContains(keyword) {
    if(this.url.includes(keyword)) {
      return true;
    } else {
      return false;
    }
  }

}
