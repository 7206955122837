<div class="col-md-7">
    <div class="view card">
        <div class="mb-2">
            <button mat-raised-button class="btn btn-success" (click)="back()">Go back</button>
        </div>
        <table *ngIf="!isLoading">
            <tr>
                <th>Name</th>
                <th>Value</th>
            </tr>
            <tr>
                <th>Received date</th>
                <td>{{ data.date | date }}</td>
            </tr>
            <tr>
                <th>Market Name</th>
                <td>{{ data.market?.name }}</td>
            </tr>
            <tr>
                <th>Broker</th>
                <td>{{ data.broker }}</td>
            </tr>
            <tr>
                <th>Crop</th>
                <td>{{ data.crop?.name }}</td>
            </tr>
            <tr>
                <th>Received quantity</th>
                <td>{{ data.quantity }}</td>
            </tr>
            <tr>
                <th>Crop quality</th>
                <td>{{ data.quality }}</td>
            </tr>
            <tr>
                <th>Wholesale price</th>
                <td>{{ data.total_price }}</td>
            </tr>
            <tr>
                <th>Retail price</th>
                <td>{{ data.retail_price }}</td>
            </tr>
            <tr>
                <th>CESS Payment (TZs)</th>
                <td>{{ data.cess_payment }}</td>
            </tr>
            <tr>
                <th>Source</th>
                <td>{{ data.source }}</td>
            </tr>
            <tr>
                <th>Source name</th>
                <td *ngIf="data.source == 'Market'">{{ data.source_market.name }}</td>
                <td *ngIf="data.source == 'Warehouse'">{{ data.source_warehouse.name }}</td>
                <td *ngIf="data.source == 'Farm'"></td>
            </tr>
            <tr>
                <th>Source region</th>
                <td>{{ data.region?.name }}</td>
            </tr>
            <tr>
                <th>Source district</th>
                <td>{{ data.district?.name }}</td>
            </tr>
            <tr>
                <th>Source ward</th>
                <td>{{ data.ward?.name }}</td>
            </tr>
            <tr>
                <th>Source village</th>
                <td>{{ data.village?.name }}</td>
            </tr>
        </table>

        <div *ngIf="isLoading" class="spinner-holder">
            <mat-spinner class="spinner"></mat-spinner>
        </div>
    </div>
</div>
