<!-- <div [ngClass]="!isLoading ? 'show' : 'hide'" class="view card"> -->
<div [ngClass]="!isChecked ? 'hidden': ''">
    <div [ngClass]="!isLoading ? 'show' : 'hide'" class="view card">
        <div class="table-responsive">
            <div class="card-header d-flex justify-content-start" *ngIf="authService.isAllowed('input-data')">
                <button class="button" mat-button (click)="onReceive()"><span
                        class="material-icons notranslate">add_circle_outline</span>Receive in Secondary Market</button>
            </div>
            <div class="card-body">
                <!-- i have removed [dtTrigger]="dtTrigger" below  -->
                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" id="dtbs" class="table p-3">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>Market Name</th>
                            <th>Broker</th>
                            <th>Crop</th>
                            <!-- <th>Quality/Grade</th> -->
                            <th>Quantity (MT)</th>
                            <th>Wholesale Price</th>
                            <th>Retail Price</th>
                            <!-- <th>CESS Payments</th>
                            <th>Village</th>
                            <th>Ward</th> -->
                            <th>Source Region</th>
                            <th>Source District</th>
                            <th>Latitude</th>
                            <th>Longitude</th>
                            <th *ngIf="authService.isAllowed('input-data')">Action</th>
                        </tr>

                    </thead>
                    <tbody>
                        <tr *ngFor="let market of markets;let i = index">
                            <td>{{i+1}}</td>
                            <td>{{market.date | date: mediumDate}}</td>
                            <td>{{market.market}}</td>
                            <td>{{market.auctioneer}}</td>
                            <td>{{market.crop}}</td>
                            <!-- <td>{{market.quality}}</td> -->
                            <td>{{market.quantity}}</td>
                            <td>{{market.total_price}}</td>
                            <td>{{market.retail_price}}</td>
                            <!-- <td>{{market.cess_payment}}</td> -->
                            <!-- <td>{{market.village}}</td>
                            <td>{{market.ward}}</td> -->
                            <td>{{market.region?.name}}</td>
                            <td>{{market.district?.name}}</td>
                            <td>{{market.market?.lat}}</td>
                            <td>{{market.market?.long}}</td>
                            <td *ngIf="authService.isAllowed('input-data')" style="display: flex; flex-direction: row;">
                                <mat-icon class="text-secondary act" title="view more" (click)="viewMore(market.id)">
                                    visibility</mat-icon>
                                <a class="text-success act"
                                    (click)="editMarket(market.id, market.region_id, market.district_id, market.ward_id)"><i
                                        class="fa fa-edit"></i></a>
                                <mat-icon *ngIf="authService.isAdmin" class="text-danger act"
                                    (click)="deleteMarket(market.id)">delete_forever</mat-icon>
                                <!--<mat-icon class="text-primary act" title="withdraw good" (click)="withdraw(market.id, market.market_id, market.trader_id, market.crop_id)">ios_share</mat-icon> -->
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>


    </div>
    <div *ngIf="isLoading" class="spinner-holder">
        <mat-spinner class="spinner"></mat-spinner>
    </div>
</div>