<div [ngClass]="!isLoading ? 'show' : 'hide'">
  <div class="d-flex justify-content-start mt-1 bg-white">
      <div class="p-3">
          <button class="button" mat-button (click)="onAddUom()"><span class="material-icons notranslate">add_circle_outline</span>Add Unit of Measure</button>
      </div>
  </div>
  <div class="col-md-12">
      <div class="view">
          <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table p-3">
              <thead>
                  <tr>
                      <th width="95%">Unit of Measure</th>
                      <th width="5%">Action</th>
                  </tr>

              </thead>
              <tbody>
                  <tr *ngFor="let uom of uoms">
                      <td>{{uom.uom}}</td>

                      <td style="display: flex; flex-direction: row;">
                          <a class="text-success act" (click)="editUom(uom.id)"><i class="fa fa-edit"></i></a>
                          <mat-icon *ngIf="authService.isAdmin" class="text-danger act" (click)="deleteUom(uom.id)">delete_forever</mat-icon>
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>

  </div>
</div>

<div *ngIf="isLoading" class="spinner-holder">
  <mat-spinner class="spinner"></mat-spinner>
</div>
