import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-farmer-modal',
  templateUrl: './farmer-modal.component.html',
  styleUrls: ['./farmer-modal.component.css']
})
export class FarmerModalComponent implements OnInit {
  @Input() farmers: any[] = [];
  @Input() isVisible: boolean = false;

  @Output() eventEmitter = new EventEmitter();

  ngOnInit(): void {
  }

  closeModal() {
    this.isVisible = false;
  }

  setFarmer(farmer) {
    this.eventEmitter.emit(farmer);
    this.farmers = []
  }

}
