import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ManagementService } from '../../../management.service';
import { ProducesService } from '../produces.service';

@Component({
  selector: 'app-aggregated-list',
  templateUrl: './aggregated-list.component.html',
  styleUrls: ['./aggregated-list.component.css']
})
export class AggregatedListComponent implements OnInit {

  produces;
  isLoading = true
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  dtCheck = false;
  constructor(
    public dialog: MatDialog,
    private produceServ: ProducesService,
    private manServ: ManagementService,
    public authService: AuthService,
    public route: Router
    ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.getProduces();
    this.dtTrigger.next();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 100,
      processing: true,
      dom: 'Bfrtip',
        // buttons: [
        //     'copy', 'csv', 'excel', 'print'
        // ]
    };
  }
  onAddProduce() {
    this.route.navigate(['manage-ac/produces/add'])
  }


  getProduces() {
    this.produceServ.getProduces().subscribe(
      data => {
        this.produces = data;
        this.isLoading = false
        this.rerender();
      }
    );
  }

  rerender(): void {
    if (this.dtCheck){
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
      });
    }
    else {
      this.dtCheck = true;
      this.dtTrigger.next();
    }

  }
}
