<div class="relative">
    <div id="content-header" class="absolute -top-24">
        <h1 class="text-uppercase font-bold">Sell Produces</h1>
    </div>
    <div class="absolute -top-28 right-2 cursor-pointer text-white bg-red-500 hover:bg-red-600 rounded-lg h-6 w-6 items-center text-center"
        title="Close" (click)="goBack()">x</div>
    <div class="flex flex-col-reverse md:flex-row gap-4 w-full min-h-64">
        <div class="px-4 bg-gray-50 rounded-md p-2 relative w-full">
            <form [formGroup]="form" (ngSubmit)="onSubmit()">

                <div class="mb-2">
                    <label for="buyer_name">Buyer Name<span class="text-red-500">*</span></label>
                    <input type="text" id="buyer_name" formControlName="buyer_name" placeholder="Enter buyer name"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    <div class="text-red" *ngIf="form.get('buyer_name').invalid && form.get('buyer_name').touched">Name
                        is required.</div>
                </div>
                <div class="mb-2">
                    <label for="buyer_phone">Buyer Phone<span class="text-red-500">*</span></label>
                    <input type="tel" min="1" id="buyer_phone" formControlName="buyer_phone" placeholder="255123456789"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    <div class="text-red" *ngIf="form.get('buyer_phone').invalid && form.get('buyer_phone').touched">
                        Phone is required.</div>
                </div>
                <div class="mb-2">
                    <label for="amount">Amount (Kg)<span class="text-red-500">*</span></label>
                    <input type="number" min="1" max="{{ produce.amount }}" id="amount" formControlName="amount"
                        placeholder="Enter center amount"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    <div class="text-red" *ngIf="form.get('amount').invalid && form.get('amount').touched">Amount is
                        required.</div>
                </div><button type="submit" [disabled]="form.invalid"
                    class="px-2.5 py-1.5 rounded-md flex space-x-2 items-center">
                    <i class="fa fa-spinner fa-spin" *ngIf="submitting"></i>
                    <span>Submit</span>
                </button>
            </form>

            <div class="absolute top-1/2 right-1/2 z-50" *ngIf="loading">
                <div class="w-20 h-20 bg-black/50 flex justify-center items-center rounded-md text-gray-200">
                    <i class="fa fa-spinner fa-spin text-4xl"></i>
                </div>
            </div>
        </div>

        <div class="w-full overflow-x-auto my-0.5">
            <div class="bg-green-100/5 rounded-md min-w-96 p-2">

                <div class="flex space-x-2 py-1">
                    <div class="font-bold">Farmer:</div>
                    <div class="">{{ produce.farmer.full_name }}</div>
                </div>
                <div class="flex space-x-2 py-1">
                    <div class="font-bold">Crop:</div>
                    <div class=" md:flex">{{ produce.crop.name }}</div>
                </div>
                <div class="flex space-x-2 py-1">
                    <div class="font-bold">Amount Available (Kg):</div>
                    <div class=" md:flex">{{ produce.amount }}</div>
                </div>
                <div class="flex space-x-2 py-1">
                    <div class="font-bold">Unit Price (Tsh):</div>
                    <div class=" md:flex">{{ produce.value }}</div>
                </div>
            </div>
        </div>
    </div>
</div>