import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ManagementService } from 'src/app/modules/management/management.service';
import { environment } from 'src/environments/environment';
import { BuyerService } from '../../buyer.service';

@Component({
  selector: 'app-buyer-crops',
  templateUrl: './buyer-crops.component.html',
  styleUrls: ['./buyer-crops.component.css']
})
export class BuyerCropsComponent implements OnInit {

  isLoading = true;
  data;
  imageUrl = environment.imageUrl;
  status = 'approved';
  crops;
  selected_crops = [];

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();
  dtCheck = false;
  constructor(
    private dialog: MatDialog,
    private manServ: ManagementService,
    public authService: AuthService,
    public buyerService: BuyerService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.getPermits();
    this.getCrops();
  }
  
  ngAfterViewInit() {
    this.dtTrigger.next();

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 100,
      processing: true,
      responsive: true,
      dom: 'Bfrtip',
      // buttons: [
      //     'copy', 'csv', 'excel', 'print'
      // ]
    };

  }

  getPermits() {
    this.buyerService.getPermitByStatus(this.status).subscribe(
      data => {
        this.data = data;
        this.isLoading = false;

        this.rerender();
      }
    );
  }

  getPermitsByCrop() {
    var data = {
      'crops': this.selected_crops
    }

    this.buyerService.getPermitByCrop(data).subscribe(
      data => {
        this.data = data;
        this.isLoading = false;

        this.rerender();
      }
    );
  }
  rerender(): void {
    if (this.dtCheck){
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
          //  $('#dtb').DataTable();
      });
    }
    else {
      this.dtCheck = true;
      this.dtTrigger.next();
    }

  }

  changeCrop(crop) {
    this.isLoading = true;
    console.log(crop)
    this.selected_crops = crop;
    this.getPermitsByCrop();
  }

  getCrops() {
    this.manServ.getCrops().subscribe(
      data => {
        this.crops = data;
      }
    );
  }
  
}
