import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ManagementService } from 'src/app/modules/management/management.service';
import { environment } from 'src/environments/environment';
import { BuyerService } from '../../buyer.service';

// Import DataTables API
import 'datatables.net';

@Component({
  selector: 'app-returned',
  templateUrl: './returned.component.html',
  styleUrls: ['./returned.component.css']
})
export class ReturnedComponent implements OnInit, AfterViewInit {
  isLoading = true;
  data: any;
  imageUrl = environment.imageUrl;

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  dtCheck = false;

  constructor(
    private dialog: MatDialog,
    private manServ: ManagementService,
    public authService: AuthService,
    public buyerService: BuyerService,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.getVerifiedPermits();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 100,
      processing: true,
      responsive: true,
    };
  }

  getVerifiedPermits(): void {
    this.buyerService.getPermitByStatus('returned').subscribe(
      data => {
        this.data = data;
        this.isLoading = false;
        console.log(data);

        this.rerender();
      }
    );
  }

  rerender(): void {
    if (this.dtCheck) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtCheck = true;
      this.dtTrigger.next();
    }
  }
}
