import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';
import { BuyerService } from '../buyer.service';

@Component({
  selector: 'app-qr-info',
  templateUrl: './qr-info.component.html',
  styleUrls: ['./qr-info.component.css']
})
export class QrInfoComponent implements OnInit {

  application;
  permitNumber
  attachmentUrl = ''
  prevUrl: string;
  isLoading = true;

  constructor(
    public buyerService: BuyerService,
    public route: Router,
    public actvRoute: ActivatedRoute,
    public dialog: MatDialog,
    public authService: AuthService
  ) { 
    
  }

  ngOnInit(): void {
    this.prevUrl = this.buyerService.getPreviousUrl();
    
    this.attachmentUrl = environment.imageUrl;
    this.permitNumber = this.actvRoute.params['value'].number;
    this.getDetails();
    
  }

  getDetails() {
    this.buyerService.getPermitApplicationDetailsByNumber(this.permitNumber).subscribe(
      data => {
        this.application = data
        console.log(data);
        this.isLoading = false;
      }
    );
  }

}
