<app-header *ngIf="showHeader"></app-header>
<div class="main-holder mt-xl-2">
    <div class="sm:px-4 md:px-36">
        <div class="flex justify-between items-center">
            <div class="sm: text-xl md:text-4xl font-bold text-sky-900 notranslate">Crop Stock Dynamics</div>
            <div class="w-28">
                <img src="assets/copra_logo.png" class="">
            </div>
        </div>
        <div class="py-3 md:grid md:grid-cols-3 bg-gray-50 rounded-lg px-2">
            <div class="border border-sky-900">
                <div class="bg-sky-900 text-lg px-2 py-2.5 text-gray-100 rounded-sm">{{ 'about-title' | translate }}</div>
                <div class="px-2 py-4">
                    {{ 'about' | translate }}
                </div>
            </div>
            <div class="border border-sky-900">
                <div class="bg-sky-900 text-lg px-2 py-2.5 text-gray-100 rounded-sm">{{ 'new-reg-title' | translate }}</div>
                <div class="px-2 py-4">
                    {{ 'new-reg-info' | translate }}

                    <a href="/register"
                    class="block text-center bg-gray-300 hover:no-underline hover:bg-gray-300/80 hover:text-gray-700 py-2.5 px-2.5 mt-3 shadow-md regist er-btn"
                    mat-butto n>{{ 'new-reg-btn' | translate }}</a>
                </div>
            </div>
            <div class="border border-sky-900">
                <div class="bg-sky-900 text-lg px-2 py-2.5 text-gray-100 rounded-sm">{{ 'reset_password'|translate }}</div>
                <div class="px-4 py-4">
                    <form [formGroup]="authForm" (ngSubmit)="onSubmit()" class="font">
                        <div class="">
                            <mat-form-field class="add-form-field w-full" appearance="outline">
                                <mat-label>{{ 'your_email'|translate }}</mat-label>
                                <input matInput placeholder="{{ 'your_email'|translate }} {{ 'reset_placeholder'|translate }}" formControlName="email" required>
                                <mat-error *ngIf="authForm.get('email').invalid">{{getErrorMessage()}}</mat-error>
                            </mat-form-field>

                        </div>

                        <div class="pt-2">
                            <button class="button login-btn w-full py-2" mat-button><span *ngIf="submitting" class="px-2"><i class="fa fa-spinner fa-spin"></i></span>{{ 'reset'|translate }}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <p class="pt-2 text-center notranslate" style="color: grey">{{ currentYear }}&copy;crop-stock dynamics</p>
</div>