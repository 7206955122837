<form *ngIf="data.action == 'add'" [formGroup]="addForm" (ngSubmit)="onSubmit()">
    <div class="title-holder">
        <h2 class="title">Add {{data.category | titlecase}}</h2>
    </div>
    <div class="row">
        <div class="col-md-6 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>{{data.category | titlecase}} Name</mat-label>
                <input matInput placeholder="Enter Name" formControlName="name" required>
                <mat-error *ngIf="addForm.get('name').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>{{data.category | titlecase}} Phone</mat-label>
                <input matInput type="tel" placeholder="255xxxxxxxxx" formControlName="phone" required>
                <mat-error *ngIf="addForm.get('phone').invalid">{{getPhoneErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput placeholder="Email" formControlName="email" required>
                <mat-error *ngIf="addForm.get('email').invalid">{{getEmailErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Adddress</mat-label>
                <input matInput placeholder="Adddress" formControlName="address">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Company name</mat-label>
                <input matInput type="text" placeholder="Company name" formControlName="company_name">
            </mat-form-field>
        </div>
        <div class="col-md-6 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>TIN number</mat-label>
                <input matInput type="text" [textMask]="{mask: tinmask}" placeholder="TIN number" formControlName="tin_number">
            </mat-form-field>
        </div>
    </div>
    <div class="col-md-12 column">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Country</mat-label>
            <mat-select placeholder="Select Country" formControlName="country_id" required (selectionChange)="changeValidation($event.value)">
                <mat-option *ngFor="let country of countries" [value]="country.id">{{country.country_name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="addForm.get('country_id').invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>
    </div>
    <div *ngIf="isRequired">
        <div class="row column">
            <div class="col-md-6 space1">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Regions</mat-label>
                    <mat-select placeholder="Select Region" formControlName="region_id" [required]="isRequired" (selectionChange)="getDistricts($event.value)">
                        <mat-option *ngFor="let region of regions" [value]="region.id">{{region.name}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="addForm.get('region_id').invalid">{{getErrorMessage()}}</mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6 space3">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Districts</mat-label>
                    <mat-select placeholder="Select District" formControlName="district_id" [required]="isRequired" (selectionChange)="getWards($event.value)">
                        <mat-option *ngFor="let district of districts" [value]="district.id">{{district.name}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="addForm.get('district_id').invalid">{{getErrorMessage()}}</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row column">
            <div class="col-md-6 space1">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Wards</mat-label>
                    <mat-select placeholder="Select Ward" formControlName="ward_id" (selectionChange)="getVillages($event.value)">
                        <mat-option *ngFor="let ward of wards" [value]="ward.id">{{ward.name}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="addForm.get('ward_id').invalid">{{getErrorMessage()}}</mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6 space3">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Villages/Streets</mat-label>
                    <mat-select placeholder="Select Village" formControlName="village_id">
                        <mat-option *ngFor="let village of villages" [value]="village.id">{{village.name}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="addForm.get('village_id').invalid">{{getErrorMessage()}}</mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="col-md-12 column">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Trader Category</mat-label>
            <mat-select placeholder="Select Category" formControlName="category" required multiple>
                <mat-option value="Buyer">Buyer</mat-option>
                <mat-option value="Seller">Seller</mat-option>
                <mat-option value="Broker">Broker</mat-option>
                <mat-option value="Warehouse Owner">Warehouse Owner</mat-option>
                <mat-option value="Warehouse Operator">Warehouse Operator</mat-option>
                <mat-option value="Market Manager">Market Manager</mat-option>
                <mat-option value="Checkpoint Manager">Checkpoint Manager</mat-option>
            </mat-select>
            <mat-error *ngIf="addForm.get('category').invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>
    </div>
    <div class="w-100 mb-1">
        <label>Image</label>
        <!-- <button type="button" mat-raised-button (click)="fileInput.click()">Choose File</button> -->
        <!-- <input hid den (change)="onFileSelected($event)" formControlName="image" #fileInput type="file"> -->
        <input class="form-control" formControlName="image" accept="image/*" #fileInput type="file" (change)="onFileChange($event)">
        <span class="file-name">{{selectedFile?.name}}</span>
        <img [src]="imageSrc" *ngIf="imageSrc" style="height: 100px; width:100px; margin-top: 10px;">
    </div>

    <div class="col-md-12 column">
        <mat-form-field class="w-100" appearance="fill">
            <mat-label>Date (13/1/2001)</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="date" required>
            <mat-datepicker-toggle matSuffix [for]="picker">
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="addForm.get('date').invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>
    </div>
    <!--<div class="col-md-12 column">
        <mat-form-field class="w-100" appearance="fill">
            <mat-label>Crops</mat-label>
            <mat-select formControlName="crops" multiple>
                <mat-option *ngFor="let crop of crops" [value]="crop.id">{{crop.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="addForm.get('crops').invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>
    </div> -->
    <div style="margin-top: 30px; display: flex; flex-direction: row; justify-content: flex-end;">
        <button (click)="onNoClick()" class="cancelbtn" mat-button>Cancel</button>

        <button type="submit" class="createbtn" mat-button>Submit</button>
    </div>
</form>

<!-- Update Warehouse -->

<form *ngIf="data.action == 'edit'" [formGroup]="editForm" (ngSubmit)="onUpdate()">
    <div class="title-holder">
        <h2 class="title">Update {{data.category | titlecase}}</h2>
    </div>
    <div class="row">
        <div class="col-md-6 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>{{data.category | titlecase}} Name</mat-label>
                <input matInput placeholder="Enter Name" formControlName="name" required>
                <mat-error *ngIf="editForm.get('name').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>{{data.category | titlecase}} Phone</mat-label>
                <input matInput type="tel" placeholder="255xxxxxxxxx" formControlName="phone" required>
                <mat-error *ngIf="editForm.get('phone').invalid">{{getPhoneErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput placeholder="Email" formControlName="email" required>
                <mat-error *ngIf="editForm.get('email').invalid">{{getEmailErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Adddress</mat-label>
                <input matInput placeholder="Adddress" formControlName="address">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Company name</mat-label>
                <input matInput type="text" placeholder="Company name" formControlName="company_name">
            </mat-form-field>
        </div>
        <div class="col-md-6 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>TIN number</mat-label>
                <input matInput type="text" [textMask]="{mask: tinmask}" placeholder="TIN number" formControlName="tin_number">
            </mat-form-field>
        </div>
    </div>
    <div class="col-md-12 column">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Country</mat-label>
            <mat-select placeholder="Select Region" formControlName="country_id" required (selectionChange)="changeValidation($event.value)">
                <mat-option *ngFor="let country of countries" [value]="country.id">{{country.country_name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="editForm.get('country_id').invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>
    </div>
    <div *ngIf="isRequired">
        <div class="row column">
            <div class="col-md-6 space1">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Regions</mat-label>
                    <mat-select placeholder="Select Region" formControlName="region_id" [required]="isRequired" (selectionChange)="getDistricts($event.value)">
                        <mat-option *ngFor="let region of regions" [value]="region.id">{{region.name}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="editForm.get('region_id').invalid">{{getErrorMessage()}}</mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6 space3">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Districts</mat-label>
                    <mat-select placeholder="Select District" formControlName="district_id" [required]="isRequired" (selectionChange)="getWards($event.value)">
                        <mat-option *ngFor="let district of districts" [value]="district.id">{{district.name}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="editForm.get('district_id').invalid">{{getErrorMessage()}}</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row column">
            <div class="col-md-6 space1">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Wards</mat-label>
                    <mat-select placeholder="Select Ward" formControlName="ward_id" (selectionChange)="getVillages($event.value)">
                        <mat-option *ngFor="let ward of wards" [value]="ward.id">{{ward.name}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="editForm.get('ward_id').invalid">{{getErrorMessage()}}</mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6 space3">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Villages/Streets</mat-label>
                    <mat-select placeholder="Select Village" formControlName="village_id">
                        <mat-option *ngFor="let village of villages" [value]="village.id">{{village.name}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="editForm.get('village_id').invalid">{{getErrorMessage()}}</mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="col-md-12 column">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Trader Category</mat-label>
            <mat-select placeholder="Select Category" formControlName="category" required multiple>
                <mat-option value="Buyer">Buyer</mat-option>
                <mat-option value="Seller">Seller</mat-option>
                <mat-option value="Broker">Broker</mat-option>
                <mat-option value="Warehouse Owner">Warehouse Owner</mat-option>
                <mat-option value="Warehouse Operator">Warehouse Operator</mat-option>
                <mat-option value="Market Manager">Market Manager</mat-option>
                <mat-option value="Checkpoint Manager">Checkpoint Manager</mat-option>
            </mat-select>
            <mat-error *ngIf="editForm.get('category').invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>
    </div>
    <!-- <div class="w-100 mb-1">
        <label>Image</label>
        <input class="form-control" formControlName="image" accept="image/*" #fileInput type="file" (change)="onFileChange($event)">
        <span class="file-name">{{selectedFile?.name}}</span>
        <img [src]="imageSrc" *ngIf="imageSrc" style="height: 100px; width:100px; margin-top: 10px;">
    </div> -->

    <div class="col-md-12 column">
        <mat-form-field class="w-100" appearance="fill">
            <mat-label>Date (13/1/2001)</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="date" required>
            <mat-datepicker-toggle matSuffix [for]="picker">
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="editForm.get('date').invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>
    </div>
    <!-- <div class="col-md-12 column">
        <mat-form-field class="w-100" appearance="fill">
            <mat-label>Crops</mat-label>
            <mat-select [formControl]="crops" multiple>
                <mat-option *ngFor="let crop of crops" checked=true>
                    <span *ngIf="editCrops.includes(crop.id)">{{crop.name}}</span>
                </mat-option>
            </mat-select>
            <mat-error *ngIf="editForm.get('crops').invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>
    </div> -->
    <div style="margin-top: 30px; display: flex; flex-direction: row; justify-content: flex-end;">
        <button (click)="onNoClick()" class="cancelbtn" mat-button>Cancel</button>

        <button type="submit" class="createbtn" mat-button>Update</button>
    </div>
</form>
