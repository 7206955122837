import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ManagementService } from '../../../management.service';
import { RegistrationService } from '../../registration/registration.service';
import { ProducesService } from '../produces.service';

@Component({
  selector: 'app-sell-produce',
  templateUrl: './sell-produce.component.html',
  styleUrls: ['./sell-produce.component.css']
})
export class SellProduceComponent implements OnInit {
  form: FormGroup;

  id;
  action;

  crops;
  centers;
  loading = false;
  submitting = false;
  farmer

  firstName: string = '';
  lastName: string = '';
  farmers = [];
  showModal: boolean = false;
  searching: boolean = false;

  produce;

  constructor(
    public active: ActivatedRoute,
    public produceServ: ProducesService,
    public acRegServ: RegistrationService,
    public manServ: ManagementService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private route: Router,
  ) {
    active.url.subscribe(
      data => {
        // console.log(data[2].path)
        this.id = data[2].path;

      }
    );
  }
  ngOnInit(): void {
    this.produceServ.getProduceSingleData(this.id).subscribe(
      data => {
        this.produce = data
        // console.log(data);

      }
    );

    this.form = this.fb.group({
      buyer_name: ['', Validators.required],
      buyer_phone: ['', Validators.required],
      amount: ['', Validators.required],
      id: [''],
    });
  }

  goBack() {
    this.route.navigate(['manage-ac/produces']);
  }

  onSubmit() {
    this.submitting = true;
    this.form.get('id').setValue(this.id);
    if (this.form.valid) {
      // console.log(this.form.value);
      if(this.form.get('amount').value > this.produce.amount) {
        this.snackBar.open('Error', 'Sell amount exceeds available amount.', {
          duration: 5000
        });
        this.loading = false
      } else {
        this.produceServ.sellProduce(this.form.value).subscribe(
          (resp: any) => {
            this.snackBar.open('success', resp['success'], {
              duration: 5000
            });
            this.submitting = false;
            this.route.navigate(['manage-ac/produces'])
          }
        );

      }

    }
  }
}
