import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { DeleteDataComponent } from '../../delete-data/delete-data.component';
import { ManagementService } from '../../management.service';
import { AddUomComponent } from '../add-uom/add-uom.component';

@Component({
  selector: 'app-uom',
  templateUrl: './uom.component.html',
  styleUrls: ['./uom.component.css']
})
export class UomComponent implements OnInit, AfterViewInit {

  uoms;
  isLoading = true;
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  dtCheck = false;
  constructor(
    public dialog: MatDialog,
    private manServ: ManagementService,
    public authService: AuthService
    ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.getUoms();
    this.dtTrigger.next();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 100,
      processing: true
    };
  }
  onAddUom() {
    const dialogRef = this.dialog.open(AddUomComponent, {
      width: '500px',
      height: '310px',
      data: {action: 'add' }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('closed');
      this.getUoms();
    });
  }


  editUom(id) {
    const dialogRef = this.dialog.open(AddUomComponent, {
      width: '500px',
      height: '310px',
      // tslint:disable-next-line: object-literal-shorthand
      data: {id: id, action: 'edit' }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('closed');
      this.getUoms();
    });
  }

  deleteUom(id) {
    const dialogRef = this.dialog.open(DeleteDataComponent, {
      width: '500px',
      height: '300px',
      // tslint:disable-next-line: object-literal-shorthand
      data: {id: id, type: 'uoms' }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getUoms();
    });
  }

  getUoms() {
    this.manServ.getUoms().subscribe(
      data => {
        this.uoms = data;
        this.isLoading = false
        this.rerender();
      }
    );
  }

  rerender(): void {
    if (this.dtCheck){
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
      });
    }
    else {
      this.dtCheck = true;
      this.dtTrigger.next();
    }

  }
}
