import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BuyerService } from '../buyer.service';
import { environment } from 'src/environments/environment';
import { PermitActionComponent } from '../management/permit-action/permit-action.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth/auth.service';
import { PrintPermitComponent } from '../print-permit/print-permit.component';

@Component({
  selector: 'app-application-info',
  templateUrl: './application-info.component.html',
  styleUrls: ['./application-info.component.css']
})
export class ApplicationInfoComponent implements OnInit {

  application;
  Id
  attachmentUrl = ''
  prevUrl: string;
  isLoading = true;

  constructor(
    public buyerService: BuyerService,
    public route: Router,
    public actvRoute: ActivatedRoute,
    public dialog: MatDialog,
    public authService: AuthService
  ) { 
    
  }

  ngOnInit(): void {
    this.prevUrl = this.buyerService.getPreviousUrl();
    
    this.attachmentUrl = environment.imageUrl;
    this.Id = this.actvRoute.params['value'].id;
    // console.log(this.actvRoute.params['value'].id);
    this.getDetails();
    
  }

  getDetails() {
    this.buyerService.getPermitApplicationDetails(this.Id).subscribe(
      data => {
        this.application = data
        // console.log(data);
        this.isLoading = false;
      }
    );
  }

  goBack() {
    this.route.navigate([this.prevUrl]);
    // this.route.navigate(['buyers-portal']);
  }
  
  onApprove() {
    const dialogRef = this.dialog.open(PermitActionComponent, {
      width: '500px',
      height: '340px',
      // tslint:disable-next-line: object-literal-shorthand
      data: {id: this.Id }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('closed');
      // this.goBack();
    });
  }

  openPdf() {
    const dialogRef = this.dialog.open(PrintPermitComponent, {
      width: '850px',
      height: '810px',
      data: {id: this.Id }
    });
  }
}
