import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { DeleteDataComponent } from '../../delete-data/delete-data.component';
import { ManagementService } from '../../management.service';
import { AddMarketComponent } from '../../markets/add-market/add-market.component';
import { AddPointComponent } from '../add-point/add-point.component';
import { CrossPointsService } from '../cross-points.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  dtCheck = false;
  points;
  received;
  isLoading = true;
  isChecked = true;
  constructor(
    private manServ: ManagementService,
    private dialog: MatDialog,
    public crossP: CrossPointsService,
    public authService: AuthService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.getCollectionPoints();
    this.dtTrigger.next();

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 100,
      processing: true,
      responsive: true,
      dom: 'Bfrtip',
        // buttons: [
        //     'copy', 'csv', 'excel', 'print'
        // ]
    };
  }
  getCollectionPoints() {
    this.crossP.getCollectionPoints().subscribe(
      data => {
        this.points = data;

        this.isLoading = false;

        this.rerender();
      }
    );
  }

  rerender(): void {
    if (this.dtCheck){
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
          //  $('#dtb').DataTable();
      });
    }
    else {
      this.dtCheck = true;
      this.dtTrigger.next();
    }

  }


  onAddPoint() {
    const dialogRef = this.dialog.open(AddPointComponent, {
      width: '600px',
      height: '550px',
      data: {action: 'add' }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('closed');
      // this.dtCheck = false;
      this.getCollectionPoints();
    });
  }


  editPoint(id, regionId, districtId, wardId) {
    console.log(regionId);
    const dialogRef = this.dialog.open(AddPointComponent, {
      width: '600px',
      height: '550px',
      data: {id, action: 'edit', region: regionId, district: districtId, ward: wardId }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('closed');
      this.getCollectionPoints();
    });
  }


  deletePoint(id) {
    const dialogRef = this.dialog.open(DeleteDataComponent, {
      width: '500px',
      height: '300px',
      data: {id, type: 'cross-point' }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getCollectionPoints();
    });
  }

}
