<div class="w-100">
    <div>
        <div [ngClass]="!isLoading ? 'show' : 'hide'" class="view card">
            <div class="table-responsive">
                <div class="card-header d-flex justify-content-start">
                    <button class="button" mat-button (click)="onAddMarketPrice()"><span class="material-icons notranslate">add_circle_outline</span>Add market Price</button>
                </div>
                <div class="card-body">
                    <!-- i have removed [dtTrigger]="dtTrigger" below  -->
                    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" id="dtbs" class="table p-3">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Date</th>
                                <th>Market</th>
                                <th>Crop</th>
                                <th>High Price</th>
                                <th>Low Price</th>
                                <th>Price Unit</th>
                                <th>Region</th>
                                <th>District</th>
                                <th>Posted By</th>
                                <th>Action</th>
                            </tr>

                        </thead>
                        <tbody>
                            <tr *ngFor="let price of prices;let i = index">
                                <td>{{i+1}}</td>
                                <td>{{ price.date }}</td>
                                <td>{{ price.market }}</td>
                                <td>{{ price.crop }}</td>
                                <td>{{ price.high_price }}</td>
                                <td>{{ price.low_price }}</td>
                                <td>{{ price.unit }}</td>
                                <td>{{ price['market-details']?.region?.name }}</td>
                                <td>{{ price['market-details']?.district?.name }}</td>
                                <td>{{ price.user }}</td>
                                <td style="display: flex; flex-direction: row;">
                                    <a class="text-success act" (click)="editMarketPrice(price.id, price.region_id, price.district_id)"><i class="fa fa-edit"></i></a>
                                    <mat-icon class="text-danger act" (click)="deleteMarketPrice(price.id)">delete_forever</mat-icon>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>


        <div *ngIf="isLoading" class="spinner-holder">
            <mat-spinner class="spinner"></mat-spinner>
        </div>
    </div>
</div>
