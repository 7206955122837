<div class="relative">
    <div id="content-header" class="absolute -top-24">
        <h1 class="text-uppercase font-bold">Manage Produces</h1>
    </div>
    <div [ngClass]="!isLoading ? 'show' : 'hide'">
        <div class="d-flex justify-content-start mt-1 bg-white">
            <div class="p-3">
                <button class="button" mat-button (click)="onAddProduce()"><span class="material-icons notranslate">add_circle_outline</span>Record Produce</button>
            </div>
        </div>
        <div class="col-md-12">
            <div class="view">
                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table p-3">
                    <thead>
                        <tr>
                            <th>Famer</th>
                            <th>Produce Type</th>
                            <th>Amount (Kg)</th>
                            <th>Unit Price (TSh.)</th>
                            <th>Received By</th>
                            <th width="5%">Action</th>
                        </tr>
    
                    </thead>
                    <tbody>
                        <tr *ngFor="let produce of produces">
                            <td>{{produce.farmer?.full_name}}</td>
                            <td>{{produce.crop?.name}}</td>
                            <td>{{produce.amount | number}}</td>
                            <td class="text-right">{{produce.value | number}}</td>
                            <td>{{produce.user?.full_name}}</td>
                            <td class="flex space-x-2">
                                <a class="text-success act" (click)="editProduce(produce.id)"><i class="fa fa-edit"></i></a>
                                <a class="text-sky-500 act" (click)="sellProduce(produce.id)"><i class="fa fa-shopping-cart" title="Sell Produce"></i></a>
                                <!-- <a class="text-success act" (click)="editProduce(produce.id)"><i class="fa fa-edit"></i></a> -->
                                <mat-icon *ngIf="authService.isAdmin" class="text-danger act" (click)="deleteProduce(produce.id)">delete_forever</mat-icon>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
    
        </div>
    </div>
    
    
    <div *ngIf="isLoading" class="spinner-holder">
      <mat-spinner class="spinner"></mat-spinner>
    </div>
</div>
