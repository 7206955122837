import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ManagementService } from './../../management.service';

export interface DialogData{
  id;
  action;
}

@Component({
  selector: 'app-add-crop',
  templateUrl: './add-crop.component.html',
  styleUrls: ['./add-crop.component.css']
})
export class AddCropComponent implements OnInit {

  edit;
  cropsForm = this.fb.group({
    name: ['', Validators.required],
    code: ['', Validators.required],
    crop_type: ['', ],
  });

  cropsFormEdit = this.fb.group({
    name: ['', Validators.required],
    code: ['', Validators.required],
    crop_type: ['', ],
  });

  constructor(
    public dialogRef: MatDialogRef<AddCropComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private manServ: ManagementService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    if (this.data.action === 'edit') {
      this.manServ.getSingleCrop(this.data.id).subscribe(
        res => {
          this.edit = res;
          this.cropsFormEdit.get('name').setValue(this.edit.name);
          this.cropsFormEdit.get('code').setValue(this.edit.code);
          this.cropsFormEdit.get('crop_type').setValue(this.edit.crop_type);
        }
      );
    }
  }

  getErrorMessage() {
    return 'This field is required';
  }

  onNoClick() {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.cropsForm.valid) {
      this.manServ.addCrop(this.cropsForm.value).subscribe(
        (resp: string) => {
          this.snackBar.open('', resp, {
            duration: 5000
          });
          if (resp !== 'Crop exists') {
            this.onNoClick();
          }
        }
      );
    }
  }

  onUpdate() {
    if (this.cropsFormEdit.valid) {
      this.manServ.updateCrop(this.cropsFormEdit.value, this.data.id).subscribe(
        resp => {
          this.onNoClick();
        }
      );
    }
  }
}
