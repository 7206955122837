
<div class="w-100">
  <div class="view card">
      <div class="table-responsive">
        <div class="card-header">
          Role:  {{ role.role }}
        </div>

        <div class="card-body">
          <div class="row" *ngIf="!isLoading">
            <div class="col-md-3" *ngFor="let perm of permissions;let i = index">
              <div class="form-check">
                <input class="form-check-input" *ngIf="assignedPerms(perm.id)" checked type="checkbox" value="{{perm.id}}" (change)="changePermission($event)" id="perm{{i}}">
                <input class="form-check-input" *ngIf="!assignedPerms(perm.id)" type="checkbox" value="{{perm.id}}" (change)="changePermission($event)" id="perm{{i}}">
                <label class="form-check-label" for="perm{{i}}">
                  {{ perm.name }}
                </label>
              </div>
            </div>
          </div>
          <div class="spinner-holder d-flex justify-content-center" *ngIf="isLoading">
            <mat-spinner class="spinner"></mat-spinner>
          </div>
        </div>
        <div class="modal-footer">
          <div class="d-flex justify-content-end">
            <a class="btn btn-danger mr-2" (click)="goBack()">Cancel</a>
            <button class="btn" (click)="rolePermissionUpdate()">Save</button>
          </div>
        </div>
      </div>
  </div>

</div>
