import { Component, OnInit } from '@angular/core';
import { ManagementService } from '../../management/management.service';
import { DefinitionService } from '../definition.service';
import { Chart } from 'node_modules/chart.js';
import { MatSelectChange } from '@angular/material/select';
import * as Highcharts from 'highcharts';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-movement-dashb',
  templateUrl: './movement-dashb.component.html',
  styleUrls: ['./movement-dashb.component.css']
})
export class MovementDashbComponent implements OnInit {
  regions;
  districts;
  years;
  commodities;
  infos;
  index = 0;
  cropName;
  color = '#1388F6';
  defaultRegion;
  regionName;
  flow = 'export';
  flowtitle = 'Exported';
  infoTitle = 'Destination';

  bg = ['#1388F6', '#325B15', '#F4CE0F', '#A53200', '#02A347', '#BA12CC', '#892EFF', '#A0D447', '#AA7329', '#F71900']

  // filter variables
  selectedRegion = null;
  selectedDistrict = null;
  selectedTradeFlow = 'export';
  selectedYear = null;

  Highcharts = Highcharts;
  chartOptions: {};
  constructor(
    private manServ: ManagementService,
    private defn: DefinitionService,
    private authService: AuthService,
    private router: Router,
  ) { 
    if(this.authService.isAcManager) {
      this.router.navigate(['manage-ac/summaries'])
    } 
  }

  ngOnInit(): void {
    this.getAllData();
  }

  getAllData() {
    this.allRegions();
    this.allYears();
    this.getMovement();
    // this.drawCharts();
  }


  allRegions() {
    var id = localStorage.getItem('asdsUserId');
    this.manServ.getRegions(id).subscribe(
      data => {
        this.regions = data;
        // console.log(this.regions)
      }
    );
  }

  getDistricts(regionId) {
    var id = localStorage.getItem('asdsUserId');
    this.manServ.getDistricts(regionId, id).subscribe(
      data => {
        this.districts = data;
        // console.log(this.districts)
      }
    );
  }
  getMovement() {
    this.defn.getStockMovement().subscribe(
      data => {
        this.commodities = data[0];
        this.cropName = this.commodities[0]?.name;

        this.defaultRegion = data[2].id;
        this.selectedRegion = this.defaultRegion;
        this.regionName = data[2].name;

        console.log(this.regionName);
      }
    );
  }
  getMovementInfo() {
    this.defn.getStockMovement().subscribe(
      data => {
        this.infos = data[1];
        console.log(this.infos)

      }
    );
  }

  selectYear(year) {
    this.selectedYear = year;
    this.resetColor();
    this.filter();
  }
  selectTradeFlow(flow) {
    this.selectedTradeFlow = flow;
    if (flow === 'export') {
      this.infoTitle = 'Destination';
      this.flowtitle = 'Exported';
    } else {
      this.infoTitle = 'Source';
      this.flowtitle = 'Imported';
    }
    this.resetColor();
    this.filter();
  }

  selectRegion(event: MatSelectChange) {
    const id = event.value;
    this.selectedRegion = id;
    // this.getDistricts(id);
    this.resetColor();
    this.filter();

    this.regionName = event.source.triggerValue;
  }

  selectDistrict(id) {
    this.selectedDistrict = id;
    this.resetColor();
    this.filter();
  }

  allYears() {
    this.years = this.manServ.getYears();
  }

  // onChangeCommodity(id, amount, i) {
  // this.changeInfo(id, amount);
  // this.index = i;
  // const container = document.getElementById('list');
  // const card = container.getElementsByClassName('card');

  // // console.log(card);

  // // tslint:disable-next-line: prefer-for-of
  // for (let i = 0; i < card.length; i++ ) {
  //   // tslint:disable-next-line: only-arrow-functions
  //   card[i].addEventListener('click', function() {
  //     const current = document.getElementsByClassName('active-commodity');

  //     if (current.length > 0) {
  //       current[0].className = current[0].className.replace('active-commodity', '');
  //     }

  //     this.className += ' active-commodity';
  //   });
  // }
  // }

  // changeInfo(id, amount) {
  //   this.defn.getActiveInfos(id, amount).subscribe(
  //     data => {
  //       this.infos = data;
  //     }
  //   );
  // }

  resetColor() {
    this.color = '#1388F6';
  }

  getMovementDetails(commodity, color) {
    this.cropName = commodity.name;
    this.color = color;
    // if (this.selectedRegion == null && this.selectedYear == null && this.selectedTradeFlow == null) {
    //   this.defn.getActiveInfos(commodity.id).subscribe(
    //     data => {
    //       this.infos = data;
    //       // this.drawCommodityInfo();
    //     }
    //   );
    // }
    // if (this.selectedRegion != null && this.selectedYear == null && this.selectedTradeFlow == null) {
    //   this.defn.onlyRegionCommodityInfo(commodity.id, this.selectedRegion).subscribe(
    //     data => {
    //       this.infos = data;
    //       // this.drawCommodityInfo();
    //     }
    //   );
    // }
    // if (this.selectedRegion != null && this.selectedYear != null && this.selectedTradeFlow == null) {
    //   this.defn.onlyRegionAndYearCommodityInfo(commodity.id, this.selectedRegion, this.selectedYear).subscribe(
    //     data => {
    //       this.infos = data;
    //       // this.drawCommodityInfo();
    //     }
    //   );
    // }
    if (this.selectedRegion != null && this.selectedYear == null && this.selectedTradeFlow != null) {
      this.defn.onlyRegionAndFlowCommodityInfo(commodity.id, this.selectedRegion, this.selectedTradeFlow).subscribe(
        data => {
          this.infos = data;
          // this.drawCommodityInfo();
        }
      );
    }
    if (this.selectedRegion != null && this.selectedYear != null && this.selectedTradeFlow != null) {
      this.defn.onlyRegionYearAndFlowCommodityInfo(commodity.id, this.selectedRegion, this.selectedYear, this.selectedTradeFlow).subscribe(
        data => {
          this.infos = data;
          // this.drawCommodityInfo();
        }
      );
    }
  }


  // filters
  filter() {
    // filter without involving district
    // All combinations
    if (this.selectedRegion != null && this.selectedYear != null && this.selectedTradeFlow != null) {
      this.defn.filterMovementWithExceptionOfDistrict(this.selectedRegion, this.selectedYear, this.selectedTradeFlow).subscribe(
        data => {
          this.updateValues(data);
        }
      );
    }
    // All except trade flow
    if (this.selectedRegion != null && this.selectedYear != null && this.selectedTradeFlow == null) {
      this.defn.filterMovementWithExceptionOfDistrictAndFlow(this.selectedRegion, this.selectedYear).subscribe(
        data => {
          this.updateValues(data);
        }
      );
    }
    // except year
    if (this.selectedRegion != null && this.selectedYear == null && this.selectedTradeFlow != null) {
      this.defn.filterMovementWithExceptionOfDistrictAndYear(this.selectedRegion, this.selectedTradeFlow).subscribe(
        data => {
          this.updateValues(data);
        }
      );
    }
    // except region
    if (this.selectedRegion == null && this.selectedYear != null && this.selectedTradeFlow != null) {
      this.defn.filterMovementWithExceptionOfDistrictAndRegion(this.selectedYear, this.selectedTradeFlow).subscribe(
        data => {
          this.updateValues(data);
        }
      );
    }
    // only region
    if (this.selectedRegion != null && this.selectedYear == null && this.selectedTradeFlow == null) {
      this.defn.filterMovementWithOnlyRegion(this.selectedRegion).subscribe(
        data => {
          this.updateValues(data);
        }
      );
    }
  }

  updateValues(data) {
    this.commodities = data[0];
    this.infos = data[1];
    this.cropName = this.commodities[0]?.name;

  }

}


