import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const baseUrl = environment.baseUrl;
const url1 = baseUrl + '/market-release';
const url2 = baseUrl + '/market_managers/';
const url3 = baseUrl + '/market-prices/';
const url4 = baseUrl + '/single-market-prices/';
const url5 = baseUrl + '/update-market-prices/';
const url6 = baseUrl + '/delete-market-prices/';
const url7 = baseUrl + '/record-price-in-market';
const url8 = baseUrl + '/market-summary';
@Injectable({
  providedIn: 'root'
})
export class MarketService {

  
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
     'X-Requested-Width': 'XMLHttpRequeest',
    'Access-Control-Allow-Origin': '*'
   });
  constructor(private http: HttpClient) { }

  getMarketReleases() {
    return this.http.get(url1, {headers: this.headers});
  }

  releaseGood(data) {
    return this.http.post(url1, data, {headers: this.headers});
  }
  getMarketManagers(id) {
    return this.http.get(url2 + id, {headers: this.headers});
  }
  getMarketPrices(id) {
    return this.http.get(url3 + id, {headers: this.headers});
  }
  getSingleMarketPrice(priceId) {
    return this.http.get(url4 + priceId, {headers: this.headers});
  }
  updateMarketPrice(data, id) {
    return this.http.put(url5 + id, data, {headers: this.headers});
  }
  deleteMarketPrice(id) {
    return this.http.delete(url6 + id, {headers: this.headers});
  }
  addMarketPrice(data) {
    return this.http.post(url7, data, {headers: this.headers});
  }

  getData(data) {
    return this.http.post(url8, data, {headers: this.headers});
  }
}
