<form *ngIf="data.action == 'add'" [formGroup]="roleForm" (ngSubmit)="onSubmit()">
    <div class="title-holder">
        <h2 class="title">Add new Role</h2>
    </div>
    <mat-form-field class="w-100" appearance="outline">
        <mat-label>Role Name</mat-label>
        <input matInput placeholder="Role Name" (keyup)="slugify($event)" formControlName="role" required>
        <mat-error *ngIf="roleForm.get('role').invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>
    <mat-form-field class="w-100" appearance="outline">
        <mat-label>Slug</mat-label>
        <input matInput placeholder="role slug" formControlName="slug" required>
        <mat-error *ngIf="roleForm.get('slug').invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>


    <div style="margin-top: 30px; display: flex; flex-direction: row; justify-content: flex-end;">
        <button (click)="onNoClick()" class="cancelbtn" mat-button>Cancel</button>

        <button type="submit" class="createbtn" mat-button>Submit</button>
    </div>
</form>

<!-- updating role -->
<form *ngIf="data.action == 'edit'" [formGroup]="roleFormEdit" (ngSubmit)="onUpdate()">
    <div class="title-holder">
        <h2 class="title">Update role</h2>
    </div>
    <mat-form-field class="w-100" appearance="outline">
        <mat-label>Role Name</mat-label>
        <input matInput placeholder="Role Name" (keyup)="slugify($event)" formControlName="role" required>
        <mat-error *ngIf="roleFormEdit.get('role').invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>
    <mat-form-field class="w-100" appearance="outline">
        <mat-label>Slug</mat-label>
        <input matInput placeholder="role slug" formControlName="slug" required>
        <mat-error *ngIf="roleFormEdit.get('slug').invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>


    <div style="margin-top: 30px; display: flex; flex-direction: row; justify-content: flex-end;">
        <button (click)="onNoClick()" class="cancelbtn" mat-button>Cancel</button>

        <button type="submit" class="createbtn" mat-button>Submit</button>
    </div>
</form>


<form *ngIf="data.action == 'assign'" [formGroup]="permissionForm" (ngSubmit)="onAssignPermission()">
    <div class="title-holder">
        <h2 class="title">Assign Permissions</h2>
    </div>
    <mat-form-field class="w-100" appearance="outline">
        <mat-label>Role Permissions</mat-label>
        <mat-select formControlName="permissions" multiple>
            <mat-option *ngFor="let permission of permissions" [value]="permission.id">{{permission.name}}</mat-option>
        </mat-select>
        <mat-error *ngIf="permissionForm.get('permissions').invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>

    <div style="margin-top: 30px; display: flex; flex-direction: row; justify-content: flex-end;">
        <button (click)="onNoClick()" class="cancelbtn" mat-button>Cancel</button>

        <button type="submit" class="createbtn" mat-button>Submit</button>
    </div>
</form>