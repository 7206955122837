<div class="view card" [ngClass]="!isLoading ? 'show' : 'hide'">
    <div class="table-responsive">
        <div class="card-body">
            <div class="card-header">
                <button class="button" mat-button (click)="onAddTrader('trader')"><span class="material-icons notranslate">add_circle_outline</span>Register Trader</button>
            </div>
            <div class="card-body">
                <!-- -->
                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" id="dtbs" class="table p-3">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Trader Name</th>
                            <th>Category</th>
                            <th>Phone</th>
                            <th>Email</th>
                            <th>District</th>
                            <th>Region</th>
                            <th>Country</th>
                            <th>Image</th>
                            <th>Action</th>
                        </tr>

                    </thead>
                    <tbody>
                        <tr *ngFor="let trader of traders;let i = index">
                            <td>{{i+1}}</td>
                            <td>{{trader.user?.full_name}}</td>
                            <td>{{trader.category}}</td>
                            <td>{{trader.user?.phone}}</td>
                            <td>{{trader.user?.email}}</td>
                            <td>{{trader.user?.district?.name}}</td>
                            <td>{{trader.user?.region?.name}}</td>
                            <td>{{trader.country?.country_name}}</td>
                            <td><img *ngIf="trader.image" src={{imageUrl}}{{trader.image}}  alt="" width="75" height="50" onerror="this.onerror=null;this.src='https://www.kindpng.com/picc/m/207-2074624_white-gray-circle-avatar-png-transparent-png.png';" style="background-color: transparent;"></td>
                            <td style="display: flex; flex-direction: row;">
                                <a class="text-success act" (click)="editTrader(trader.id, trader.user?.region?.id, trader.user?.district?.id, trader.user?.ward?.id, 'trader')"><i class="fa fa-edit"></i></a>
                                <mat-icon *ngIf="authService.isAdmin" class="text-danger act" (click)="deleteTrader(trader.id)">delete_forever</mat-icon>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isLoading" class="spinner-holder">
  <mat-spinner class="spinner"></mat-spinner>
</div>
