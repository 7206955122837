import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ApproveDataComponent } from '../../approve-data/approve-data.component';
import { DeleteDataComponent } from '../../delete-data/delete-data.component';
import { WarehousesService } from '../warehouses.service';
// import { ReleaseFromWarehouseComponent } from '../release-from-warehouse/release-from-warehouse.component';
import { ManagementService } from './../../management.service';
import { ReceiveinWarehouseComponent } from './../receivein-warehouse/receivein-warehouse.component';

@Component({
  selector: 'app-inwarehouse',
  templateUrl: './inwarehouse.component.html',
  styleUrls: ['./inwarehouse.component.css']
})
export class InwarehouseComponent implements OnInit, AfterViewInit {

  regions;
  traders;
  crops;
  clear = false;
  role;

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtElement2: DataTableDirective;
  dtElement3: DataTableDirective;
  // dtOptions: DataTables.Settings = {};
  dtOptions: any = {};
  dtOptions2: any = {};
  dtTrigger: Subject<any> = new Subject();
  dtTrigger2: Subject<any> = new Subject();
  dtTrigger3: Subject<any> = new Subject();
  dtCheck = false;
  dtCheck2 = false;
  dtCheck3 = false;
  received;
  new_received;
  approved_received;
  isLoading = true;
  isChecked = true;
  pageLength = 100;

  inWarehouseFilterForm = this.fb.group({
    'trader_id': [''],
    'region_id': [''],
    'crop_id': [''],
    'status': [''],
    'start': [''],
    'end': [''],
  })
  constructor(
    private manServ: ManagementService,
    private dialog: MatDialog,
    private router: Router,
    public authService: AuthService,
    public ware: WarehousesService, 
    public fb: FormBuilder,
    private dateAdapter: DateAdapter<Date>,
  ) {
    this.dateAdapter.setLocale('en-GB'); // dd/MM/yyyy
    this.role = localStorage.getItem('role').toLowerCase();
  }

  ngOnInit(): void {
    this.getTraderData();
    this.getAllRegions();
    this.getAllCrops();
  }
  ngAfterViewInit() {
    this.dtTrigger.next();
    this.dtTrigger2.next();
    this.dtTrigger3.next();
    this.getNewReceivedWarehouseData();
    this.getReceivedWarehouseData();
    this.getApprovedWarehouseData();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: this.pageLength,
      processing: true,
      responsive: true,
      dom: 'Bfrtip',
        buttons: [
            'copy', 'csv', 'excel', 'print'
        ]
    };
    this.dtOptions2 = {
      pagingType: 'full_numbers',
      pageLength: this.pageLength,
      processing: true,
      responsive: true,
      dom: 'Bfrtip',
        buttons: [
            'copy', 'csv', 'excel', 'print'
        ]
    };
  }

  getReceivedWarehouseData() {
    this.manServ.getReceivedWarehouseData().subscribe(
      data => {
        this.received = data;

        this.rerender2();
      }
    );
  }

  getNewReceivedWarehouseData() {
    this.ware.getNewInWarehouses().subscribe(
      data => {
        this.new_received = data;

        this.rerender();
      }
    );
  }
  rerender(): void {
    if (this.dtCheck){
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
      });
    }
    else {
      this.dtCheck = true;
      this.dtTrigger.next();
    }

  }

  getApprovedWarehouseData() {
    this.ware.getApprovedInWarehouses().subscribe(
      data => {
        this.approved_received = data;

        this.rerender3();
      }
    );
  }
  rerender2(): void {
    if (this.dtCheck2){
        this.dtElement2.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger2.next();
      });
    }
    else {
      this.dtCheck2 = true;
      this.dtTrigger2.next();
    }

  }
  rerender3(): void {
    if (this.dtCheck3){
        this.dtElement3.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger3.next();
      });
    }
    else {
      this.dtCheck3 = true;
      this.dtTrigger3.next();
    }

  }

  onReceiveInWarehouse() {
    const dialogRef = this.dialog.open(ReceiveinWarehouseComponent, {
      width: '710px',
      height: '700px',
      data: {action: 'add' }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('closed');
      this.getReceivedWarehouseData();
    });
  }

  editData(id, regionId, districtId, wardId, villageId) {
    const dialogRef = this.dialog.open(ReceiveinWarehouseComponent, {
      width: '710px',
      height: '700px',
      // tslint:disable-next-line: object-literal-shorthand
      data: {id: id, action: 'edit', region: regionId, district: districtId, ward: wardId, village: villageId }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('closed');
      this.getReceivedWarehouseData();
    });
  }

  deleteData(id) {
    const dialogRef = this.dialog.open(DeleteDataComponent, {
      width: '500px',
      height: '300px',
      data: {id, type: 'inwarehouse' }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getReceivedWarehouseData();
    });
  }


  approveData(id) {
    const dialogRef = this.dialog.open(ApproveDataComponent, {
      width: '500px',
      height: '300px',
      data: {id, type: 'warehouse' }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getNewReceivedWarehouseData();
      this.getApprovedWarehouseData();
    });
  }


  getTraderData() {
    this.manServ.getTraders().subscribe(
      data => {
        this.traders = data;

      }
    );
  }
  
  getAllRegions() {
    var id = localStorage.getItem('asdsUserId');
    this.manServ.getRegions(id).subscribe(
      data => {
        this.regions = data;
      }
    );
  }
  getAllCrops() {
    this.manServ.getCrops().subscribe(
      data => {
        this.crops = data;
      }
    );
  }
  // withdraw(id, warehouse, seller, crop) {
  //   const dialogRef = this.dialog.open(ReleaseFromWarehouseComponent, {
  //     width: '710px',
  //     height: '700px',
  //     // tslint:disable-next-line: object-literal-shorthand
  //     data: {id: id, warehouse_id: warehouse, trader_id: seller, crop_id: crop }
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log('closed');
  //     this.getReceivedWarehouseData();
  //   });
  // }


  viewMore(marketId) {
    this.router.navigate(['managements/warehouses/receive-commodities/' + marketId]);
  }

  applyFilter() {
    this.clear = true;
    this.ware.filterInWarehouses(this.inWarehouseFilterForm.value).subscribe(
      data => {
        this.received = data;

        this.rerender2();
      }
    )
  }

  clearForm() {
    this.inWarehouseFilterForm.reset();
    this.clear = false;
    this.getReceivedWarehouseData();
  }
}
