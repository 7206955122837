<div id="content" class="relative">
    <div class="fixed top-2/4 left-2/4 z-50 {{ loading ? '' : 'hidden' }}">
        <div class=" bg-gray-900/50 text-gray-100 p-3 rounded-md">
            <i class="fa fa-spinner fa-spin fa-2x"></i>
        </div>
    </div>

    <div id="content-header" class="absolute -top-24">
        <h1 class="text-uppercase font-bold">Warehouse Summaries</h1>
    </div>
    <div class="">
        <div class="grid md:grid-cols-4 gap-2 bg-gray-100/50 px-2 mb-2 w-full">
            <div class="">
                <mat-form-field class="" appearance="outline">
                    <mat-label>Regions</mat-label>
                    <mat-select placeholder="Select Region" formControlName="region_id" (selectionChange)="setRegion($event.value)">
                        <mat-option *ngFor="let region of regions" [value]="region.id">{{region.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3">
        <div
            class="h-[20vh] hover:-translate-y-1 duration-700 ease-in-out flex items-center justify-center bg-gray-50/50 rounded-md shadow-md border-t-4 border-gray-100">
            <div class="align-middle">
                <div class="flex justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="size-20 text-sky-700">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 0 1-2.25 2.25M16.5 7.5V18a2.25 2.25 0 0 0 2.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 0 0 2.25 2.25h13.5M6 7.5h3v3H6v-3Z" />
                    </svg>
                </div>
                <div class="text-xl font-bold flex space-x-2 items-center">
                    <div class="text-sky-800 text-2xl">{{ totalWarehouses }}</div>
                    <div>
                        Registered Warehouses
                    </div>
                </div>
            </div>
        </div>
        <div
            class="h-[20vh] hover:-translate-y-1 duration-700 ease-in-out flex items-center justify-center bg-gray-50/50 rounded-md shadow-md border-t-4 border-gray-100">
            <div class="align-middle">
                <div class="flex justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="size-20 text-sky-800">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M13.5 21v-7.5a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349M3.75 21V9.349m0 0a3.001 3.001 0 0 0 3.75-.615A2.993 2.993 0 0 0 9.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 0 0 2.25 1.016c.896 0 1.7-.393 2.25-1.015a3.001 3.001 0 0 0 3.75.614m-16.5 0a3.004 3.004 0 0 1-.621-4.72l1.189-1.19A1.5 1.5 0 0 1 5.378 3h13.243a1.5 1.5 0 0 1 1.06.44l1.19 1.189a3 3 0 0 1-.621 4.72M6.75 18h3.75a.75.75 0 0 0 .75-.75V13.5a.75.75 0 0 0-.75-.75H6.75a.75.75 0 0 0-.75.75v3.75c0 .414.336.75.75.75Z" />
                    </svg>
                </div>
                <div class="text-xl font-bold flex space-x-2 items-center">
                    <div class="text-sky-800 text-2xl">{{ totalGovt }}</div>
                    <div>
                        Government Warehouses
                    </div>
                </div>
            </div>
        </div>
        <div
            class="h-[20vh] hover:-translate-y-1 duration-700 ease-in-out flex items-center justify-center bg-gray-50/50 rounded-md shadow-md border-t-4 border-gray-100">
            <div class="align-middle">
                <div class="flex justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="size-20 text-sky-800">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
                    </svg>
                </div>
                <div class="text-xl font-bold flex space-x-2 items-center">
                    <div class="text-sky-800 text-2xl">{{ totalPrvt }}</div>
                    <div>
                        Private Warehouses
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 rounded-md mt-5">
        <div class="bg-inherit rounded-md">
            <highcharts-chart [constructorType]="chartConstructor" [Highcharts]="Highcharts" [options]="chartOptions"
                class="chart">
            </highcharts-chart>
        </div>
        <div class="bg-inherit rounded-md">
            <highcharts-chart [constructorType]="chartConstructor" [Highcharts]="Highcharts" [options]="licensedChartOptions"
                class="chart">
            </highcharts-chart>
        </div>

        <!-- <div class="">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga maiores animi aspernatur ea asperiores dolores adipisci quisquam ullam ipsa eaque non tempora possimus consequatur eligendi quasi, perspiciatis voluptatibus veniam. Praesentium!
        </div> -->
    </div>
</div>