<form *ngIf="data.action == 'add'" [formGroup]="uomForm" (ngSubmit)="onSubmit()">
  <div class="title-holder">
      <h2 class="title">Add Unit of Measure</h2>
  </div>
  <mat-form-field class="w-100" appearance="outline">
      <mat-label>Unit of Measure</mat-label>
      <input matInput placeholder="Unit of Measure" formControlName="uom" required>
      <mat-error *ngIf="uomForm.get('uom').invalid">{{getErrorMessage()}}</mat-error>
  </mat-form-field>


  <div style="margin-top: 30px; display: flex; flex-direction: row; justify-content: flex-end;">
      <button (click)="onNoClick()" class="cancelbtn" mat-button>Cancel</button>

      <button type="submit" class="createbtn" mat-button>Submit</button>
  </div>
</form>


<form *ngIf="data.action == 'edit'" [formGroup]="uomFormEdit" (ngSubmit)="onUpdate()">
  <div class="title-holder">
      <h2 class="title">Edit Unit of Measure</h2>
  </div>
  <mat-form-field class="w-100" appearance="outline">
      <mat-label>Unit of Measure</mat-label>
      <input matInput placeholder="Unit of Measure" formControlName="uom" required>
      <mat-error *ngIf="uomFormEdit.get('uom').invalid">{{getErrorMessage()}}</mat-error>
  </mat-form-field>


  <div style="margin-top: 30px; display: flex; flex-direction: row; justify-content: flex-end;">
      <button (click)="onNoClick()" class="cancelbtn" mat-button>Cancel</button>

      <button type="submit" class="createbtn" mat-button>Update</button>
  </div>
</form>
