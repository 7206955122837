import { Component, OnInit, Inject } from '@angular/core';
import { Validators, FormBuilder, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ManagementService } from './../../management.service';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface DialogData{
  id;
  action;
}

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {
  regions;
  districts;
  wards;
  villages;
  edit;
  roles;
  warehouses;
  markets;

  isWarehouse = false;
  isMarket = false;
  district;
  imageSrc: string;
  selectedImage;

  userForm = this.fb.group({
    full_name: ['', Validators.required],
    // email: ['', [Validators.required, Validators.pattern(/.+@.+\..+/)]],
    email: [''],
    phone: ['', Validators.required],
    username: ['', Validators.required],
    password: ['', Validators.required],
    role_id: ['', Validators.required],
    region_id: ['', Validators.required],
    district_id: ['', Validators.required],
    ward_id: [''],
    village_id: [''],
    warehouse_id: [''],
    market_id: [''],
    image: [''],
    // fileSource: new FormControl('', [Validators.required])
  });

  userFormEdit = this.fb.group({
    full_name: ['', Validators.required],
    // email: ['', [Validators.required, Validators.pattern(/.+@.+\..+/)]],
    email: [''],
    phone: ['', Validators.required],
    username: ['', Validators.required],
    role_id: ['', Validators.required],
    region_id: ['', Validators.required],
    district_id: ['', Validators.required],
    ward_id: [''],
    village_id: [''],
    status: ['', Validators.required],
    warehouse_id: [''],
    market_id: [''],
    image: FormControl[''],
  });

  userFormEditPassword = this.fb.group({
    password: ['', Validators.required],
  });

  constructor(
    public dialogRef: MatDialogRef<AddUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private manServ: ManagementService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.getAllRegions();
    this.getRoles();
    if (this.data.action === 'edit') {
      this.manServ.getSingleUser(this.data.id).subscribe(
        res => {
          this.edit = res;

          this.getDistricts(this.edit.region_id);
          this.getWards(this.edit.district_id);
          this.getVillages(this.edit.ward_id);
          this.userFormEdit.get('full_name').setValue(this.edit.full_name);
          this.userFormEdit.get('email').setValue(this.edit.email);
          this.userFormEdit.get('phone').setValue(this.edit.phone);
          this.userFormEdit.get('username').setValue(this.edit.username);
          this.userFormEdit.get('role_id').setValue(this.edit.role_id);
          this.userFormEdit.get('region_id').setValue(this.edit.region_id);
          this.userFormEdit.get('district_id').setValue(this.edit.district_id);
          this.userFormEdit.get('ward_id').setValue(this.edit.ward_id);
          this.userFormEdit.get('village_id').setValue(this.edit.village_id);
          this.userFormEdit.get('market_id').setValue(this.edit.market_id);
          this.userFormEdit.get('warehouse_id').setValue(this.edit.warehouse_id);
          this.userFormEdit.get('status').setValue(this.edit.status);

          if(this.edit.role_id == 5) {
            this.isWarehouse = true;
          } else if(this.edit.role_id == 6) {
            this.isMarket = true;
          }
        }
      );
    }
  }

  getAllRegions() {
    var id = localStorage.getItem('asdsUserId');
    this.manServ.getRegions(id).subscribe(
      data => {
        this.regions = data;
      }
    );
  }

  getDistricts(regionId) {
    var id = localStorage.getItem('asdsUserId');
    this.manServ.getDistricts(regionId, id).subscribe(
      data => {
        this.districts = data;
      }
    );
  }

  getWards(districtId) {
    this.district = districtId;
    this.locationMarketWarehouse(districtId);
    this.manServ.getWards(districtId).subscribe(
      data => {
        this.wards = data;
      }
    );
  }

  getVillages(wardId) {
    this.manServ.getVillages(wardId).subscribe(
      data => {
        this.villages = data;
      }
    );
  }


  getErrorMessage() {
    return 'This field is required';
  }

  getEmailErrorMessage() {
    return 'email pattern should be a@b.c';
  }

  getRoles() {
    this.manServ.getRoles().subscribe(
      data => {
        this.roles = data;
      }
    );
  }

  onNoClick() {
    this.dialogRef.close();
    this.selectedImage = null;
  }

  onSubmit() {
    if (this.userForm.valid) {
      // console.log(this.userForm.value);
      var formData: any = new FormData();
      // formData.append(this.userForm.value)
      formData.append('full_name', this.userForm.controls['full_name'].value)
      formData.append('email', this.userForm.controls['email'].value)
      formData.append('phone', this.userForm.controls['phone'].value)
      formData.append('username', this.userForm.controls['username'].value)
      formData.append('password', this.userForm.controls['password'].value)
      formData.append('role_id', this.userForm.controls['role_id'].value)
      formData.append('region_id', this.userForm.controls['region_id'].value)
      formData.append('district_id', this.userForm.controls['district_id'].value)
      formData.append('ward_id', this.userForm.controls['ward_id'].value)
      formData.append('village_id', this.userForm.controls['village_id'].value)
      formData.append('warehouse_id', this.userForm.controls['warehouse_id'].value)
      formData.append('market_id', this.userForm.controls['market_id'].value)
      formData.append('image', this.selectedImage)
      
      this.manServ.registerUser(formData).subscribe(
        (resp: string) => {
          console.log(resp)
          this.snackBar.open('', resp, {
            duration: 5000
          });
          if (resp !== 'User exists') {
            this.onNoClick();
          }
          
        }
      );
    }
  }
  onUpdate() {
    if (this.userFormEdit.valid) {
      var formData:FormData = new FormData();
      // formData.append(this.userFormEdit.value)
      // alert(this.userFormEdit.controls['full_name'].value)
      formData.append('full_name', this.userFormEdit.controls['full_name'].value)
      formData.append('email', this.userFormEdit.controls['email'].value)
      formData.append('phone', this.userFormEdit.controls['phone'].value)
      formData.append('username', this.userFormEdit.controls['username'].value)
      formData.append('role_id', this.userFormEdit.controls['role_id'].value)
      formData.append('region_id', this.userFormEdit.controls['region_id'].value)
      formData.append('district_id', this.userFormEdit.controls['district_id'].value)
      formData.append('ward_id', this.userFormEdit.controls['ward_id'].value)
      formData.append('village_id', this.userFormEdit.controls['village_id'].value)
      formData.append('warehouse_id', this.userFormEdit.controls['warehouse_id'].value)
      formData.append('market_id', this.userFormEdit.controls['market_id'].value)
      formData.append('status', this.userFormEdit.controls['status'].value)
      formData.append('image', this.selectedImage)
      formData.append('_method', 'PUT')

      this.manServ.updateUser(formData, this.data.id).subscribe(
        (resp: string) => {
          this.snackBar.open('', resp, {
            duration: 5000
          });
          if (resp !== 'User exists') {
            this.onNoClick();
          }
        }
      );
    }
  }

  onUpdatePassword() {
    if (this.userFormEditPassword.valid) {
      this.manServ.updateUserPassword(this.userFormEditPassword.value, this.data.id).subscribe(
        (resp: string) => {
          this.snackBar.open('', resp, {
            duration: 5000
          });
          if (resp !== 'User exists') {
            this.onNoClick();
          }
        }
      );
    }
  }

  locationMarketWarehouse(district) {
    this.manServ.availableAreaWarehouses(this.district).subscribe(
      data => {
        this.warehouses = data;
        // if (data.toString() === 'nothing') {
        //   this.snackBar.open('', 'No warehouse found', {
        //     duration: 5000
        //   });
        // }
      }
    );

    this.manServ.allAvailableAreaMarkets(this.district).subscribe(
      data => {
        this.markets = data;
        // if (data.toString() === 'nothing') {
        //   this.snackBar.open('', 'No market found', {
        //     duration: 5000
        //   });
        // }
      }
    );
  }
  selectedRole(event) {
    let slug;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.roles.length; i++) {
      if (this.roles[i].id === event.value) {
        slug = this.roles[i].slug;
      }
    }

    if (slug === 'warehouse-manager') {
      this.isMarket = false;
      this.isWarehouse = true;
      this.userForm.get('warehouse_id').setValidators(Validators.required);
      this.userForm.get('market_id').clearValidators();
      this.userForm.get('market_id').setValue('');

    }
    else if (slug === 'market-manager') {
      this.isWarehouse = false;
      this.isMarket = true;
      this.userForm.get('market_id').setValidators(Validators.required);
      this.userForm.get('warehouse_id').clearValidators();
      this.userForm.get('warehouse_id').setValue('');

    }
    else {
      this.isMarket = false;
      this.isWarehouse = false;

      this.userForm.get('warehouse_id').clearValidators();
      this.userForm.get('market_id').clearValidators();
      this.userForm.get('warehouse_id').setValue('');
      this.userForm.get('market_id').setValue('');
    }

  }


  selectedRoleUpdate(event) {
    let slug;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.roles.length; i++) {
      if (this.roles[i].id === event.value) {
        slug = this.roles[i].slug;
      }
    }

    if (slug === 'warehouse-manager') {
      this.isMarket = false;
      this.isWarehouse = true;
      this.userFormEdit.get('warehouse_id').setValidators(Validators.required);
      this.userFormEdit.get('market_id').clearValidators();
      this.userFormEdit.get('market_id').setValue('');

    }
    else if (slug === 'market-manager') {
      this.isWarehouse = false;
      this.isMarket = true;
      this.userFormEdit.get('market_id').setValidators(Validators.required);
      this.userFormEdit.get('warehouse_id').clearValidators();
      this.userFormEdit.get('warehouse_id').setValue('');

    }
    else {
      this.isMarket = false;
      this.isWarehouse = false;

      this.userFormEdit.get('warehouse_id').clearValidators();
      this.userFormEdit.get('market_id').clearValidators();
      this.userFormEdit.get('warehouse_id').setValue('');
      this.userFormEdit.get('market_id').setValue('');
    }

  }

  onFileChange(event) {
    const reader = new FileReader();
    
    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
    
      reader.onload = () => {
   
        this.imageSrc = reader.result as string;
     
        // this.userForm.patchValue({
        //   fileSource: reader.result,
        // });

        this.selectedImage = event.srcElement.files[0];
      };
   
    }
  }
}
