import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MarketService } from '../../markets/market.service';
import { ManagementService } from './../../management.service';

export interface DialogData {
  action;
  id;
  region;
  district;
  country;
  origin;
}
@Component({
  selector: 'app-add-withdraw',
  templateUrl: './add-withdraw.component.html',
  styleUrls: ['./add-withdraw.component.css']
})
export class AddWithdrawComponent implements OnInit {
  countries;
  regions;
  districts;
  warehouses;
  primaryMarkets;
  secondaryMarkets;
  crops;
  buyers;
  edit;
  units;

  isProcessed = false;
  // showWarehouse1 = false;
  // showMarket1 = false;
  // decreaseSize1 = false;
  showWarehouse = false;
  showMarket = false;
  decreaseSize = false;
  isRequired = false;
  isDomestic = true;
  checked_first = 'mat-radio-checked'

  withdrawForm = this.fb.group({
    crop_id : ['', Validators.required],
    quality : ['', ],
    quantity : ['', Validators.required],
    buying_price : ['', ],
    processed : ['', Validators.required],
    after_processed : [''],
    district_id : [''],
    warehouse_id : [''],
    market_id : [''],
    origin_warehouse : [''],
    origin_market : [''],
    region_id : [''],
    country_id : [''],
    cess_payment : [''],
    cess_unit : [''],
    price_unit : [''],
    usage : ['', Validators.required],
    trader_id : ['', Validators.required],
    date : ['', Validators.required],
  });

  editForm = this.fb.group({
    crop_id : ['', Validators.required],
    quality : ['', ],
    quantity : ['', Validators.required],
    buying_price : ['', ],
    processed : ['', Validators.required],
    after_processed : [''],
    district_id : [''],
    warehouse_id : [''],
    market_id : [''],
    origin_warehouse : [''],
    origin_market : [''],
    region_id : [''],
    country_id : [''],
    cess_payment : [''],
    cess_unit : [''],
    price_unit : [''],
    usage : ['', Validators.required],
    trader_id : ['', Validators.required],
    date : ['', Validators.required],
  });
  constructor(
    public dialogRef: MatDialogRef<AddWithdrawComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private manServ: ManagementService,
    private marketServ: MarketService,
    private snackBar: MatSnackBar,
    private dateAdapter: DateAdapter<Date>,
  ) {
    this.dateAdapter.setLocale('en-GB'); // dd/MM/yyyy
  }

  ngOnInit(): void {

    this.withdrawForm.get('date').setValue(new Date());

    this.getUnits();
    this.getCountries();
    this.getAllRegions();
    this.getCrops();
    this.getWarehouses();
    this.getMarkets();
    this.getBuyerData();
    if (this.data.action === 'edit') {

      this.getDistricts(this.data.region);

      this.manServ.getWithdrawedSingleData(this.data.id).subscribe(
        resp => {
          this.edit = resp;
          this.editForm.get('crop_id').setValue(this.edit.crop_id);
          this.editForm.get('quality').setValue(this.edit.quality);
          this.editForm.get('quantity').setValue(this.edit.quantity);
          this.editForm.get('buying_price').setValue(this.edit.buying_price);
          this.editForm.get('processed').setValue(this.edit.processed);
          this.editForm.get('after_processed').setValue(this.edit.after_processed);
          this.editForm.get('district_id').setValue(this.data.district);
          this.editForm.get('warehouse_id').setValue(this.edit.warehouse_id);
          this.editForm.get('market_id').setValue(this.edit.market_id);
          this.editForm.get('region_id').setValue(this.data.region);
          this.editForm.get('country_id').setValue(this.data.country);
          this.editForm.get('cess_payment').setValue(this.edit.cess_payment);
          this.editForm.get('usage').setValue(this.edit.usage);
          this.editForm.get('trader_id').setValue(this.edit.trader_id);
          this.editForm.get('date').setValue(this.edit.date);
          this.editForm.get('price_unit').setValue(this.edit.price_unit);
          this.editForm.get('cess_unit').setValue(this.edit.cess_unit);

          if (this.edit.processed === 'yes') {
            this.isProcessed = true;
          }
          if (this.edit.country_id === 223) {
            this.isRequired = true;
          }

          if (this.edit.usage === 'warehouse') {
            this.showWarehouse = true;
          }

          if (this.edit.usage === 'market') {
            this.showMarket = true;
          }
        }
      );

    }
  }


  //
  getUnits() {
    this.manServ.getUoms().subscribe(
      data => {
        this.units = data;

      }
    );
  }

  getCountries() {
    this.manServ.getCountries().subscribe(
      data => {
        this.countries = data;
      }
    );
  }
  getAllRegions() {
    var id = localStorage.getItem('asdsUserId');
    this.manServ.getRegions(id).subscribe(
      data => {
        this.regions = data;
      }
    );
  }

  getDistricts(regionId) {
    var id = localStorage.getItem('asdsUserId');
    this.manServ.getDistricts(regionId, id).subscribe(
      data => {
        this.districts = data;
      }
    );
  }

  getCrops() {
    this.manServ.getCrops().subscribe(
      data => {
        this.crops = data;
      }
    );
  }
  getWarehouses() {
    this.manServ.getMyWarehouseData(localStorage.getItem('api_token')).subscribe(
      data => {
        this.warehouses = data;
      }
    );
  }

  getMarkets() {
    this.manServ.getMarketData().subscribe(
      data => {
        this.primaryMarkets = data[1];
        this.secondaryMarkets = data[0];
      }
    );
  }


  getBuyerData() {
    this.manServ.getCategoryTraders('buyer').subscribe(
      data => {
        this.buyers = data;
        // console.log(this.buyers)
      }
    );
  }
  showDestination(event) {
    console.log(event)
    if(event.value == 1) {
      this.isDomestic = true
      this.withdrawForm.get('region_id').setValidators(Validators.required);
      this.withdrawForm.get('district_id').setValidators(Validators.required);

      this.withdrawForm.get('country_id').clearValidators();

      this.withdrawForm.get('country_id').setValue('');
    } else {
      this.isDomestic = false
      this.withdrawForm.get('region_id').clearValidators();
      this.withdrawForm.get('district_id').clearValidators();

      this.withdrawForm.get('region_id').setValue('');
      this.withdrawForm.get('district_id').setValue('');
      this.withdrawForm.get('country_id').setValidators(Validators.required);
    }
  }

  changeValidation(countryId) {
    // if (countryId === 223) {
    //   this.isRequired = true;
    //   if (this.data.action === 'add') {
    //     this.withdrawForm.get('region_id').setValidators(Validators.required);
    //     this.withdrawForm.get('district_id').setValidators(Validators.required);
    //   }else {
    //     this.editForm.get('region_id').setValidators(Validators.required);
    //     this.editForm.get('district_id').setValidators(Validators.required);
    //   }

    // }
    // else {
    //   this.isRequired = false;

    //   if (this.data.action === 'add') {
    //     this.withdrawForm.get('region_id').clearValidators();
    //     this.withdrawForm.get('district_id').clearValidators();

    //     this.withdrawForm.get('region_id').setValue('');
    //     this.withdrawForm.get('district_id').setValue('');
    //   }else {
    //     this.editForm.get('region_id').clearValidators();
    //     this.editForm.get('district_id').clearValidators();

    //     this.editForm.get('region_id').setValue('');
    //     this.editForm.get('district_id').setValue('');
    //   }
    // }
  }

  cropProcessed(value) {
    if (value === 'yes') {
      this.isProcessed = true;
      if (this.data.action === 'add') {
        this.withdrawForm.get('after_processed').setValidators(Validators.required);
      }else {
        this.editForm.get('after_processed').setValidators(Validators.required);
      }
    }
    else {
      this.isProcessed = false;

      if (this.data.action === 'add') {
        this.withdrawForm.get('after_processed').clearValidators();

        this.withdrawForm.get('after_processed').setValue('');
      }else {
        this.editForm.get('after_processed').clearValidators();

        this.editForm.get('after_processed').setValue('');
      }
    }
  }

  checkDestination(value) {
    if (value === 'warehouse') {
      this.showMarket = false;
      this.showWarehouse = true;
      this.decreaseSize = true;

      if (this.data.action === 'add') {
        this.withdrawForm.get('warehouse_id').setValidators(Validators.required);
        this.withdrawForm.get('market_id').clearValidators();

        this.withdrawForm.get('market_id').setValue('');
      }
      else {
        this.editForm.get('warehouse_id').setValidators(Validators.required);
        this.editForm.get('market_id').clearValidators();

        this.editForm.get('market_id').setValue('');
      }
    }
    if (value === 'market') {
      this.showWarehouse = false;
      this.showMarket = true;
      this.decreaseSize = true;

      if (this.data.action === 'add') {
        this.withdrawForm.get('market_id').setValidators(Validators.required);
        this.withdrawForm.get('warehouse_id').clearValidators();

        this.withdrawForm.get('warehouse_id').setValue('');
      }
      else {
        this.editForm.get('market_id').setValidators(Validators.required);
        this.editForm.get('warehouse_id').clearValidators();

        this.editForm.get('warehouse_id').setValue('');
      }
    }
    if (value !== 'warehouse' && value !== 'market') {
      this.showWarehouse = false;
      this.showMarket = false;
      this.decreaseSize = false;

      if (this.data.action === 'add') {
        this.withdrawForm.get('market_id').clearValidators();
        this.withdrawForm.get('warehouse_id').clearValidators();

        this.withdrawForm.get('warehouse_id').setValue('');
        this.withdrawForm.get('market_id').setValue('');
      }
      else {
        this.editForm.get('market_id').clearValidators();
        this.editForm.get('warehouse_id').clearValidators();

        this.editForm.get('warehouse_id').setValue('');
        this.editForm.get('market_id').setValue('');
      }
    }
  }
  getErrorMessage() {
    return 'This field is required';
  }

  onNoClick() {
    this.dialogRef.close();
  }


  onSubmit() {
    console.log(this.withdrawForm.valid)
    if (this.withdrawForm.valid) {
      const formData = {
        ...this.withdrawForm.value,
        'user_id': localStorage.getItem('asdsUserId')
      };
      // if(this.data.origin == 'market') {
      //   this.marketServ.releaseGood(formData).subscribe(
      //     (resp: string) => {
      //       this.snackBar.open('success', resp['resp'], {
      //         duration: 5000
      //       });
      //       this.onNoClick();
            
      //     }
      //   );

      // } else {
        this.manServ.addWithdrawedGood(formData).subscribe(
          (resp: string) => {
            if (resp === 'failed') {
              this.snackBar.open('error', 'the amount withdrawed exceeds the available amount', {
                duration: 5000
              });
            }
            else {
              if (resp['resp'] === 'failed') {
                this.snackBar.open('success', 'Quantity exceeded the available amount', {
                  duration: 5000
                });
              } else {
                this.snackBar.open('success', resp['resp'], {
                  duration: 5000
                });
                this.onNoClick();
              }
            }
          }
        );
      // }
    }
  }

  onUpdate() {
    if (this.editForm.valid) {
      this.manServ.updateWithdrawedData(this.editForm.value, this.data.id).subscribe(
        (resp: string) => {
          this.snackBar.open('success', resp, {
            duration: 5000
          });
          this.onNoClick();
        }
      );
    }
  }
}
