// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  // baseUrl : 'http://stocks.multics.co.tz/public/api',

  // baseUrl : 'https://asds.kilimo.go.tz/public/api',
  appUrl: 'https://asds.kilimo.go.tz/',
  loginUrl : 'https://asds.kilimo.go.tz/login',


  imageUrl : 'https://iks.kilimo.go.tz/csdynamics/backend/public',
  indexUrl : 'https://iks.kilimo.go.tz/csdynamics/backend',
  baseUrl : 'https://iks.kilimo.go.tz/csdynamics/backend/public/api',
  // appUrl: 'https://iks.kilimo.go.tz/csdynamics/frontend/',
  // loginUrl : 'https://iks.kilimo.go.tz/mkilimo/auth/login',

  // imageUrl : 'http://127.0.0.1:8000',
  // indexUrl : 'http://127.0.0.1:8000',
  // baseUrl : 'http://127.0.0.1:8000/api',
  // appUrl: 'http://localhost:4200/',
  // loginUrl: 'http://localhost:4200/login'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
