import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ManagementService } from 'src/app/modules/management/management.service';
import { environment } from 'src/environments/environment';
import { BuyerService } from '../../buyer.service';

@Component({
  selector: 'app-rejected',
  templateUrl: './rejected.component.html',
  styleUrls: ['./rejected.component.css']
})
export class RejectedComponent implements OnInit {

  isLoading = true;
  data;
  imageUrl = environment.imageUrl;

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();
  dtCheck = false;
  constructor(
    private dialog: MatDialog,
    private manServ: ManagementService,
    public authService: AuthService,
    public buyerService: BuyerService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.getVerifiedPermits();
  }
  
  ngAfterViewInit() {
    this.dtTrigger.next();

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 100,
      processing: true,
      responsive: true,
      // dom: 'Bfrtip',
      // buttons: [
      //     'copy', 'csv', 'excel', 'print'
      // ]
    };

  }

  getVerifiedPermits() {
    this.buyerService.getPermitByStatus('rejected').subscribe(
      data => {
        this.data = data;
        this.isLoading = false;
        console.log(data);
        
        this.rerender();
      }
    );
  }
  rerender(): void {
    if (this.dtCheck){
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
          //  $('#dtb').DataTable();
      });
    }
    else {
      this.dtCheck = true;
      this.dtTrigger.next();
    }

  }
  

}
