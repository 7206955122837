<div id="content" class="relative">

    <div id="content-header" class="absolute -top-24">
        <h1 class="text-uppercase font-bold">Buyer Applications Report</h1>
    </div>


    <div class="view card" [ngClass]="!isLoading ? 'show' : 'hide'">
        <div class="table-responsive">
            <div class="card-body">
                <div class="card-body">
                    <div class="grid grid-cols-4 gap-2">
                        <mat-form-field class="w-100 px-1" appearance="outline">
                            <mat-label>Application Status</mat-label>
                            <mat-select placeholder="Select"
                                formControlName="market_category" required (selectionChange)="changeStatus($event.value)">
                                <mat-option value="all">All</mat-option>
                                <mat-option value="pending">Pending</mat-option>
                                <mat-option value="approved">Verified</mat-option>
                                <mat-option value="returned">Returned</mat-option>
                                <mat-option value="rejected">Rejected</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <!-- -->
                    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" id="dtbs" class="table p-3">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Date</th>
                                <th>Name</th>
                                <th>Category</th>
                                <th>Type</th>
                                <th>Market</th>
                                <th>Country</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>

                        </thead>
                        <tbody>
                            <tr *ngFor="let data of data;let i = index">
                                <td>{{i+1}}</td>
                                <td>{{ data.created_at.split('T')[0] }}</td>
                                <td>{{ data.trader?.full_name }}</td>
                                <td>{{ data.trader?.buyer_category }}</td>
                                <td>{{ data.trader?.type }}</td>
                                <td>{{ data.market_category?.replace('_', ' ') }}</td>
                                <td>{{ data.country }}</td>
                                <td><span class="">{{ data.status }}</span></td>
                                <td class="flex space-x-2 items-center">
                                    <!-- <a class="text-success act" (click)="editTrader(data.id, data.user?.region?.id, data.user?.district?.id, data.user?.ward?.id, 'data')"><i class="fa fa-edit"></i></a> -->
                                    <a routerLink="/buyers-portal/application-info/{{ data.id }}"
                                        class="text-blue-500 bg-gray-200 px-2 py-1.5 rounded-md"><i
                                            class="fa fa-eye"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="isLoading" class="spinner-holder">
        <mat-spinner class="spinner"></mat-spinner>
    </div>
</div>