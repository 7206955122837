import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ManagementService } from './../../management.service';
import { WarehousesService } from '../warehouses.service';

export interface DialogData {
  id;
  action;
  region;
  district;
  ward;
}

@Component({
  selector: 'app-add-warehouse',
  templateUrl: './add-warehouse.component.html',
  styleUrls: ['./add-warehouse.component.css']
})
export class AddWarehouseComponent implements OnInit {
  regions;
  districts;
  wards;
  villages;
  edit;
  owners;
  operators;

  isPrivate = false;

  warehouseForm = this.fb.group({
    name : ['', Validators.required],
    capacity : ['', Validators.required],
    registered : [''],
    licensed_by : [''],
    grade : [''],
    ownership : ['', Validators.required],
    owner : [''],
    operator : [''],
    village_id : [''],
    ward_id : [''],
    district_id : ['', Validators.required],
    region_id : ['', Validators.required],
    type : ['', Validators.required],
    latitude : [''],
    longitude : [''],
  });

  editForm = this.fb.group({
    name : ['', Validators.required],
    capacity : ['', Validators.required],
    registered : [''],
    licensed_by : [''],
    grade : [''],
    ownership : ['', Validators.required],
    owner : [''],
    operator : [''],
    village_id : [''],
    ward_id : [''],
    district_id : ['', Validators.required],
    region_id : ['', Validators.required],
    type : ['', Validators.required],
    latitude : [''],
    longitude : [''],
  });
  constructor(
    public dialogRef: MatDialogRef<AddWarehouseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private manServ: ManagementService,
    private wareServ: WarehousesService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.getAllRegions();
    // this.getOwners();
    this.getOperators();
    if (this.data.action === 'edit') {

      this.getDistricts(this.data.region);
      this.getWards(this.data.district);
      this.getVillages(this.data.ward);

      this.manServ.getWarehouseSingleData(this.data.id).subscribe(
        resp => {
          this.edit = resp;
          this.editForm.get('name').setValue(this.edit.name);
          this.editForm.get('grade').setValue(this.edit.grade);
          this.editForm.get('capacity').setValue(this.edit.capacity);
          this.editForm.get('registered').setValue(this.edit.registered);
          this.editForm.get('licensed_by').setValue(this.edit.licensed_by);
          this.editForm.get('ownership').setValue(this.edit.ownership);
          this.editForm.get('latitude').setValue(this.edit.lat);
          this.editForm.get('longitude').setValue(this.edit.long);
          this.editForm.get('region_id').setValue(this.data.region);
          this.editForm.get('district_id').setValue(this.data.district);
          this.editForm.get('ward_id').setValue(this.data.ward);
          this.editForm.get('village_id').setValue(this.edit.village_id);
          this.editForm.get('type').setValue(this.edit.type);
          this.editForm.get('owner').setValue(Number(this.edit.owner));
          this.editForm.get('operator').setValue(Number(this.edit.operator));
        }
        );
        
    }
  }

  getAllRegions() {
    var id = localStorage.getItem('asdsUserId');
    this.manServ.getRegions(id).subscribe(
      data => {
        this.regions = data;
      }
    );
  }

  getDistricts(regionId) {
    var id = localStorage.getItem('asdsUserId');
    this.manServ.getDistricts(regionId, id).subscribe(
      data => {
        this.districts = data;
      }
    );
  }

  getWards(districtId) {
    this.manServ.getWards(districtId).subscribe(
      data => {
        this.wards = data;
      }
    );
  }

  getVillages(wardId) {
    this.manServ.getVillages(wardId).subscribe(
      data => {
        this.villages = data;
      }
    );
  }


  getOwners(value) {
    if(value == 'Government') {
      this.isPrivate = false;
    } else {
      this.isPrivate = true;
      var id = localStorage.getItem('asdsUserId');
      this.wareServ.getWarehouseOwners(id).subscribe(
        data => {
          this.owners = data;
        }
      );
    }
  }

  getOperators() {
    var id = localStorage.getItem('asdsUserId');
    this.wareServ.getWarehouseOperators(id).subscribe(
      data => {
        this.operators = data;
      }
    );
  }

  getErrorMessage() {
    return 'This field is required';
  }

  onNoClick() {
    this.dialogRef.close();
  }
  onSubmit() {
    if (this.warehouseForm.valid) {
      const formData = {
        ...this.warehouseForm.value,
        'user_id': localStorage.getItem('asdsUserId')
      };
      // console.log(formData);
      
      this.manServ.addWarehouseData(formData).subscribe(
        (resp: string) => {
          this.snackBar.open('', resp['resp'], {
            duration: 5000
          });
          this.onNoClick();
        }
      );
    }
  }


  onUpdate() {
    if (this.editForm.valid) {
      this.manServ.updateWarehouseData(this.editForm.value, this.data.id).subscribe(
        (data: string) => {
          this.snackBar.open('', data, {
            duration: 5000
          });
          this.onNoClick();
        },
        error => {
          console.log(error);
        }
      );
    }
  }
}
