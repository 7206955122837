import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ManagementService } from '../../management.service';

export interface DialogData{
  id;
  action;
}

@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.css']
})
export class AddRoleComponent implements OnInit {

  edit;
  permissions;
  roleForm = this.fb.group({
    role: ['', Validators.required],
    slug: ['', Validators.required],
  });

  roleFormEdit = this.fb.group({
    role: ['', Validators.required],
    slug: ['', Validators.required],
  });

  permissionForm = this.fb.group({
    permissions: ['', Validators.required],
  });

  constructor(
    public dialogRef: MatDialogRef<AddRoleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private manServ: ManagementService,
    private snackBar: MatSnackBar
  ) { }
  ngOnInit(): void {
    if (this.data.action === 'edit') {
      this.manServ.getSingleRole(this.data.id).subscribe(
        res => {
          this.edit = res;
          this.roleFormEdit.get('role').setValue(this.edit.role);
          this.roleFormEdit.get('slug').setValue(this.edit.slug);
        }
      );
    }
    if (this.data.action === 'assign') {
      this.manServ.getPermissions().subscribe(
        res => {
          this.permissions = res;
          this.manServ.getSingleRole(this.data.id).subscribe(
            res => {
              // console.log(res)
              this.permissionForm.get('permissions').setValue(res['role_permissions']);
            }
          );
        }
      );
    }
  }

  getErrorMessage() {
    return 'This field is required';
  }

  slugify(event) {
    const role = event.target.value;
    const str = role.replace(/\s+/g, '-').toLowerCase();
    this.roleForm.get('slug').setValue(str);
  }

  onNoClick() {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.roleForm.valid) {
      this.manServ.addRole(this.roleForm.value).subscribe(
        (resp: string) => {
          this.snackBar.open('', resp, {
            duration: 5000
          });
          if (resp !== 'role exists') {
            this.onNoClick();
          }
        }
      );
    }
  }

  onUpdate() {
    if (this.roleFormEdit.valid) {
      this.manServ.updateRole(this.roleFormEdit.value, this.data.id).subscribe(
        (resp: string) => {
          this.snackBar.open('', resp, {
            duration: 5000
          });
          if (resp !== 'role exists') {
            this.onNoClick();
          }
        }
      );
    }
  }

  onAssignPermission() {
    if (this.permissionForm.valid) {
      this.manServ.assignPermission(this.permissionForm.value, this.data.id).subscribe(
        (resp: string) => {
          this.snackBar.open('', resp, {
            duration: 5000
          });
          this.onNoClick();
        }
      );
    }
  }
}
