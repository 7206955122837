<div *ngIf="!isLoading">
    <p style="text-align: center;">
        <mat-icon style="font-size: 80px;height:80px;width:80px;font-weight: lighter;text-align: center;color: #a81f1f">help_outline</mat-icon>
    </p>
    <!-- <p *ngIf="this.data.type === 'warehouse'" style="text-align: center;font-size: 25px;color: #383838;">Are you sure you want to delete this warehouse?</p>
    <p *ngIf="this.data.type === 'inwarehouse' || data.type === 'inmarket' || data.type === 'withdraw'" style="text-align: center;font-size: 25px;color: #383838;">Are you sure you want to delete this data?</p>
    <p *ngIf="this.data.type === 'market'" style="text-align: center;font-size: 25px;color: #383838;">Are you sure you want to delete this market?</p>
    <p *ngIf="this.data.type === 'marketprice'" style="text-align: center;font-size: 25px;color: #383838;">Are you sure you want to delete this record?</p>
    <p *ngIf="this.data.type === 'crops'" style="text-align: center;font-size: 25px;color: #383838;">Are you sure you want to delete this crop?</p>
    <p *ngIf="this.data.type === 'uoms'" style="text-align: center;font-size: 25px;color: #383838;">Are you sure you want to delete this unit of measure?</p>
    <p *ngIf="this.data.type === 'stock'" style="text-align: center;font-size: 25px;color: #383838;">Are you sure you want to delete this stock?</p>
    <p *ngIf="this.data.type === 'stock move'" style="text-align: center;font-size: 25px;color: #383838;">Are you sure you want to delete this stock movement?</p>
    <p *ngIf="this.data.type === 'trader'" style="text-align: center;font-size: 25px;color: #383838;">Are you sure you want to delete this trader?</p>
    <p *ngIf="this.data.type === 'user'" style="text-align: center;font-size: 25px;color: #383838;">Are you sure you want to delete this user?</p>
    <p *ngIf="this.data.type === 'role'" style="text-align: center;font-size: 25px;color: #383838;">Are you sure you want to delete this role?</p>
    <p *ngIf="this.data.type === 'cross-point'" style="text-align: center;font-size: 25px;color: #383838;">Are you sure you want to delete this cross point?</p>
    <p *ngIf="this.data.type === 'cross-point-data'" style="text-align: center;font-size: 25px;color: #383838;">Are you sure you want to delete this record?</p> -->
    <p style="text-align: center;font-size: 25px;color: #383838;">Are you sure you want to delete this record?</p>
    <div style="display: flex;flex-direction: row;justify-content: space-between;margin-top: 40px;">
        <button mat-button class="delete-btn" style="width: 47%;" (click)="onNoClick()">NO</button>
        <button class="red-delete-btn" style="width: 47%;" (click)="onSubmit()" mat-button>YES</button>
    </div>
</div>

<div *ngIf="isLoading" class="spinner-holder">
    <mat-spinner class="spinner"></mat-spinner>
</div>
