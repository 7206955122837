import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  showHeader = false;
  submitting = false;
  currentYear: number = new Date().getFullYear();
  authForm = this.fb.group({
    email: ['', Validators.required],
  });

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private route: Router
  ) { }
  ngOnInit(): void {
    if (window.location.href.indexOf('forgot-password') > -1) {
      this.showHeader = true;
    }
  }

  onSubmit() {
    this.submitting = true;
    if(this.authForm.valid) {
        this.authService.resetPassword(this.authForm.value).subscribe(
          (data: any) => {
            console.log(data);
            this.submitting = false;
            if (data['error']) {
    
              this.snackBar.open('Error', data['resp'], {
                duration: 2000,
              });
    
            } else {
              this.snackBar.open('Success', data['resp'], {
                duration: 2000,
              });
              this.route.navigate(['/login']);
            }
          }
        );
    } else {
      this.submitting = false;
      this.snackBar.open('Error', 'Email is required to reset password', {
        duration: 2000,
      });
    }
  }

  getErrorMessage() {
    return 'You must enter a value';
  }
}
