<header>
    <div class="top-bar top-banner relative">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 header-title">
                    <div class="img">
                        <a href="#">
                            <img src="assets/tz_logo.png" alt="" class="text-center" />
                        </a>
                    </div>

                    <div id="content-header-1">
                        <h1>{{ 'uni_rep_of_tz'|translate }}</h1>
                        <h3>{{ 'ministry'|translate }}</h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="absolute top-4 right-1 flex">
            <div id="google_translate_element" class="flex">

                <div class="">
                    <div>
                        <!-- style="background-color: white;margin-top: 10px;margin-right: 10px;font-weight: bold;color: gray;" -->
                        <button class="flex space-x-1 p-1 inherit"
                            mat-bu tton [matMenuTriggerFor]="menu">{{language.toUpperCase()}}
                            <div *ngIf="language.toUpperCase() == 'EN'"><img src="assets/uk_flag.png"
                                    class="flag"></div>
                            <div *ngIf="language.toUpperCase() == 'SW'"><img src="assets/tz_flag.png"
                                    class="flag"></div>
                            <mat-icon style="color: gray;font-weight: bold;">keyboard_arrow_down</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu" class="bg-white">
                            <a class="" [ngClass]="{'bg-green-500': language == 'en'}" (click)="setLanguage('en')" mat-menu-item>EN</a>
                            <a cl ass="" [ngClass]="{'bg-green-500': language == 'sw'}" (click)="setLanguage('sw')" mat-menu-item>SW</a>
                        </mat-menu>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>

<div class="d-flex justify-content-between bg-dark p-1">
    <div class="p-2 flex">
        <div *ngIf="authService.isAuth" class="text-white font-weight-500 text-xsss">
            {{ 'welcome'|translate }}, {{ username }}
        </div>
    </div>

    <div class="p-2">
        <ul class="navbar-nav" *ngIf="authService.isAuth">
            <li class="nav-item dropdown">
                <a href="#" class="dropdown-toggle text-white" data-toggle="dropdown">
                    {{ username }}
                </a>

                <div class="dropdown-menu dropdown-menu-right">
                    <div class="user_set_header">
                        <p>{{ username }} <br>
                            <span class="address"></span>
                        </p>
                    </div>

                    <div class="user_content">
                        <a class="dropdown-item font-weight-600" (click)="manageProfile()">My Profile</a>
                        <a class="dropdown-item font-weight-600" (click)="changePassword()">Change Password</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item font-weight-600" (click)="onLogout()">Logout</a>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>