import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { DeleteDataComponent } from '../../delete-data/delete-data.component';
import { ManagementService } from '../../management.service';
import { AddRoleComponent } from '../add-role/add-role.component';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css']
})
export class RolesComponent implements OnInit, AfterViewInit {
  roles;
  isLoading = true;

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  dtCheck = false;
  constructor(
    private dialog: MatDialog,
    private manServ: ManagementService,
    public authService: AuthService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.getRoles();
    this.dtTrigger.next();

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 100,
      processing: true,
      responsive: true
    };
  }

  getRoles() {
    this.manServ.getRoles().subscribe(
      data => {
        this.roles = data;
        this.isLoading = false;
        this.rerender();
      }
    );
  }

  rerender(): void {
    if (this.dtCheck){
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
      });
    }
    else {
      this.dtCheck = true;
      this.dtTrigger.next();
    }

  }

  onAddUserRole() {
    const dialogRef = this.dialog.open(AddRoleComponent, {
      width: '470px',
      height: '317px',
      data: {action: 'add' }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('closed');
      this.getRoles();
    });
  }



  editRole(id) {
    const dialogRef = this.dialog.open(AddRoleComponent, {
      width: '470px',
      height: '317px',
      // tslint:disable-next-line: object-literal-shorthand
      data: {id: id, action: 'edit' }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('closed');
      this.getRoles();
    });
  }

  assignPermissions(id) {
    const dialogRef = this.dialog.open(AddRoleComponent, {
      width: '500px',
      height: '270px',
      // tslint:disable-next-line: object-literal-shorthand
      data: {id: id, action: 'assign' }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('closed');
      this.getRoles();
    });
  }

  deleteRole(id) {
    const dialogRef = this.dialog.open(DeleteDataComponent, {
      width: '500px',
      height: '300px',
      // tslint:disable-next-line: object-literal-shorthand
      data: {id: id, type: 'role' }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getRoles();
    });
  }
}
