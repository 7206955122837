import { Component, Inject, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AddCropComponent } from 'src/app/modules/management/crops/add-crop/add-crop.component';
import { DialogData } from 'src/app/modules/management/delete-data/delete-data.component';
import { BuyerService } from '../../buyer.service';
import { AuthService } from 'src/app/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-permit-action',
  templateUrl: './permit-action.component.html',
  styleUrls: ['./permit-action.component.css']
})
export class PermitActionComponent implements OnInit {

  edit;
  isSaving = false;
  permitActionForm = this.fb.group({
    status: ['', Validators.required],
    comment: ['', ],
  });


  constructor(
    public dialogRef: MatDialogRef<AddCropComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private buyerService: BuyerService,
    public authService: AuthService,
    private snackBar: MatSnackBar,
    public route: Router
  ) { }

  ngOnInit(): void {
  }

  getErrorMessage() {
    return 'This field is required';
  }

  onNoClick() {
    this.isSaving = false;
    this.dialogRef.close();
  }

  onSubmit() {
    this.isSaving = true;
    if (this.permitActionForm.valid) {
      try {
        this.buyerService.approvePermit(this.permitActionForm.value, this.data.id).subscribe(
          (resp: any) => {
            this.snackBar.open('success', resp['resp'], {
              duration: 5000
            });
              this.onNoClick();
              this.route.navigate([this.buyerService.getPreviousUrl()])
          }
        );

      } catch(e) {
        this.isSaving = false;
        this.snackBar.open('error', e, {
          duration: 5000
        });
      }
    }
  }

}
