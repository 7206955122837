import { Component, Inject, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ManagementService } from '../../management.service';
import { CrossPointsService } from '../cross-points.service';

export interface DialogData {
  id;
  action;
  region;
  district;
  ward;
  dest_region;
  dest_district
}

@Component({
  selector: 'app-add-point-data',
  templateUrl: './add-point-data.component.html',
  styleUrls: ['./add-point-data.component.css']
})
export class AddPointDataComponent implements OnInit {
  points;
  crops;
  regions;
  dest_regions;
  districts;
  dest_districts;
  // wards;
  // villages;
  edit;

  crossPointForm = this.fb.group({
    cross_point_id : ['', Validators.required],
    source : ['', Validators.required],
    crop_id : ['', Validators.required],
    quantity : ['', Validators.required],
    price : [''],
    cess_payment : [''],
    // village_id : [''],
    // ward_id : [''],
    district_id : ['', Validators.required],
    region_id : ['', Validators.required],
    dest_district_id : ['', Validators.required],
    dest_region_id : ['', Validators.required],
    date : ['', Validators.required],

  });

  editForm = this.fb.group({
    source : ['', Validators.required],
    crop_id : ['', Validators.required],
    quantity : ['', Validators.required],
    price : [''],
    cess_payment : [''],
    // village_id : [''],
    // ward_id : [''],
    cross_point_id : ['', Validators.required],
    district_id : ['', Validators.required],
    region_id : ['', Validators.required],
    dest_district_id : ['', Validators.required],
    dest_region_id : ['', Validators.required],
    date : ['', Validators.required],

  });
  constructor(
    public dialogRef: MatDialogRef<AddPointDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private manServ: ManagementService,
    private snackBar: MatSnackBar,
    public crossP: CrossPointsService,
    private dateAdapter: DateAdapter<Date>,
    ) {
      this.dateAdapter.setLocale('en-GB'); // dd/MM/yyyy
    }

  ngOnInit(): void {
    this.getRegions();
    this.getAllRegions();
    this.getCrossPoints();
    this.getCrops();
    this.crossPointForm.get('date').setValue(new Date());

    if (this.data.action === 'edit') {

      this.getDistricts(this.data.region);
      this.getDestDistricts(this.data.dest_region);
      // this.getWards(this.data.district);
      // this.getVillages(this.data.ward);

      this.crossP.getCrossPointSingleDataRecord(this.data.id).subscribe(
        resp => {
          this.edit = resp;
          this.editForm.get('date').setValue(this.edit.date);
          this.editForm.get('region_id').setValue(this.data.region);
          this.editForm.get('district_id').setValue(this.data.district);
          this.editForm.get('crop_id').setValue(this.edit.crop_id);
          this.editForm.get('quantity').setValue(this.edit.quantity);
          this.editForm.get('dest_region_id').setValue(this.data.dest_region);
          this.editForm.get('dest_district_id').setValue(this.data.dest_district);
          this.editForm.get('cross_point_id').setValue(this.edit.cross_point_id);
          this.editForm.get('price').setValue(this.edit.price);
          this.editForm.get('cess_payment').setValue(this.edit.cess_payment);
          this.editForm.get('source').setValue(this.edit.source);
        }
      );

    }
  }
  getCrops() {
    this.manServ.getCrops().subscribe(
      data => {
        this.crops = data;
      }
    );
  }
  getRegions() {
    var id = localStorage.getItem('asdsUserId');
    this.manServ.getRegions(id).subscribe(
      data => {
        this.regions = data;
      }
    );
  }
  getAllRegions() {
    var id = localStorage.getItem('asdsUserId');
    this.manServ.getAllRegions().subscribe(
      data => {
        this.dest_regions = data;
      }
    );
  }

  getDistricts(regionId) {
    var id = localStorage.getItem('asdsUserId');
    this.manServ.getDistricts(regionId, id).subscribe(
      data => {
        this.districts = data;
      }
    );
  }


  getDestDistricts(regionId) {
    var id = localStorage.getItem('asdsUserId');
    this.manServ.getAllDistricts(regionId).subscribe(
      data => {
        this.dest_districts = data;
      }
    );
  }
  getCrossPoints() {
    // var id = localStorage.getItem('asdsUserId');
    this.crossP.getCollectionPoints().subscribe(
      data => {
        this.points = data;
      }
    );
  }

  // getWards(districtId) {
  //   this.manServ.getWards(districtId).subscribe(
  //     data => {
  //       this.wards = data;
  //     }
  //   );
  // }

  // getVillages(wardId) {
  //   this.manServ.getVillages(wardId).subscribe(
  //     data => {
  //       this.villages = data;
  //     }
  //   );
  // }

  getErrorMessage() {
    return 'This field is required';
  }

  onNoClick() {
    this.dialogRef.close();
  }

  onSubmit() {
    console.log(this.crossPointForm.valid)
    if (this.crossPointForm.valid) {
      this.crossP.addCrossPointData(this.crossPointForm.value).subscribe(
        (data: string) => {
          if (data['resp'] === 'failed') {
            this.snackBar.open('error', 'Record exists', {
              duration: 5000
            });
          }
          else {
            this.snackBar.open('success', data, {
              duration: 5000
            });
            this.onNoClick();
          }
        }
      );
    }
  }

  onUpdate() {
    if (this.editForm.valid) {
      this.crossP.updateCrossPointData(this.editForm.value, this.data.id).subscribe(
        (data: string) => {
          if (data['resp'] === 'failed') {
            this.snackBar.open('error', 'Record exists', {
              duration: 5000
            });
          }
          else {
            this.snackBar.open('success', data, {
              duration: 5000
            });
            this.onNoClick();
          }
        }
      );
    }
  }
}
