import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { yearsPerPage } from '@angular/material/datepicker';
import { environment } from 'src/environments/environment';

const baseUrl = environment.baseUrl
const url1 = baseUrl + '/warehouse_stock/';

// warehouse stock filters
const url2 = baseUrl + '/warehouse_region/';
const url3 = baseUrl + '/warehouse_region_and_year/';
const url4 = baseUrl + '/warehouse_year/';



// market
const url5 = baseUrl + '/stock_trend/';
// trading filters
const url6 = baseUrl + '/exclude_district_trading/';
const url7 = baseUrl + '/market_type_region_trading/';
const url8 = baseUrl + '/market_type_year_trading/';
const url9 = baseUrl + '/region_year_trading/';
const url10 = baseUrl + '/market_type_trading/';
const url11 = baseUrl + '/region_trading/';
const url12 = baseUrl + '/year_trading/';



// stock movement
const url49 = baseUrl + '/movement_summary';
const url50 = baseUrl + '/commodity_info/';
const url51 = baseUrl + '/movement_without_district/';
const url52 = baseUrl + '/movement_without_district_and_flow/';
const url53 = baseUrl + '/movement_without_district_and_year/';
const url54 = baseUrl + '/movement_without_district_and_region/';
const url55 = baseUrl + '/movement_only_region/';
const url56 = baseUrl + '/commodity_info_only_region/';
const url57 = baseUrl + '/commodity_info_only_region_year_and_commodity/';
const url58 = baseUrl + '/commodity_info_region_year_flow_and_commodity/';
@Injectable({
  providedIn: 'root'
})
export class DefinitionService {

  headers = new HttpHeaders({'Content-Type': 'application/json', 'X-Requested-Width': 'XMLHttpRequeest'});
  constructor(private http: HttpClient) { }


  stockWarehouseCharts(crop) {
    return this.http.get(url1 + crop, {headers: this.headers});
  }

  getWarehouseStockByRegion(region, crop) {
    return this.http.get(url2 + region + '/' + crop, {headers: this.headers});
  }

  getWarehouseStockByRegionAndYear(region, year, crop) {
    return this.http.get(url3 + region + '/' + year + '/'  + crop, {headers: this.headers});
  }

  getWarehouseStockByYear(year, crop) {
    return this.http.get(url4 + year + '/'  + crop, {headers: this.headers});
  }


  // market chart

  stockMarketCharts(cropId) {
    return this.http.get(url5 + cropId, {headers: this.headers});
  }

  excludeDistrictTrading(type, region, year, crop) {
    return this.http.get(url6 + type + '/' + region + '/' + year + '/' + crop, {headers: this.headers});
  }
  marketTypeRegionTrading(type, region, crop) {
    return this.http.get(url7 + type + '/' + region + '/' + crop, {headers: this.headers});
  }
  marketTypeYearTrading(type, year, crop) {
    return this.http.get(url8 + type + '/' + year + '/' + crop, {headers: this.headers});
  }
  regionYearTrading(region, year, crop) {
    return this.http.get(url9 + region + '/' + year + '/' + crop, {headers: this.headers});
  }
  onlyMarketTypeTrading(type, crop) {
    return this.http.get(url10 + type + '/' + crop, {headers: this.headers});
  }
  onlyRegionTrading(region, crop) {
    return this.http.get(url11 + region + '/' + crop, {headers: this.headers});
  }
  onlyYearTrading(year, crop) {
    return this.http.get(url12 + year + '/' + crop, {headers: this.headers});
  }


  // stock movement
  getStockMovement() {
    return this.http.get(url49, {headers: this.headers});
  }

  getActiveInfos(id) {
    return this.http.get(url50 + id, {headers: this.headers});
  }

  // filter stock movement
  filterMovementWithExceptionOfDistrict(region, year, flow) {
    return this.http.get(url51 + region + '/' + year + '/' + flow, {headers: this.headers});
  }

  // filter stock movement
  filterMovementWithExceptionOfDistrictAndFlow(region, year) {
    return this.http.get(url52 + region + '/' + year, {headers: this.headers});
  }

  filterMovementWithExceptionOfDistrictAndYear(region, flow) {
    return this.http.get(url53 + region + '/' + flow, {headers: this.headers});
  }

  filterMovementWithExceptionOfDistrictAndRegion(year, flow) {
    return this.http.get(url54 + year + '/' + flow, {headers: this.headers});
  }

  filterMovementWithOnlyRegion(region) {
    return this.http.get(url55 + region, {headers: this.headers});
  }

  onlyRegionCommodityInfo(commodity, region) {
    return this.http.get(url56 + commodity + '/' + region, {headers: this.headers});
  }
  onlyRegionAndYearCommodityInfo(commodity, region, year) {
    return this.http.get(url57 + commodity + '/' + region + '/' + year, {headers: this.headers});
  }

  onlyRegionYearAndFlowCommodityInfo(commodity, region, year, flow) {
    return this.http.get(url58 + commodity + '/' + region + '/' + year + '/' + flow, {headers: this.headers});
  }

  onlyRegionAndFlowCommodityInfo(commodity, region, flow) {
    return this.http.get(url53 + commodity + '/' + region  + '/' + flow, {headers: this.headers});
  }
}
