import { Component, OnInit } from '@angular/core';
import { ProducesService } from '../produces.service';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ManagementService } from '../../../management.service';
import { RegistrationService } from '../../registration/registration.service';

@Component({
  selector: 'app-produces-form',
  templateUrl: './produces-form.component.html',
  styleUrls: ['./produces-form.component.css']
})
export class ProducesFormComponent implements OnInit {
  form: FormGroup;

  id;
  action;

  crops;
  centers;
  loading = false;
  submitting = false;
  farmer

  firstName: string = '';
  lastName: string = '';
  farmers = [];
  showModal: boolean = false;
  searching: boolean = false;

  constructor(
    public active: ActivatedRoute,
    public produceServ: ProducesService,
    public acRegServ: RegistrationService,
    public manServ: ManagementService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private route: Router,
  ) {
    active.url.subscribe(
      data => {
        // console.log(data[2].path)
        this.id = data[2].path;
        if (this.id) {
          this.action = 'update';
        }
      }
    );
  }

  ngOnInit(): void {
    this.loading = true
    this.form = this.fb.group({
      farmer_id: ['', Validators.required],
      crop_id: ['', Validators.required],
      amount: ['', Validators.required],
      value: ['', Validators.required],
      aggregation_center_id: ['', Validators.required],
    });
    this.getAllCenters();
    this.getAllTraders();
    this.getCrops();
    if (this.id) {
      this.produceServ.getProduceSingleData(this.id).subscribe(
        (data: any) => {
          // console.log(data);
          
          this.form.patchValue({
            farmer_id: data.farmer_id,
            crop_id: data.crop_id,
            amount: data.amount,
            value: data.value,
            aggregation_center_id: data.aggregation_center_id,
          });
          this.farmer = data.farmer?.full_name
        }
      );
    }
  }
  handleEvent(data: any) {
    // console.log(data); 
    this.farmer = data.full_name;
    this.form.get('farmer_id').setValue(data.id);
  }

  getAllTraders() {
    this.produceServ.getFarmers().subscribe(
      (data: any) => {
        // console.log(data);
        
        this.farmers = data;
        this.loading = false;
      }
    );
  }

  getAllCenters() {
    this.acRegServ.getAggregationCenters().subscribe(
      data => {
        this.centers = data;
        this.loading = false;
      }
    );
  }

  getCrops() {
    this.loading = true;

    this.manServ.getCrops().subscribe(
      data => {
        this.crops = data;
        this.loading = false;
      }
    );
  }

  searchFarmers() {
    this.searching = true;
    this.produceServ.searchFarmers(this.firstName, this.lastName).subscribe(
      (data: any) => {
        this.farmers = data;
        this.showModal = true;
        this.searching = false;
      },
      (error: any) => {
        console.error('Error fetching farmers:', error);
        this.searching = false;
      }
    );
  }


  onSubmit() {
    this.submitting = true;
    if (this.form.valid) {
      // console.log(this.form.value);
      
      if (this.action !== 'update') {
        this.form.value['created_by'] = localStorage.getItem('asdsUserId');
        // console.log('valid');
        this.produceServ.addProduce(this.form.value).subscribe(
          (resp: any) => {
            this.snackBar.open('', resp['success'], {
              duration: 5000
            });
            this.submitting = false;
            this.route.navigate(['manage-ac/produces'])
          }
        );
      } else {
        this.produceServ.updateProduceData(this.form.value, this.id).subscribe(
          (resp: any) => {
            this.snackBar.open('', resp['success'], {
              duration: 5000
            });
            this.submitting = false;
            this.route.navigate(['manage-ac/produces'])
          }
        );
      }
    }
  }


  goBack() {
    this.route.navigate(['manage-ac/produces']);
  }
}
