<form *ngIf="data.action == 'add'" [formGroup]="cropsForm" (ngSubmit)="onSubmit()">
    <div class="title-holder">
        <h2 class="title">Add Crop</h2>
    </div>
    <mat-form-field class="w-100" appearance="outline">
        <mat-label>Crop Name</mat-label>
        <input matInput placeholder="Crop Name" formControlName="name" required>
        <mat-error *ngIf="cropsForm.get('name').invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>

    <mat-form-field class="w-100" appearance="outline">
        <mat-label>Crop Code</mat-label>
        <input matInput placeholder="Crop Code" formControlName="code" required type="number">
        <mat-error *ngIf="cropsForm.get('code').invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>

    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Crop Type</mat-label>
      <input matInput placeholder="Crop Type" formControlName="crop_type">
      <mat-error *ngIf="cropsForm.get('crop_type').invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>

    <div style="margin-top: 30px; display: flex; flex-direction: row; justify-content: flex-end;">
        <button (click)="onNoClick()" class="cancelbtn" mat-button>Cancel</button>

        <button type="submit" class="createbtn" mat-button>Submit</button>
    </div>
</form>


<form *ngIf="data.action == 'edit'" [formGroup]="cropsFormEdit" (ngSubmit)="onUpdate()">
    <div class="title-holder">
        <h2 class="title">Edit Crop</h2>
    </div>
    <mat-form-field class="w-100" appearance="outline">
        <mat-label>Crop Name</mat-label>
        <input matInput placeholder="Crop Name" formControlName="name" required>
        <mat-error *ngIf="cropsFormEdit.get('name').invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>

    <mat-form-field class="w-100" appearance="outline">
        <mat-label>Crop Code</mat-label>
        <input matInput placeholder="Crop Code" formControlName="code" required type="number">
        <mat-error *ngIf="cropsFormEdit.get('code').invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>

    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Crop Type</mat-label>
      <input matInput placeholder="Crop Type" formControlName="crop_type">
      <mat-error *ngIf="cropsFormEdit.get('crop_type').invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>

    <div style="margin-top: 30px; display: flex; flex-direction: row; justify-content: flex-end;">
        <button (click)="onNoClick()" class="cancelbtn" mat-button>Cancel</button>

        <button type="submit" class="createbtn" mat-button>Update</button>
    </div>
</form>
