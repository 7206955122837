import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ManagementService } from '../../management.service';

export interface DialogData {
  id
  market_id
  trader_id
  crop_id
}
@Component({
  selector: 'app-release-from-market',
  templateUrl: './release-from-market.component.html',
  styleUrls: ['./release-from-market.component.css']
})
export class ReleaseFromMarketComponent implements OnInit {
  countries;
  regions;
  districts;
  primaryMarkets;
  secondaryMarkets;
  crops;
  buyers;
  broker;
  isProcessed = false;
  showWarehouse1 = false;
  showMarket1 = false;
  decreaseSize1 = false;
  showWarehouse = false;
  showMarket = false;
  decreaseSize = false;
  isRequired = false;

  markets;
  market;
  details;
  warehouses;

  withdrawForm = this.fb.group({
    market : ['', Validators.required],
    crop : ['', Validators.required],
    quality : ['', Validators.required],
    quantity : ['', Validators.required],
    buying_price : ['', Validators.required],
    processed : ['', Validators.required],
    after_processed : [''],
    district_id : [''],
    warehouse_id : [''],
    market_id : [''],
    region_id : [''],
    country_id : ['', Validators.required],
    cess_payment : [''],
    destination : ['', Validators.required],
    trader_id : ['', Validators.required],
    date : ['', Validators.required],
  });

  constructor(
    public dialogRef: MatDialogRef<ReleaseFromMarketComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private manServ: ManagementService,
    private snackBar: MatSnackBar,
    private dateAdapter: DateAdapter<Date>,
  ) {
    this.dateAdapter.setLocale('en-GB'); // dd/MM/yyyy
  }

  ngOnInit(): void {

    this.getDetails();
    this.getMarket();
    this.getCrop();
    this.getCountries();
    this.getAllRegions();
    this.getWarehouses();
    this.getMarkets();
    this.getBuyerData();

    this.withdrawForm.get('date').setValue(new Date());
  }


  getDetails() {
    this.manServ.getReceivedInMarketSingleData(this.data.id).subscribe(
      resp => {
        this.details = resp;
        this.withdrawForm.get('quality').setValue(this.details.quality);
        this.broker = this.details.broker;
      }
    );
  }

  getCountries() {
    this.manServ.getCountries().subscribe(
      data => {
        this.countries = data;
      }
    );
  }
  getAllRegions() {
    var id = localStorage.getItem('asdsUserId');
    this.manServ.getRegions(id).subscribe(
      data => {
        this.regions = data;
      }
    );
  }

  getDistricts(regionId) {
    var id = localStorage.getItem('asdsUserId');
    this.manServ.getDistricts(regionId, id).subscribe(
      data => {
        this.districts = data;
      }
    );
  }

  getCrops() {
    this.manServ.getCrops().subscribe(
      data => {
        this.crops = data;
      }
    );
  }

  getMarkets() {
    this.manServ.getMarketData().subscribe(
      data => {
        this.primaryMarkets = data[1];
        this.secondaryMarkets = data[0];
      }
    );
  }

  getWarehouses() {
    this.manServ.getWarehouseData().subscribe(
      data => {
        this.warehouses = data;
      }
    );
  }


  getBuyerData() {
    this.manServ.getCategoryTraders('Buyer').subscribe(
      data => {
        this.buyers = data;
      }
    );
  }


  getCrop() {
    this.manServ.getSingleCrop(this.data.crop_id).subscribe(
      resp => {
        this.crops = resp;
        this.withdrawForm.get('crop').setValue(this.crops.name);
      }
    );
  }
  getMarket() {
    this.manServ.getMarketSingleData(this.data.market_id).subscribe(
      resp => {
        this.market = resp;
        this.withdrawForm.get('market').setValue(this.market.name);
      }
    );
  }


  changeValidation(countryId) {
    if (countryId === 223) {
      this.isRequired = true;
      this.withdrawForm.get('region_id').setValidators(Validators.required);
      this.withdrawForm.get('district_id').setValidators(Validators.required);


    }
    else {
      this.isRequired = false;

      this.withdrawForm.get('region_id').clearValidators();
      this.withdrawForm.get('district_id').clearValidators();

      this.withdrawForm.get('region_id').setValue('');
      this.withdrawForm.get('district_id').setValue('');

    }
  }

  cropProcessed(value) {
    if (value === 'yes') {
      this.isProcessed = true;
      this.withdrawForm.get('after_processed').setValidators(Validators.required);

    }
    else {
      this.isProcessed = false;

      this.withdrawForm.get('after_processed').clearValidators();

        this.withdrawForm.get('after_processed').setValue('');

    }
  }

  checkDestination(value) {
    if (value === 'warehouse') {
      this.showMarket = false;
      this.showWarehouse = true;
      this.decreaseSize = true;

      this.withdrawForm.get('warehouse_id').setValidators(Validators.required);
      this.withdrawForm.get('market_id').clearValidators();

      this.withdrawForm.get('market_id').setValue('');

    }
    if (value === 'market') {
      this.showWarehouse = false;
      this.showMarket = true;
      this.decreaseSize = true;

      this.withdrawForm.get('market_id').setValidators(Validators.required);
      this.withdrawForm.get('warehouse_id').clearValidators();

      this.withdrawForm.get('warehouse_id').setValue('');

    }
    if (value !== 'warehouse' && value !== 'market') {
      this.showWarehouse = false;
      this.showMarket = false;
      this.decreaseSize = false;

      this.withdrawForm.get('market_id').clearValidators();
      this.withdrawForm.get('warehouse_id').clearValidators();

      this.withdrawForm.get('warehouse_id').setValue('');
      this.withdrawForm.get('market_id').setValue('');

    }
  }
  getErrorMessage() {
    return 'This field is required';
  }

  onNoClick() {
    this.dialogRef.close();
  }

  onSubmit() {
    // if (this.withdrawForm.valid) {
    //   this.manServ.addWithdrawedGood(this.withdrawForm.value, this.data.id, this.data.crop_id, 'market').subscribe(
    //     (resp: string) => {
    //       if (resp['resp'] === 'failed') {
    //         this.snackBar.open('error', 'the amount withdrawed exceeds the available amount', {
    //           duration: 5000
    //         });
    //       }
    //       else {
    //         this.snackBar.open('success', resp['resp'], {
    //           duration: 5000
    //         });
    //         this.onNoClick();
    //       }
    //     }
    //   );
    // }
  }
}
