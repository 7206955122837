import { Component, Inject, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ManagementService } from '../../management.service';

export interface DialogData{
  id;
  action;
}
@Component({
  selector: 'app-add-uom',
  templateUrl: './add-uom.component.html',
  styleUrls: ['./add-uom.component.css']
})
export class AddUomComponent implements OnInit {

  edit;
  uomForm = this.fb.group({
    uom: ['', Validators.required],
  });

  uomFormEdit = this.fb.group({
    uom: ['', Validators.required],
  });

  constructor(
    public dialogRef: MatDialogRef<AddUomComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private manServ: ManagementService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    if (this.data.action === 'edit') {
      this.manServ.getSingleUom(this.data.id).subscribe(
        res => {
          console.log(res)
          this.edit = res;
          this.uomFormEdit.get('uom').setValue(this.edit.uom);
        }
      );
    }
  }

  getErrorMessage() {
    return 'This field is required';
  }

  onNoClick() {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.uomForm.valid) {
      this.manServ.addUom(this.uomForm.value).subscribe(
        (resp: string) => {
          this.snackBar.open('', resp, {
            duration: 5000
          });
          if (resp !== 'Uom exists') {
            this.onNoClick();
          }
        }
      );
    }
  }

  onUpdate() {
    if (this.uomFormEdit.valid) {
      this.manServ.updateUom(this.uomFormEdit.value, this.data.id).subscribe(
        resp => {
          this.onNoClick();
        }
      );
    }
  }
}
