import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from '../order.service';

@Component({
  selector: 'app-order-view',
  templateUrl: './order-view.component.html',
  styleUrls: ['./order-view.component.css']
})
export class OrderViewComponent implements OnInit {

  id;
  order;
  isLoading = false
  constructor(
    public active: ActivatedRoute,
    public orderServ: OrderService,
    private route: Router,
  ) {
    active.url.subscribe(
      data => {
        // console.log(data[2].path)
        this.id = data[2].path;
      }
    );
  }

  ngOnInit(): void {
    this.getOrder();
  }

  getOrder() {
    this.orderServ.getOrderSingleData(this.id).subscribe(
      data => {
        this.order = data;
        this.isLoading = false
      }
    );
  }

  goBack() {
    this.route.navigate(['manage-ac/order-produces']);
  }
}
