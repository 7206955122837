<div class="relative">
    <div id="content-header" class="absolute -top-24">
        <h1 class="text-uppercase font-bold">Aggregated Produces</h1>
    </div>
    <div [ngClass]="!isLoading ? 'show' : 'hide'">
        <div class="col-md-12">
            <div class="view">
                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table p-3">
                    <thead>
                        <tr>
                            <th>Famer</th>
                            <th>Produce Type</th>
                            <th>Total Amount (Kg)</th>
                            <th>Total Price (TSh.)</th>
                        </tr>
    
                    </thead>
                    <tbody>
                        <tr *ngFor="let produce of produces">
                            <td>{{produce.farmer?.full_name}}</td>
                            <td>{{produce.crop?.name}}</td>
                            <td>{{produce.amount | number}}</td>
                            <td class="text-right">{{produce.total | number}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
    
        </div>
    </div>
    
    
    <div *ngIf="isLoading" class="spinner-holder">
      <mat-spinner class="spinner"></mat-spinner>
    </div>
</div>
