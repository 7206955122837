import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ManagementService } from '../../../management.service';
import { RegistrationService } from '../../registration/registration.service';
import * as Highcharts from 'highcharts';
import { AcReportService } from '../ac-report.service';

@Component({
  selector: 'app-summaries',
  templateUrl: './summaries.component.html',
  styleUrls: ['./summaries.component.css']
})
export class SummariesComponent implements OnInit, AfterViewInit {

  loading = false;
  filtering = false;
  regions;
  districts;
  centers;

  center_id: string = '';
  start_date = '';
  end_date = '';
  region_id = '';
  district_id = '';


  Highcharts = Highcharts;
  chartOptions: {};
  chartConstructor = 'chart';
  constructor(
    public manServ: ManagementService,
    public acRegServ: RegistrationService,
    public acReportServ: AcReportService,
  ) { }

  ngOnInit(): void {
    this.getAllCenters();
    this.getAllRegions();
  }

  ngAfterViewInit(): void {
    this.getData();
  }

  getAllCenters() {
    this.acRegServ.getAggregationCenters().subscribe(
      data => {
        this.centers = data;
        this.loading = false;
      }
    );
  }

  getAllRegions() {
    this.manServ.getAllRegions().subscribe(
      data => {
        this.regions = data;
        this.loading = false;
      }
    );
  }

  getDistricts(regionId) {
    this.loading = true;

    this.manServ.getAllDistricts(regionId).subscribe(
      data => {
        this.districts = data;
        this.loading = false;
      }
    );
  }

  filterSummary() {
    this.center_id = '';
    this.start_date = '';
    this.end_date = '';
    this.region_id = '';
    this.district_id = '';

    this.getData();
  }

  getData() {
    this.loading = true;
    var data = {
      'center_id': this.center_id,
      'start_date': this.start_date,
      'end_date': this.end_date,
      'region_id': this.region_id,
      'district_id': this.district_id
    }

    this.acReportServ.gatSummary(data).subscribe(
      (data: any) => {
        console.log(data);

        this.produceSummary(data.availability);
        this.loading = false;
      }
    );
  }

  produceSummary(data) {
    // this.chartOptions = {
    //   chart: {
    //     type: 'column',
    //     // marginLeft: 60, // Adjust margins if necessary
    //     // marginRight: 60,
    //     events: {
    //       render: function () {
    //         this.reflow();
    //       }
    //     },
    //   },
    //   title: {
    //     text: 'Warehouse Registration',
    //     align: 'left'
    //   },
    //   subtitle: {
    //     text:
    //       '',
    //     align: 'left'
    //   },
    //   xAxis: {
    //     categories: data.categories,
    //     crosshair: true,
    //     accessibility: {
    //       description: 'Countries'
    //     }
    //   },
    //   yAxis: {
    //     min: 0,
    //     title: {
    //       text: 'Number of warehouses'
    //     }
    //   },
    //   tooltip: {
    //     valueSuffix: ''
    //   },
    //   plotOptions: {
    //     column: {
    //       pointPadding: 0.2,
    //       borderWidth: 0
    //     }
    //   },
    //   series: data.series,
    //   legend: {
    //     enabled: false
    //   },
    //   responsive: {
    //     rules: [{
    //       condition: {
    //         maxWidth: 500
    //       },
    //       chartOptions: {
    //         legend: {
    //           layout: 'vertical',
    //           align: 'bottom',
    //           verticalAlign: 'right'
    //         },
    //         xAxis: {
    //           labels: {
    //             // rotation: -45, // Rotate labels for better fit
    //             align: 'center',
    //             style: {
    //               fontSize: '13px',
    //               fontFamily: 'Verdana, sans-serif'
    //             }
    //           }
    //         }
    //       }
    //     }]
    //   }
    // };
    this.chartOptions = {
      chart: {
        type: 'column'
      },
      title: {
        text: 'Produce Summary',
        align: 'left'
      },
      subtitle: {
        text: '',
        align: 'left'
      },
      xAxis: {
        categories: data.categories,
        title: {
          text: null
        },
        gridLineWidth: 0,
        lineWidth: 0
      },
      yAxis: {
        // min: 0,
        title: {
          text: 'Produce Quantity',
          align: 'high'
        },
        labels: {
          overflow: 'justify'
        },
        gridLineWidth: 1
      },
      tooltip: {
        valueSuffix: ' '
      },
      plotOptions: {
        bar: {
          borderRadius: '2%',
          dataLabels: {
            enabled: true
          },
          groupPadding: 0.1
        }
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        x: -40,
        y: 80,
        floating: true,
        borderWidth: 1,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
        shadow: true
      },
      credits: {
        enabled: false
      },
      series: data.series

    };
  }
}
