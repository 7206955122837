<div>
  <div class="w-100">
      <div [ngClass]="!isLoading ? 'show' : 'hide'" class="view card">
          <div class="table-responsive">
              <div class="card-header d-flex justify-content-start">
                  <button class="button" mat-button (click)="onAddPoint()"><span class="material-icons notranslate">add_circle_outline</span>Add Cross Points</button>
              </div>
              <div class="card-body">
                  <!-- i have removed [dtTrigger]="dtTrigger" below -->
                  <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" id="dtbs" class="table p-3">
                      <thead>
                          <tr>
                              <th>#</th>
                              <th>Name</th>
                              <th>Category</th>
                              <th>Village</th>
                              <th>Ward</th>
                              <th>District</th>
                              <th>Region</th>
                              <th>Latitude</th>
                              <th>Longitude</th>
                              <th>Action</th>
                          </tr>

                      </thead>
                      <tbody>
                          <tr *ngFor="let point of points;let i = index">
                              <td>{{i+1}}</td>
                              <td>{{point.name}}</td>
                              <td>{{point.category}}</td>
                              <td>{{point.village?.name}}</td>
                              <td>{{point.ward?.name}}</td>
                              <td>{{point.district?.name}}</td>
                              <td>{{point.region?.name}}</td>
                              <td>{{point.lat}}</td>
                              <td>{{point.long}}</td>
                              <td style="display: flex; flex-direction: row;">
                                  <a class="text-success act" (click)="editPoint(point.id, point.region_id, point.district_id, point.ward_id)"><i class="fa fa-edit"></i></a>
                                  <mat-icon *ngIf="authService.isAdmin" class="text-danger act" (click)="deletePoint(point.id)">delete_forever</mat-icon>
                              </td>
                          </tr>
                      </tbody>
                  </table>
              </div>
          </div>
      </div>


      <div *ngIf="isLoading" class="spinner-holder">
          <mat-spinner class="spinner"></mat-spinner>
      </div>
  </div>
  <!-- <div [ngClass]="isChecked ? 'hidden': ''">
      <app-inmarket></app-inmarket>
  </div> -->
</div>
