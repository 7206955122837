import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { MarketService } from '../market.service';
import { DeleteDataComponent } from '../../delete-data/delete-data.component';
import { AddMarketComponent } from '../add-market/add-market.component';
import { MatDialog } from '@angular/material/dialog';
import { AddMarketPriceComponent } from '../add-market-price/add-market-price.component';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'app-market-price',
  templateUrl: './market-price.component.html',
  styleUrls: ['./market-price.component.css']
})
export class MarketPriceComponent implements OnInit, AfterViewInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();
  dtCheck = false;
  prices;
  isLoading = true;
  constructor(
    private marketServ: MarketService,
    private dialog: MatDialog,
    private dateAdapter: DateAdapter<Date>,
    ) {
      this.dateAdapter.setLocale('en-GB'); // dd/MM/yyyy
    } 

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.getMarketPrices();
    this.dtTrigger.next();

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 100,
      processing: true,
      responsive: true,
      dom: 'Bfrtip',
        buttons: [
            'copy', 'csv', 'excel', 'print'
        ]
    };
  }

  getMarketPrices() {
    var id = localStorage.getItem('asdsUserId');
    this.marketServ.getMarketPrices(id).subscribe(
      data => {
        this.prices = data;

        this.isLoading = false;
        this.rerender();
      }
    );
  }

  rerender(): void {
    if (this.dtCheck){
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
          //  $('#dtb').DataTable();
      });
    }
    else {
      this.dtCheck = true;
      this.dtTrigger.next();
    }

  }

  onAddMarketPrice() {
    const dialogRef = this.dialog.open(AddMarketPriceComponent, {
      width: '600px',
      height: '550px',
      data: {action: 'add' }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('closed');
      // this.dtCheck = false;
      this.getMarketPrices();
    });
  }
  editMarketPrice(id, region_id, district_id) {
    const dialogRef = this.dialog.open(AddMarketPriceComponent, {
      width: '600px',
      height: '550px',
      data: {id, action: 'edit', region: region_id, district: district_id }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('closed');
      this.getMarketPrices();
    });
  }


  deleteMarketPrice(id) {
    const dialogRef = this.dialog.open(DeleteDataComponent, {
      width: '500px',
      height: '300px',
      data: {id, type: 'marketprice' }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getMarketPrices();
    });
  }
}
