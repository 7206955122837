import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ManagementService } from '../../management.service';

@Component({
  selector: 'app-view-balance',
  templateUrl: './view-balance.component.html',
  styleUrls: ['./view-balance.component.css']
})
export class ViewBalanceComponent implements OnInit {

  id;
  data;
  isLoading = true;
  constructor(
    private active: ActivatedRoute,
    private manServ: ManagementService,
    private location: Location
  ) {
    this.active.url.subscribe(
      data => {
        console.log(data)
        this.id = data[3];
      }
    );
   }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.manServ.getWarehouseBalance(this.id).subscribe(
      resp => {
        this.data = resp;
        this.isLoading = false;
    });
  }

  back() {
    this.location.back();
  }
}
