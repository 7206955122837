<div  class="relative">

    <div id="content-header" class="absolute -top-24">
      <h1 class="text-uppercase font-bold">{{ 'my_registration'|translate }}</h1>
    </div>
    <!-- <div class="min-h-[50vh] w-full" *ngIf="!authService.isApproved">
        <div class="mx-auto w-1/3 bg-gray-50 p-10 rounded-lg">
            <div class="text-lg py-3">You have loged in successfully!</div>
            <div class="">Once your account is approved, you will be able to proceed with certificate application.</div>
            <div class="my-2"><span class="bg-red-500 text-white cursor-pointer px-2 py-1 rounded-lg" (click)="updateStatusCheck()"><i class="fa fa-spinner fa-spin" *ngIf="updating"></i> Refresh</span></div>
        </div>
    </div> -->
    <div class="view card" [ngClass]="!isLoading ? 'show' : 'hide'">
        <div class="table-responsive">
            <div class="card-body">
                <div class="card-body">
                    <!-- -->
                    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" id="dtbs" class="table p-3">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>{{ 'date'|translate }}</th>
                                <th>{{ 'name'|translate }}</th>
                                <th>{{ 'category'|translate }}</th>
                                <th>{{ 'type'|translate }}</th>
                                <th>{{ 'market'|translate }}</th>
                                <th>{{ 'country'|translate }}</th>
                                <th>{{ 'status'|translate }}</th>
                                <th>{{ 'validity'|translate }}</th>
                                <th>{{ 'action'|translate }}</th>
                            </tr>
    
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of data;let i = index">
                                <td>{{i+1}}</td>
                                <td>{{ data.created_at.split('T')[0] }}</td>
                                <td>{{ data.trader?.full_name }}</td>
                                <td>{{ data.trader?.buyer_category }}</td>
                                <td>{{ data.trader?.type }}</td>
                                <td>{{ data.market_category?.replace('_', ' ') }}</td>
                                <td>{{ data.country }}</td>
                                <td><span class="">{{ data.status }}</span></td>
                                <td><span class="">{{ data.validity }}</span></td>
                                <td class="flex space-x-2 items-center">
                                    <a routerLink="/buyers-portal/application-info/{{ data.id }}" class="text-blue-500 bg-gray-200 px-2 py-1.5 rounded-md" ><i class="fa fa-eye"></i></a>
                                    <!-- <mat-icon *ngIf="authService.isAdmin" class="text-danger act" (click)="deleteTrader(data.id)">delete_forever</mat-icon> -->
                                    <a title="Re-apply permit" class="flex space-x-2 items-center hover:no-underline text-success bg-gray-200 px-2 py-1.5 rounded-md" *ngIf="data.validity == 'expired'" routerLink="/buyers-portal/update-permit/{{ data.id }}">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z" />
                                          </svg>  <span>Re-apply</span>                                        
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    
    <div *ngIf="isLoading" class="spinner-holder">
      <mat-spinner class="spinner"></mat-spinner>
    </div>
    
      
</div>