<form *ngIf="data.action == 'add'" [formGroup]="warehouseForm" (ngSubmit)="onSubmit()">
    <div class="title-holder">
        <h2 class="title">Receive Commodity</h2>
    </div>
    <div class="col-md-12 column">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Receiving Warehouse</mat-label>
            <mat-select placeholder="Select Warehouse" formControlName="warehouse_id" required>
                <mat-option *ngFor="let warehouse of warehouses" [value]="warehouse.id">{{warehouse.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="warehouseForm.get('warehouse_id').invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>
    </div>
    <div class="row">
        <div class="col-md-6 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Trader's Name</mat-label>
                <mat-select placeholder="Select Trader" formControlName="trader_id" required (selectionChange)="getTraderCrops($event.value)">
                    <mat-option *ngFor="let trader of sellers" [value]="trader.id">{{trader.user?.full_name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="warehouseForm.get('trader_id').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Crop</mat-label>
                <mat-select placeholder="Select Crop" formControlName="crop_id" required>
                    <mat-option *ngFor="let crop of crops" [value]="crop.id">{{crop.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="warehouseForm.get('crop_id').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Quantity (MT)</mat-label>
                <input matInput type="number" placeholder="Quantity (MT)" formControlName="quantity" required>
                <mat-error *ngIf="warehouseForm.get('quantity').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Quality/Grade</mat-label>
                <mat-select placeholder="Select quality" formControlName="quality">
                    <mat-option value="high">High</mat-option>
                    <mat-option value="moderate">Moderate</mat-option>
                    <mat-option value="low">Low</mat-option>
                    <mat-option value="unknown">Unknown</mat-option>
                </mat-select>
                <mat-error *ngIf="warehouseForm.get('quality').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Buying price (Tzs)</mat-label>
                <input matInput type="number" placeholder="Buying price (Tzs)" formControlName="buying_price">
                <mat-error *ngIf="warehouseForm.get('buying_price').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Price Unit</mat-label>
                <mat-select placeholder="Select Unit" formControlName="price_unit" required>
                    <mat-option *ngFor="let unit of units" [value]="unit.id">{{unit.uom}}</mat-option>
                </mat-select>
                <mat-error *ngIf="warehouseForm.get('price_unit').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>CESS Payment (TZs)</mat-label>
                <input matInput type="number" placeholder="CESS Payment (TZs) (Tzs)" formControlName="cess_payment">
            </mat-form-field>
        </div>
        <div class="col-md-6 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>CESS Unit</mat-label>
                <mat-select placeholder="Select Unit" formControlName="cess_unit">
                    <mat-option *ngFor="let unit of units" [value]="unit.id">{{unit.uom}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <p>From (Origin)</p>
    <div class="col-md-12 column">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Source</mat-label>
            <mat-select placeholder="Select source" formControlName="source" required (selectionChange)="chooseSource($event.value)">
                <mat-option value="Farm">Farm</mat-option>
                <mat-option value="Warehouse">Warehouse</mat-option>
                <mat-option value="Market">Market</mat-option>
            </mat-select>
            <mat-error *ngIf="warehouseForm.get('source').invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>
    </div>
    <div class="row column">
        <div class="col-md-6 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Regions</mat-label>
                <mat-select placeholder="Select Region" formControlName="region_id" required (selectionChange)="getDistricts($event.value)">
                    <mat-option *ngFor="let region of regions" [value]="region.id">{{region.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="warehouseForm.get('region_id').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Districts</mat-label>
                <mat-select placeholder="Select District" formControlName="district_id" required (selectionChange)="getWards($event.value)">
                    <mat-option *ngFor="let district of districts" [value]="district.id">{{district.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="warehouseForm.get('district_id').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row column">
        <div class="col-md-6 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Wards</mat-label>
                <mat-select placeholder="Select Ward" formControlName="ward_id" (selectionChange)="getVillages($event.value)">
                    <mat-option *ngFor="let ward of wards" [value]="ward.id">{{ward.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="warehouseForm.get('ward_id').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Villages/Streets</mat-label>
                <mat-select placeholder="Select Village" formControlName="village_id" >
                    <mat-option *ngFor="let village of villages" [value]="village.id">{{village.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="warehouseForm.get('village_id').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="col-md-12 column" *ngIf="fromWarehouse">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Origin Warehouse</mat-label>
            <mat-select placeholder="Select Warehouse" formControlName="origin_warehouse" required>
                <mat-option *ngFor="let warehouse of originWarehouses" [value]="warehouse.id">{{warehouse.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="warehouseForm.get('origin_warehouse').invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>
    </div>
    <div class="col-md-12 column" *ngIf="fromMarket">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Origin Market</mat-label>
            <mat-select placeholder="Select Market" formControlName="origin_market" required>
                <mat-option *ngFor="let market of originMarkets" [value]="market.id">{{market.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="warehouseForm.get('origin_market').invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>
    </div>
    <div class="col-md-12 column">
        <mat-form-field class="w-100" appearance="fill">
            <mat-label>Received Date (13/1/2001)</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="date" required>
            <mat-datepicker-toggle matSuffix [for]="picker">
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="warehouseForm.get('date').invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>
    </div>

    <div style="margin-top: 30px; display: flex; flex-direction: row; justify-content: flex-end;">
        <button (click)="onNoClick()" class="cancelbtn" mat-button>Cancel</button>

        <button type="submit" class="createbtn" mat-button>Submit</button>
    </div>
</form>

<!-- Update Warehouse -->

<form *ngIf="data.action == 'edit'" [formGroup]="editForm" (ngSubmit)="onUpdate()">
    <div class="title-holder">
        <h2 class="title">Update Data</h2>
    </div>
    <div class="col-md-12 column">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Receiving Warehouse</mat-label>
            <mat-select placeholder="Select Warehouse" formControlName="warehouse_id" required>
                <mat-option *ngFor="let warehouse of warehouses" [value]="warehouse.id">{{warehouse.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="editForm.get('warehouse_id').invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>
    </div>
    <div class="row">
        <div class="col-md-6 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Trader's Name</mat-label>
                <mat-select placeholder="Select Trader" formControlName="trader_id" required>
                    <mat-option *ngFor="let trader of sellers" [value]="trader.id">{{trader.user?.full_name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="editForm.get('trader_id').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Crop</mat-label>
                <mat-select placeholder="Select Crop" formControlName="crop_id" required>
                    <mat-option *ngFor="let crop of crops" [value]="crop.id">{{crop.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="editForm.get('crop_id').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Quantity (MT)</mat-label>
                <input matInput type="number" placeholder="Quantity (MT)" formControlName="quantity" required>
                <mat-error *ngIf="editForm.get('quantity').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Quality/Grade</mat-label>
                <mat-select placeholder="Select quality" formControlName="quality">
                    <mat-option value="high">High</mat-option>
                    <mat-option value="moderate">Moderate</mat-option>
                    <mat-option value="low">Low</mat-option>
                    <mat-option value="unknown">Unknown</mat-option>
                </mat-select>
                <mat-error *ngIf="editForm.get('quality').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Buying price (Tzs)</mat-label>
                <input matInput type="number" placeholder="Buying price (Tzs)" formControlName="buying_price">
                <mat-error *ngIf="editForm.get('buying_price').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Price Unit</mat-label>
                <mat-select placeholder="Select Unit" formControlName="price_unit" required>
                    <mat-option *ngFor="let unit of units" [value]="unit.id">{{unit.uom}}</mat-option>
                </mat-select>
                <mat-error *ngIf="editForm.get('price_unit').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>CESS Payment (TZs) (Tzs)</mat-label>
                <input matInput type="number" placeholder="CESS Payment (TZs) (Tzs)" formControlName="cess_payment">
            </mat-form-field>
        </div>
        <div class="col-md-6 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>CESS Unit</mat-label>
                <mat-select placeholder="Select Unit" formControlName="cess_unit">
                    <mat-option *ngFor="let unit of units" [value]="unit.id">{{unit.uom}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <p>From (Origin)</p>
    <div class="col-md-12 column">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Source</mat-label>
            <mat-select placeholder="Select source" formControlName="source" required (selectionChange)="chooseSource($event.value)">
                <mat-option value="Farm">Farm</mat-option>
                <mat-option value="Warehouse">Warehouse</mat-option>
                <mat-option value="Market">Market</mat-option>
            </mat-select>
            <mat-error *ngIf="editForm.get('source').invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>
    </div>
    <div class="row column">
        <div class="col-md-6 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Regions</mat-label>
                <mat-select placeholder="Select Region" formControlName="region_id" required (selectionChange)="getDistricts($event.value)">
                    <mat-option *ngFor="let region of regions" [value]="region.id">{{region.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="editForm.get('region_id').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Districts</mat-label>
                <mat-select placeholder="Select District" formControlName="district_id" required (selectionChange)="getWards($event.value)">
                    <mat-option *ngFor="let district of districts" [value]="district.id">{{district.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="editForm.get('district_id').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row column">
        <div class="col-md-6 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Wards</mat-label>
                <mat-select placeholder="Select Ward" formControlName="ward_id"  (selectionChange)="getVillages($event.value)">
                    <mat-option *ngFor="let ward of wards" [value]="ward.id">{{ward.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="editForm.get('ward_id').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Villages/Streets</mat-label>
                <mat-select placeholder="Select Village" formControlName="village_id" >
                    <mat-option *ngFor="let village of villages" [value]="village.id">{{village.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="editForm.get('village_id').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="col-md-12 column" *ngIf="fromWarehouse">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Origin Warehouse</mat-label>
            <mat-select placeholder="Select Warehouse" formControlName="origin_warehouse" required>
                <mat-option *ngFor="let warehouse of originWarehouses" [value]="warehouse.id">{{warehouse.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="editForm.get('origin_warehouse').invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>
    </div>
    <div class="col-md-12 column" *ngIf="fromMarket">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Origin Market</mat-label>
            <mat-select placeholder="Select Market" formControlName="origin_market" required>
                <mat-option *ngFor="let market of originMarkets" [value]="market.id">{{market.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="editForm.get('origin_market').invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>
    </div>
    <div class="col-md-12 column">
        <mat-form-field class="w-100" appearance="fill">
            <mat-label>Received Date (13/1/2001)</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="date" required>
            <mat-datepicker-toggle matSuffix [for]="picker">
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="editForm.get('date').invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>
    </div>

    <div style="margin-top: 30px; display: flex; flex-direction: row; justify-content: flex-end;">
        <button (click)="onNoClick()" class="cancelbtn" mat-button>Cancel</button>

        <button type="submit" class="createbtn" mat-button>Update</button>
    </div>
</form>
