import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ManagementService } from './../../../management/management.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';

export interface DialogData {
  action;
}

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  user;
  userForm = this.fb.group({
    fullname: ['', Validators.required],
    phone:  ['', Validators.required],
    username:  ['', Validators.required],
    email:  ['', Validators.required],
   });

   passwordForm = this.fb.group({
    current: ['', Validators.required],
    new:  ['', Validators.required],
   });
  constructor(
    private fb: FormBuilder,
    private manServ : ManagementService,
    private dialogRef: MatDialogRef<ProfileComponent>,
    private snackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit() {
    this.manServ.getSingleUser(localStorage.getItem('asdsUserId')).subscribe(
      data => {
        this.user = data;

        this.userForm.get('fullname').setValue(this.user.full_name);
        this.userForm.get('phone').setValue(this.user.phone);
        this.userForm.get('username').setValue(this.user.username);
        this.userForm.get('email').setValue(this.user.email);
      }
    );
  }

  getErrorMessage() {
    return 'You must enter a value';
  }

  onSubmit() {
    if(this.userForm.valid) {
      this.manServ.updateProfile(this.userForm.value, localStorage.getItem('asdsUserId')).subscribe(
        data => {
          this.snackbar.open('success', data.toString(), {
            duration: 5000
          });
          this.onNoClick();
        },
        error => {
          console.log(error);
        }
      )

    }
  }

  
  onSubmitPasswords() {
    if(this.passwordForm.valid) {
      this.manServ.updatePasswords(this.passwordForm.value, localStorage.getItem('asdsUserId')).subscribe(
        data => {
          if (data.toString() != 'failed') {
            this.snackbar.open('success', data.toString(), {
              duration: 5000
            });
            this.onNoClick();
          } else {
            this.snackbar.open('failed', 'current password is incorrect', {
              duration: 5000
            });
          }
        },
        error => {
          console.log(error);
        }
      )

    }
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

}
