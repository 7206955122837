import { TradersComponent } from './modules/management/traders/traders/traders.component';
import { WarehouseComponent } from './modules/management/warehouses/warehouse/warehouse.component';
import { CropsComponent } from './modules/management/crops/crops/crops.component';
import { MarketComponent } from './modules/management/markets/market/market.component';
import { WithdrawGoodComponent } from './modules/management/withdraw/withdraw-good/withdraw-good.component';
import { SidebarComponent } from './modules/shared/components/sidebar/sidebar.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WarehouseDashbComponent } from './modules/definition/warehouse-dashb/warehouse-dashb.component';
import { UsersComponent } from './modules/management/users/users/users.component';
import { MarketDashbComponent } from './modules/definition/market-dashb/market-dashb.component';
import { MovementDashbComponent } from './modules/definition/movement-dashb/movement-dashb.component';
import { LoginComponent } from './auth/login/login.component';
import { RolesComponent } from './modules/management/users/roles/roles.component';
import { InwarehouseComponent } from './modules/management/warehouses/inwarehouse/inwarehouse.component';
import { InmarketComponent } from './modules/management/markets/inmarket/inmarket.component';
import { ReleasedFromWarehouseComponent } from './modules/management/warehouses/released-from-warehouse/released-from-warehouse.component';
import { ReleasedFromMarketComponent } from './modules/management/markets/released-from-market/released-from-market.component';
import { IndexComponent } from './modules/definition/index/index.component';
import { MoreDetailsComponent } from './modules/management/markets/more-details/more-details.component';
import { WarehouseDetailsComponent } from './modules/management/warehouses/warehouse-details/warehouse-details.component';
import { RolePermissionsComponent } from './modules/management/users/role-permissions/role-permissions.component';
import { UomComponent } from './modules/management/uom/uom/uom.component';
import { RecordComponent } from './modules/management/cross-points/record/record.component';
import { RegisterComponent } from './modules/management/cross-points/register/register.component';
import { environment } from 'src/environments/environment';
import { ViewBalanceComponent } from './modules/management/warehouses/view-balance/view-balance.component';
import { MarketPriceComponent } from './modules/management/markets/market-price/market-price.component';
import { BuyerRegisterComponent } from './auth/buyer-register/buyer-register.component';
import { ApplicationComponent } from './modules/buyers/application/application.component';
import { AuthService } from './auth/auth.service';
import { AuthGuard } from './guards/auth-guard/auth.guard';
import { GuestComponent } from './guest/guest.component';
import { LoggedinGuard } from './guards/loggedin-guard/loggedin.guard';
import { BuyerProfileComponent } from './modules/buyers/buyer-profile/buyer-profile.component';
import { ApplyPermitComponent } from './modules/buyers/apply-permit/apply-permit.component';
import { ApplicationInfoComponent } from './modules/buyers/application-info/application-info.component';
import { VerifiedPermitComponent } from './modules/buyers/management/verified-permit/verified-permit.component';
import { PermitActionComponent } from './modules/buyers/management/permit-action/permit-action.component';
import { PendingPermitComponent } from './modules/buyers/management/pending-permit/pending-permit.component';
import { MyPermitComponent } from './modules/buyers/my-permit/my-permit.component';
import { UpdatePermitComponent } from './modules/buyers/update-permit/update-permit.component';
import { RejectedComponent } from './modules/buyers/management/rejected/rejected.component';
import { ReturnedComponent } from './modules/buyers/management/returned/returned.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { QrInfoComponent } from './modules/buyers/qr-info/qr-info.component';
import { BuyerCropsComponent } from './modules/buyers/report/buyer-crops/buyer-crops.component';
import { RegistrationComponent } from './modules/buyers/report/registration/registration.component';
import { SummaryComponent } from './modules/management/traders/summary/summary.component';
import { WarehouseSummaryComponent } from './modules/management/warehouses/warehouse-summary/warehouse-summary.component';
import { MarketSummaryComponent } from './modules/management/markets/market-summary/market-summary.component';
import { AcListComponent } from './modules/management/aggregation-centers/registration/ac-list/ac-list.component';
import { AcFormComponent } from './modules/management/aggregation-centers/registration/ac-form/ac-form.component';
import { ProducesListComponent } from './modules/management/aggregation-centers/produces/produces-list/produces-list.component';
import { ProducesFormComponent } from './modules/management/aggregation-centers/produces/produces-form/produces-form.component';
import { OrderListComponent } from './modules/management/aggregation-centers/orders/order-list/order-list.component';
import { OrderFormComponent } from './modules/management/aggregation-centers/orders/order-form/order-form.component';
import { OrderViewComponent } from './modules/management/aggregation-centers/orders/order-view/order-view.component';
import { SummariesComponent } from './modules/management/aggregation-centers/reports/summaries/summaries.component';
import { AggregatedListComponent } from './modules/management/aggregation-centers/produces/aggregated-list/aggregated-list.component';
import { SellProduceComponent } from './modules/management/aggregation-centers/produces/sell-produce/sell-produce.component';

var authService: AuthService;

const routes: Routes = [
  {path: 'qr-info/:number', component: QrInfoComponent},
  {
    path: '', redirectTo: 'login', pathMatch: 'full'
  },
  {
    path: '', component: GuestComponent, canActivate: [LoggedinGuard],
    children: [
      {
        path: 'login', component: LoginComponent
      },
      {
        path: 'register', component: BuyerRegisterComponent
      },
      {
        path: 'forgot-password', component: ForgotPasswordComponent
      },

    ]
  },
  {
    path: '', component: SidebarComponent, canActivate: [AuthGuard],
    children: [
      { path: 'stock-in-warehouse', component: IndexComponent },
      { path: 'stock-in-market', component: MarketDashbComponent },
      { path: 'stock-movement', component: MovementDashbComponent },
      { path: 'managements/warehouses', component: WarehouseComponent },
      { path: 'managements/warehouses/receive-commodities', component: InwarehouseComponent },
      { path: 'managements/warehouses/receive-commodities/:id', component: WarehouseDetailsComponent },
      { path: 'managements/warehouses/warehouse-crop-balance/:id', component: ViewBalanceComponent },
      { path: 'managements/warehouses/released-commodities', component: ReleasedFromWarehouseComponent },
      { path: 'warehouse-summaries', component: WarehouseSummaryComponent },
      { path: 'managements/crops', component: CropsComponent },
      { path: 'managements/markets', component: MarketComponent },
      { path: 'managements/markets/receive-commodities', component: InmarketComponent },
      { path: 'managements/markets/receive-commodities/:id', component: MoreDetailsComponent },
      { path: 'managements/markets/released-commodities', component: ReleasedFromMarketComponent },
      { path: 'managements/markets/market-prices', component: MarketPriceComponent },
      { path: 'market-summaries', component: MarketSummaryComponent },
      { path: 'managements/traders', component: TradersComponent },
      { path: 'trader-summaries', component: SummaryComponent },
      { path: 'managements/withdraw-commodities', component: WithdrawGoodComponent },
      { path: 'managements/users', component: UsersComponent },
      { path: 'managements/user-roles', component: RolesComponent },
      { path: 'managements/user-roles/role-permissions/:id', component: RolePermissionsComponent },
      { path: 'managements/unit-of-measure', component: UomComponent },
      { path: 'managements/cross-points', component: RegisterComponent },
      { path: 'managements/cross-points/data-cross-points', component: RecordComponent },
      {
        path: 'buyers-portal', children: [
          { path: '', component: ApplicationComponent },
          { path: 'apply-permit', component: ApplyPermitComponent },
          { path: 'update-permit/:id', component: UpdatePermitComponent },
          { path: 'application-info/:id', component: ApplicationInfoComponent },
          { path: 'my-permits', component: MyPermitComponent },
          { path: 'profile', component: BuyerProfileComponent },
        ]
      },
      {
        path: 'manage-permits', children: [
          { path: 'pending', component: PendingPermitComponent },
          { path: 'verify-permit/:id', component: PermitActionComponent },
          { path: 'verified-permits', component: VerifiedPermitComponent },
          { path: 'returned-permits', component: ReturnedComponent },
          { path: 'rejected-permits', component: RejectedComponent },
        ]
      },
      {
        path: 'buyer-reports', children: [
          { path: 'registration', component: RegistrationComponent },
          { path: 'buyer-crop', component: BuyerCropsComponent },
        ]
      },
      {
        path: 'manage-ac', children: [
          // registration
          { path: 'ac-registration', component: AcListComponent },
          { path: 'ac-registration/add', component: AcFormComponent },
          { path: 'ac-registration/update/:id', component: AcFormComponent },

          // produces
          { path: 'produces', component: ProducesListComponent },
          { path: 'produces/add', component: ProducesFormComponent },
          { path: 'produces/update/:id', component: ProducesFormComponent },
          { path: 'aggregated-produces', component: AggregatedListComponent },
          { path: 'produces/sell/:id', component: SellProduceComponent },

          // orders
          { path: 'order-produces', component: OrderListComponent },
          { path: 'order-produces/add', component: OrderFormComponent },
          { path: 'order-produces/update/:id', component: OrderFormComponent },
          { path: 'order-produces/view/:id', component: OrderViewComponent },
          
          { path: 'summaries', component: SummariesComponent },
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],

  exports: [RouterModule],
})
export class AppRoutingModule { }

