
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import jspdf, { jsPDF } from 'jspdf';
import { environment } from 'src/environments/environment';
import html2pdf from 'html2pdf.js';
import * as html2canvasWrong from "html2canvas"
var html2canvas = html2canvasWrong as any as (element: HTMLElement, options?: Partial<html2canvasWrong.Options>) => Promise<HTMLCanvasElement>

const baseUrl = environment.baseUrl;
const url1 = baseUrl + '/trader_profile/';
const url2 = baseUrl + '/permits';
const url7 = baseUrl + '/trader_permits/';
const url3 = baseUrl + '/permit_application_info/';
const url4 = baseUrl + '/permit_by_status/';
const url5 = baseUrl + '/approve_permit/';
const url6 = baseUrl + '/my_permits/';
const url8 = baseUrl + '/update_permit/';
const url9 = baseUrl + '/get_councils';
const url10 = baseUrl + '/permit_application_info_by_number/';
const url11 = baseUrl + '/permit_filter';
const url12 = baseUrl + '/crops-copra';

@Injectable({
  providedIn: 'root'
})
export class BuyerService { 
  private previousUrl: string = undefined;
  private currentUrl: string = undefined;

  constructor(private router : Router, private http: HttpClient) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;

        localStorage.setItem('prevUrl', this.previousUrl);
      };
    });
  }

  public getPreviousUrl(){
    return localStorage.getItem('prevUrl');
  } 

  headers = new HttpHeaders({
    'Content-Type': 'application/json',
     'X-Requested-Width': 'XMLHttpRequeest',
    'Access-Control-Allow-Origin': '*'
   });

  fileheaders = new HttpHeaders({
    // 'Content-Type': 'multipart/form-data',
    'Accept': 'application/json',
    //  'X-Requested-Width': 'XMLHttpRequeest',
    'Access-Control-Allow-Origin': '*'
   });

  getBuyerProfile() {
    var id = localStorage.getItem('asdsUserId');
    return this.http.get(url1 + id, {headers: this.headers});
  }

  getPermitApplications() {
    var id = localStorage.getItem('asdsUserId');
    return this.http.get(url7 + id, {headers: this.headers});
  }

  requestPermitApplications(data) {
    return this.http.post(url2, data, {headers: this.fileheaders});
  }

  updatePermitApplications(data, id) {
    return this.http.post(url8 + id, data, {headers: this.fileheaders});
  }

  getPermitApplicationDetails(id) {
    return this.http.get(url3 + id, {headers: this.headers});
  }
  getPermitByStatus(status) {
    return this.http.get(url4 + status, {headers: this.headers});
  }

  approvePermit(data, id) {
    var user = localStorage.getItem('asdsUserId');
    return this.http.post(url5 + id + '/' + user, data, {headers: this.fileheaders});
  }

  getMyPermits() {
    var user = localStorage.getItem('asdsUserId')
    return this.http.get(url6 + user, {headers: this.headers});
  }

  getAllCrops() {
    return this.http.get(url12, {headers: this.headers});
  }

  getRegionCouncils(data) {
    const value = {
      'regions': data
    }
    return this.http.post(url9, value, {headers: this.headers});
  }

  getPermitApplicationDetailsByNumber(number) {
    return this.http.get(url10 + number, {headers: this.headers});
  }

  getPermitByCrop(data) {
    return this.http.post(url11, data, {headers: this.headers});
  }

  generatePdf2(htmlContent: HTMLElement, filename: string) {
    html2canvas(htmlContent).then(canvas => {
      const contentDataURL = canvas.toDataURL('image/png');
      const pdf = new jspdf('p', 'mm', 'a4');
      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(contentDataURL, 'PNG', 0, 0, imgWidth, imgHeight);
      pdf.save(filename + '.pdf');
    });
  }

  generatePdf3(content: string) {
    const doc = new jsPDF();
    doc.text(content, 10, 10);
    doc.save('document.pdf');
  }
  generatePdf(elementId: string) {
    const element = document.getElementById(elementId);
    
    html2pdf()
      .from(element)
      .save();
  }
}
