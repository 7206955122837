<div class="ware">
    <div class="row">
        <div class="col-md-4 space-2">
            <div class="card p-2">
                <div class="pb-1">
                    <h4 class="region"><strong>Region</strong></h4>
                    <hr class="divider-3">
                </div>
                <mat-select class="form-control" [(value)]="defaultRegion" placeholder="Select Region" id="region" (selectionChange)="selectRegion($event)">
                    <mat-option *ngFor="let region of regions; let index=i" [value]="region.id">
                        <span class="">{{region.name | titlecase}}</span>
                    </mat-option>
                </mat-select>
            </div>
        </div>
        <!-- <div class="col-md-3 space-2">
            <div class="card p-2">
                <div class="pb-1">
                    <h4 class="district"><strong>District</strong></h4>
                    <hr class="divider-4">
                </div>
                <mat-select class="form-control" placeholder="Select District" (selectionChange)="selectDistrict($event.value)">
                    <mat-option *ngFor="let district of districts; let index=i" [value]="district.id">
                        <span class="">{{district.name.substring(0,1) | uppercase }}{{district.name.substring(1) | lowercase }}</span>
                    </mat-option>
                </mat-select>
            </div>
        </div> -->
        <div class="col-md-4 space-2">
            <div class="card p-2">
                <div class="pb-1">
                    <h4 style="width: 100%; background-color: #4472C4; color: #fff; padding-left: 3px;"><strong>Year</strong></h4>
                    <hr class="divider-1">
                </div>
                <mat-select class="form-control" placeholder="Select Year" (selectionChange)="selectYear($event.value)">
                    <mat-option *ngFor="let year of years; let index=i" [value]="year">
                        {{year}}
                    </mat-option>
                </mat-select>
            </div>
        </div>
        <div class="col-md-4 space-2 pb-1">
            <div class="card">
                <div class="p-2">
                    <div class="pb-1">
                        <h4 style="width: 100%; background-color: #ED7D31; color: #fff; padding-left: 3px;"><strong>Trade Flow</strong></h4>
                        <hr class="divider-1">
                    </div>
                    <mat-select class="form-control" [(value)]="flow" placeholder="Select.." (selectionChange)="selectTradeFlow($event.value)">
                        <mat-option value="import">Import</mat-option>
                        <mat-option value="export">Export</mat-option>
                    </mat-select>
                </div>
            </div>
        </div>
    </div>

    <div class="row">

        <div class="col-md-6 space-2">
            <div class="">
                <div class="card card-2">
                    <div class="card-header" style="background-color: #DFDFDF; font-size: 17px;">Commodities {{ flowtitle }} ({{ regionName }})</div>
                    <table class="ta ble table-bordered table-striped">
                        <!-- <thead>
                            <tr>
                                <th>Crop</th>
                                <th style="text-align: right;">Percentage (%)</th>
                            </tr>
                        </thead> -->
                        <tbody>
                            <tr *ngFor="let commodity of commodities; let i = index" style="cursor: pointer;">
                                <td [ngStyle]="{'background-color': bg[i] , 'padding': '10px 20px', color: 'white', height: '100px'}" (click)="getMovementDetails(commodity, bg[i])">
                                    {{ commodity.name }} <span>{{ commodity.total_amount }}MT ({{ commodity.percentage }}%)</span>
                                </td>
                            </tr>
                            <tr *ngIf="commodities == null || commodities?.length == 0">
                                <td colspan="3" style="text-align: center;">No any Movement</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-md-6 space-2">
            <div class="">
                <div class="card card-2" [ngStyle]="{'background-color': color, color: 'white'}">
                    <div class="card-header hea der text" [ngStyle]="{'background-color': color}">{{ infoTitle }} ({{ cropName }})</div>
                    <table class="table-bordered" style="color: #fff;">
                        <!-- <thead>
                            <tr>
                                <th>S/No.</th>
                                <th>Region</th>
                                <th style="text-align: right;">Percentage (%)</th>
                            </tr>
                        </thead> -->
                        <tbody>
                            <tr *ngFor="let info of infos; let i = index" style="background-color: transparent;">
                                <td [ngStyle]="{'background-color': color, height: '30px'}">
                                    {{ info.name }} <span>{{ info.total_amount }}MT ({{ info.percentage }})%</span>
                                </td>
                            </tr>
                            <tr *ngIf="infos == null || infos?.length == 0">
                                <td colspan="3" style="text-align: center;">No any Movement</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

</div>
