import { AfterContentInit, AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { DeleteDataComponent } from '../../delete-data/delete-data.component';
import { ManagementService } from '../../management.service';
import { AddWithdrawComponent } from '../../withdraw/add-withdraw/add-withdraw.component';

@Component({
  selector: 'app-released-from-warehouse',
  templateUrl: './released-from-warehouse.component.html',
  styleUrls: ['./released-from-warehouse.component.css']
})
export class ReleasedFromWarehouseComponent implements OnInit, AfterViewInit {
  withdraws;

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();
  dtCheck = false;

  isLoading = true;
  constructor(
    private dialog: MatDialog,
    private manServ: ManagementService,
    public authService: AuthService
  ) { }

  ngOnInit(): void {
  }

ngAfterViewInit() {
  this.getWithdrawedGoods();
  this.dtTrigger.next();

  this.dtOptions = {
    pagingType: 'full_numbers',
    pageLength: 100,
    processing: true,
    responsive: true,
    dom: 'Bfrtip',
    buttons: [
        'copy', 'csv', 'excel', 'print'
    ]
  };
}

  getWithdrawedGoods() {
    this.manServ.onWithdraw().subscribe(
      data => {
        this.withdraws = data;

        this.isLoading = false;
        this.rerender();
      }
    );
  }
  rerender(): void {
    if (this.dtCheck){
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
      });
    }
    else {
      this.dtCheck = true;
      this.dtTrigger.next();
    }

  }


  withdraw() {
    const dialogRef = this.dialog.open(AddWithdrawComponent, {
      width: '710px',
      height: '700px',
      // tslint:disable-next-line: object-literal-shorthand
      data: { action: 'add', origin: 'warehouse'}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('closed');
      this.getWithdrawedGoods();
    });
  }

  editWithdraw(id, regionId, districtId, countryId) {

    const dialogRef = this.dialog.open(AddWithdrawComponent, {
      width: '730px',
      height: '730px',
      // tslint:disable-next-line: object-literal-shorthand
      data: {id: id, action: 'edit', region: regionId, district: districtId, country: countryId }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('closed');
      this.getWithdrawedGoods();
    });
  }

  deletWithdraw(id) {
    const dialogRef = this.dialog.open(DeleteDataComponent, {
      width: '500px',
      height: '300px',
      data: {id, type: 'withdraw' }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getWithdrawedGoods();
    });
  }
}
