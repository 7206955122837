<div class="col-md-7">
    <div class="view card">
        <div class="mb-2">
            <button mat-raised-button class="btn btn-success" (click)="back()">Go back</button>
        </div>
        <table *ngIf="!isLoading">
            <tr>
                <th>Crop</th>
                <th>Balance</th>
            </tr>
            <tr *ngFor="let d of data">
                <th>{{ d.name }}</th>
                <td>{{ d.balance }}</td>
            </tr>
        </table>

        <div *ngIf="isLoading" class="spinner-holder">
            <mat-spinner class="spinner"></mat-spinner>
        </div>
    </div>
</div>
