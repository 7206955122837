import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  showHeader = false;
  currentYear: number = new Date().getFullYear();
  authForm = this.fb.group({
    username: ['', Validators.required],
    password: ['', Validators.required]
  });

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private route: Router
  ) { }
  ngOnInit(): void {
    if (window.location.href.indexOf('login') > -1) {
      this.showHeader = true;
    }
  }

  onSubmit() {
    this.authService.login(this.authForm.value).subscribe(
      (data: string) => {
        if (data[0].hasOwnProperty('id')) {
          const values = data[0];
          var role = values['role']
          if(role !== null) {
            role = role['slug']
          } else {
            role = 'temp'
          }
          // console.log(values);
          // tslint:disable-next-line: no-string-literal
          this.authService.setCredentials(values['id'], values['username'], role, data[1], values['api_token'], values['approval_status'], values['trader_category']);
          if(this.authService.isBuyer || this.authService.isTemp) {
            this.route.navigate(['/buyers-portal']);
          } else {
            this.route.navigate(['/stock-in-warehouse']);
          }
          // window.location.replace('/');
          // window.location.href = environment.appUrl;
         } else {
           this.snackBar.open(data, 'Wrong password or username', {
             duration: 2000,
           });
         }
      }
    );
  }

  getErrorMessage() {
    return 'You must enter a value';
  }

}
