import { AfterViewInit, Component, OnInit } from '@angular/core';
import { WarehousesService } from '../warehouses.service';
import * as Highcharts from 'highcharts';
import { ManagementService } from '../../management.service';

@Component({
  selector: 'app-warehouse-summary',
  templateUrl: './warehouse-summary.component.html',
  styleUrls: ['./warehouse-summary.component.css']
})
export class WarehouseSummaryComponent implements OnInit, AfterViewInit {
  totalWarehouses = 0
  totalPrvt = 0;
  totalGovt = 0;
  loading = true;
  region_id = null;

  regions;

  Highcharts = Highcharts;
  chartOptions: {};
  licensedChartOptions: {};
  // chartOptionsUtilization: {};
  // chartOptionsGrade: {};
  chartConstructor = 'chart';
  constructor(
    public warehouseServ: WarehousesService,
    public manServ: ManagementService,
  ) { }

  ngAfterViewInit(): void {
    this.getData();
  }
  
  ngOnInit(): void {
    this.getAllRegions()
  }

  getAllRegions() {
    this.manServ.getAllRegions().subscribe(
      data => {
        this.regions = data;
      }
    );
  }

  getData() {
    this.loading = true;
    var data = {
      'region_id': this.region_id
    }
    this.warehouseServ.getData(data).subscribe(
      (data: any) => {
        console.log(data);
        
        this.totalWarehouses = data.stats.totalWarehouses;
        this.totalPrvt = data.stats.totalPrvt;
        this.totalGovt = data.stats.totalGovt;


        this.warehouseTypesChart(data.types);
        this.licensedChart(data.registration);
        this.loading = false;
      }
    );
  }

  licensedChart(data) {
    this.licensedChartOptions = {
      chart: {
        type: 'column',
        // marginLeft: 60, // Adjust margins if necessary
        // marginRight: 60,
        events: {
          render: function () {
            this.reflow();
          }
        },
      },
      title: {
        text: 'Warehouse Registration',
        align: 'left'
      },
      subtitle: {
        text:
          '',
        align: 'left'
      },
      xAxis: {
        categories: data.categories,
        crosshair: true,
        accessibility: {
          description: 'Countries'
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Number of warehouses'
        }
      },
      tooltip: {
        valueSuffix: ''
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: [data.series],
      legend: {
        enabled: false
      },
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              layout: 'vertical',
              align: 'bottom',
              verticalAlign: 'right'
            },
            xAxis: {
              labels: {
                // rotation: -45, // Rotate labels for better fit
                align: 'center',
                style: {
                  fontSize: '13px',
                  fontFamily: 'Verdana, sans-serif'
                }
              }
            }
          }
        }]
      }
    };

  }

  warehouseTypesChart(data) {
    this.chartOptions = {
      chart: {
        type: 'pie'
      },
      title: {
        text: 'Number of Warehouses',
        align: 'center'
      },
      tooltip: {
        valueSuffix: ''
      },
      subtitle: {
        text:
          'By Type',
        align: 'center'
      },
      plotOptions: {
        // series: {
        //   allowPointSelect: true,
        //   cursor: 'pointer',
        //   dataLabels: [{
        //     enabled: true,
        //     distance: 20
        //   }, {
        //     enabled: true,
        //     distance: -40,
        //     format: '{point.percentage:.1f}',
        //     style: {
        //       fontSize: '1.2em',
        //       textOutline: 'none',
        //       opacity: 0.7
        //     },
        //     filter: {
        //       operator: '>',
        //       property: 'percentage',
        //       value: 10
        //     },
        //   }]
        // },
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
            format: '{point.name}: {point.percentage:.1f} %',
            distance: 10,
            style: {
              fontSize: '1.2em',
              textOutline: 'none',
              opacity: 0.7
            }
          },
          showInLegend: true
        }
      }, 
      legend: {
        enabled: true,
        align: 'right',
        verticalAlign: 'bottom',
        layout: 'vertical'
      },
      series: [data]
    };

  }

  setRegion(id) {
    this.region_id = id;
    this.getData();
  }
}